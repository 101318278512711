import { Form, Select, Row, Col, DatePicker, TimePicker, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { PrimaryButton } from "../../styled/Button.styled";
import { LightBlueContainer, WhiteContainer } from "../../styled/Container.styled";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useLazyGetCommunicationByIdQuery, useSaveCommunicationMutation } from "../../../feature/Communication/communicationApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const { Option } = Select;

const receivers = [
  {
    label: "Users",
    value: {
      riders: []
    }
  },
  {
    label: "Drivers",
    value: {
      drivers: []
    }
  },
  {
    label: "All",
    value: {
      riders: [],
      drivers: []
    }
  }
];

const CommunicationAddDetails = () => {
  const { id } = useParams();
  const [communicationForm] = useForm();
  const [saveCommunication, { isLoading }] = useSaveCommunicationMutation();
  const [getComById] = useLazyGetCommunicationByIdQuery();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const formSubmit = (value: any) => {

    const receiver = JSON.parse(value.name);

    const { publishDate, publishTime, message } = value;

    const fd = {
      ...receiver,
      publishDate: dayjs(publishDate).format("YYYY-MM-DD"),
      publishTime: dayjs(publishTime).format("HH:mm"),
      message
    };

    saveCommunication(fd).then(() => {
      notification.success({
        message: "Successfully Added!",
        placement: "bottomLeft",
      });
      navigate(-1);
    });
  };

  useEffect(() => {
    if (id) {
      setDisable(true);
      getComById(id).then((res: any) => {
        communicationForm.setFieldsValue({
          "name": res?.data?.data.riders && res?.data?.data.drivers ? JSON.stringify({
            riders: [],
            drivers: []
          }) : res?.data?.data.riders ? JSON.stringify({
            riders: []
          }) : JSON.stringify({
            drivers: []
          }),
          "message": res?.data?.data?.message,
          "publishDate": dayjs(res?.data?.data?.publishDate),
          "publishTime": dayjs(res?.data?.data?.publishTime, "hh:mm"),
        });
      });
    }
  }, [id, getComById, communicationForm]);

  return (
    <>
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <Form name="submitForm">
            <PrimaryButton disabled={disable} loading={isLoading} onClick={() => communicationForm.submit()} htmlType="submit">Save</PrimaryButton>
          </Form>
        </Col>
      </Row>

      <LightBlueContainer>
        <WhiteContainer>
          <Form form={communicationForm} onFinish={formSubmit}>
            <Row gutter={[32, 22]}>
              <Col span={12}>
                <label>Receiver</label>
                <Form.Item name="name" rules={[
                  {
                    required: true,
                    message: "Please select the receiver"
                  }
                ]}>
                  <Select disabled={disable}>
                    {
                      receivers.map((receiver: any) => {
                        return (
                          <Option key={receiver.label} value={JSON.stringify(receiver.value)}>{receiver.label}</Option>
                        );
                      })
                    }
                  </Select>
                </Form.Item>

                <label>Publish Date</label>
                <Form.Item name="publishDate"
                  rules={[
                    {
                      required: true,
                      message: "Please select publish date",
                    }
                  ]}>
                  <DatePicker disabled={disable} className="w-100" />
                </Form.Item>

                <label>Publish Time</label>
                <Form.Item name="publishTime"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a time'
                    }
                  ]}>
                  <TimePicker disabled={disable} className="w-100" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <p>Message</p>
                <Form.Item name="message"
                  rules={[
                    {
                      required: true,
                      message: "Message is required"
                    }
                  ]}>
                  <TextArea disabled={disable} rows={14} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
};

export default CommunicationAddDetails;
