import { IValueLabel } from "../../types/common";
import { Checkbox, Col, Form, Row } from "antd";
import { FormInstance } from "antd/es/form";
import { Rule } from "rc-field-form/lib/interface";

type ITaxiServiceProps = {
  rules?: Rule[];
  form: FormInstance;
};

const options: IValueLabel[] = [
  {
    value: "LOCAL_TAXI",
    label: "Local Taxi",
  },
  {
    value: "RENTAL_TAXI",
    label: "Rental Taxi",
  },
  {
    value: "OUTSTATION_TAXI",
    label: "Outstation",
  },
];

function TaxiService(props: ITaxiServiceProps) {
  return (
    <Form.Item name="taxiServiceType" {...props}>
      <Checkbox.Group>
        <Row>
          {options.map((option) => (
            <Col span={24} key={option.value}>
              <Checkbox value={option.value}>{option.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
}

export default TaxiService;
