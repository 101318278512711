import { Form, Select } from "antd";
type IOptions = {
  value: string;
  label: string;
};

const options: IOptions[] = [
  {
    value: "0",
    label: "0%",
  },
  {
    value: "5",
    label: "5%",
  },
  {
    value: "8",
    label: "8%",
  },
  {
    value: "12",
    label: "12%",
  },
  {
    value: "18",
    label: "18%",
  },


];

const { Option } = Select;
function SelectGst() {
  return (
    <Form.Item name="gst"
      rules={[
        {
          required: true,
          message: 'Please select GST rate'
        }
      ]}>
      <Select>
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectGst;