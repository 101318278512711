import React, { useEffect, useState } from "react";
import {
  Input,
  Radio,
  Form,
  Row,
  Col,
  DatePicker,
  Card,
  notification,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import SelectStateOption from "../../selectOption/SelectStateOption";
import constants from "../../../constants";
import dayjs from "dayjs";
import { IProfileByIdResponse } from "../../../types/profile";
import { useAddAdminImageByIdMutation } from "../../../feature/Profile/ProfileApiSlice";
import secureLocalStorage from "react-secure-storage";
import FormItem from "antd/es/form/FormItem";
import SelectDriverPercentage from "../../selectOption/SelectDriverPercentage";
import { InfoCircleOutlined } from "@ant-design/icons";

const imageUploaderStyle = {
  width: "8rem",
  height: "6rem",
  borderRadius: "5%",
  border: "2px Solid #cbe3f7",
};

const colInputStyle = {
  marginBottom: '4px',
};

type ProfileDetailsProps = {
  data?: IProfileByIdResponse;
};
function ProfileDetailsForm(props: ProfileDetailsProps) {
  const idFromLocalStorage: any = secureLocalStorage.getItem("id")?.toString();
  const { data } = props;
  const [addAdminImageById] = useAddAdminImageByIdMutation();
  const [form] = Form.useForm();
  const [img, setImg] = useState({
    image_file: null,
    image_preview: " ",
  });
  const imgFileHandler = (e: any) => {
    const newData = new FormData();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    newData.append("image", e.target.files[0]);

    setImg({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });

    addAdminImageById({ id: idFromLocalStorage, formData: newData })
      .unwrap()
      .then((res: any) => {
        form.setFieldValue('userImage', res?.data?.userImage);
        notification.success({
          message: "Image Successfully Edited!",
          placement: "bottomLeft",
        });
      });
  };

  useEffect(() => {
    setImg({
      image_preview: `${data?.data?.userImage}`,
      image_file: null,
    });
  }, [data]);

  return (
    <>
      <Form
        name="profileDetailForm"
        form={form}
        initialValues={{
          firstName: data?.data.firstName,
          lastName: data?.data.lastName,
          gender: data?.data.gender,
          phoneNumber: data?.data.phoneNumber,
          email: data?.data.email,
          dob: dayjs(data?.data.dob),
          address: data?.data.address,
          state: data?.data.state,
          userImage: data?.data.userImage,
          role: data?.data.role,
          driverPercentage: data?.data.driverPercentage,
          driverRange: data?.data.driverRange,
          driverRangeForRental: data?.data.driverRangeForRental
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Row gutter={[32, 22]}>
                  <Col span={6}>
                    <Card>
                      <img
                        src={img.image_preview}
                        alt="Profile"
                        style={imageUploaderStyle}
                      />
                    </Card>
                  </Col>
                  <Col span={18}>
                    <Form.Item name={"userImage"} style={{ marginBottom: '4px' }}>
                      <input style={{ display: "none" }} />
                    </Form.Item>
                    <input
                      type="file"
                      name="image"
                      id="file"
                      className="custom-profilebtn"
                      onChange={imgFileHandler}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <label>First Name</label>
                <Form.Item name="firstName" rules={[{ required: true }]} >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <label>Last Name</label>
                <Form.Item name="lastName" rules={[{ required: true }]} >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Row gutter={16}>
                  <Col span={9}>
                    <label>Date of Birth</label>
                    <Form.Item name="dob" rules={[{ required: true }]} >
                      <DatePicker className="w-100" format={"DD-MM-YYYY"} />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {/* Empty column to create space */}
                  </Col>
                  <Col span={12}>
                    <label>Gender</label>
                    <Form.Item name="gender" rules={[{ required: true }]} >
                      <Radio.Group
                        name="radiogroup"
                        defaultValue={false}
                        className="radioButton-style"
                      >
                        <Radio value={"male"}>Male</Radio>
                        <Radio value={"female"}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <label>Phone Number</label>
                <Form.Item name="phoneNumber" rules={[{ required: true }]}>
                  <Input maxLength={10} type="number" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Role</label>
                <Form.Item name="role" rules={[{ required: true }]} >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Col span={24}>
              <label>Email</label>
              <Form.Item name="email" rules={[{ required: true }]} >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label>Address</label>
              <Form.Item name="address" rules={[{ required: true }]} >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label>State</label>
              <div style={{ marginBottom: "25px" }}>
                <SelectStateOption />
              </div>
            </Col>
            <Col span={24}>
              <label>
                Driver Percentage
                <Tooltip title="This tab displays the percentage of the share that goes to Himcab Driver">
                  <InfoCircleOutlined style={{ marginLeft: '4px', color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              </label>
              <div style={{ marginBottom: "22px" }}>
                <SelectDriverPercentage />
              </div>
            </Col>
            <Col span={24}>
              <label>Driver Radius(Local/Outstation in Km)</label>
              <Tooltip title="This tab sets the radius within which drivers are considered available for a local/outstation trip.">
                <InfoCircleOutlined style={{ marginLeft: '4px', color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
              <Form.Item
                name="driverRange"
                rules={[
                  { required: true, message: 'Please input the driver radius for Local/Outstation!' },
                  {
                    validator: (_, value) =>
                      value && value > 0 ? Promise.resolve() : Promise.reject('Set min 1 km'),
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label>Driver Radius(Rental in Km)</label>
              <Tooltip title="This tab sets the radius within which drivers are considered available for a rental trip.">
                <InfoCircleOutlined style={{ marginLeft: '4px', color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
              <Form.Item
                name="driverRangeForRental"
                rules={[
                  { required: true, message: 'Please input the driver radius Rental!' },
                  {
                    validator: (_, value) =>
                      value && value > 0 ? Promise.resolve() : Promise.reject('Set min 1 km'),
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ProfileDetailsForm;
