import { Card, Col, Row, Spin, Tag, Typography } from "antd";
import { useGetNotificationQuery, useUpDateNotificationMutation } from "../../../feature/dashboard/dashboardApiSlice";
import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { setNotifications } from "../../../feature/ui/uiSlice";

const { Title } = Typography;

function NotificationItem() {
  const dispatch = useAppDispatch();
  const { data, isLoading, isFetching } = useGetNotificationQuery();
  const [updateNotification] = useUpDateNotificationMutation();

  useEffect(() => {
    data?.data.forEach((not: any) => {
      if (not.status) {
        updateNotification({ id: not.id, status: false });
      }
    });
    dispatch(setNotifications(data?.data));
  }, [data, updateNotification, dispatch]);



  return (
    <>
      {
        !isLoading || isFetching ? data?.data.map((notification: any) => {
          return (
            <Card key={notification.id} style={{ backgroundColor: "#cbe3f7", marginBottom: "1%" }}>
              {
                notification.status === true && <Tag color="#14A011" style={{ fontSize: "8px" }}>
                  NEW
                </Tag>
              }
              <Row justify={"space-between"}>
                <Col>
                  <Title level={5} className="m-0">
                    <span>{notification.title}</span>
                  </Title>
                </Col>
                <Col>
                  <span>{notification.date}</span>
                </Col>
              </Row>
              <Row>
                <span>{notification.description}</span>
              </Row>
            </Card>
          );
        }) : <Spin size="large" style={{ display: "flex", justifyContent: "center" }} />
      }
    </>
  );
}

export default NotificationItem;
