import { mainApi } from "../../app/services/mainApiSlice";



const commonUrl: string = "/admin/transaction";

const transactionApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransaction: builder.query<any, void>({
      query: () => ({
        url: `${commonUrl}/get-all?page=0&size=1000`,
        method: "GET",
      }),
    }),
    getTransactionById: builder.query<any, any>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "GET",
      }),
    }),
    getAllTransactionsForMonth: builder.query<any, string>({
      query: (month) => ({
        url: `${commonUrl}/get-all-transactions?month=${month}&page=0&size=100`,
        method: "GET",
      }),
    }),
    downloadInvoicesForMonth: builder.query<any, string>({
      query: (month) => ({
        url: `${commonUrl}/download-invoices?month=${month}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllTransactionQuery,
  useGetTransactionByIdQuery,
  useGetAllTransactionsForMonthQuery,
  useDownloadInvoicesForMonthQuery,
} = transactionApi;
