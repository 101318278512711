import { mainApi } from "../../app/services/mainApiSlice";



const commonUrl: string = "/admin/communication";

const fareApi = mainApi.injectEndpoints({
 endpoints: (builder) => ({

  getAllCommunication: builder.query<any, void>({
   query: () => ({
    url: `${commonUrl}/get-all?page=0&size=1000`,
    method: "GET",
   }),
   providesTags: [{ type: "Main", id: "COM" }],
  }),
  getCommunicationById: builder.query<any, string | undefined>({
   query: (id) => ({
    url: `${commonUrl}/get/${id}`,
    method: "GET",
   }),
   keepUnusedDataFor: 0

  }),
  saveCommunication: builder.mutation<any, void>({
   query: (fd) => ({
    url: `${commonUrl}/save`,
    method: "POST",
    body: fd
   }),
   invalidatesTags: [{ type: "Main", id: "COM" }],
  }),
  updateCommunication: builder.mutation<any, void>({
   query: (fd) => ({
    url: `${commonUrl}/save`,
    method: "POST",
    body: fd
   })
  }),
  deleteCommunication: builder.mutation<any, any>({
   query: (id) => ({
    url: `${commonUrl}/delete/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: [{ type: "Main", id: "COM" }],
  })

 }),
 overrideExisting: false,
});


export const {
 useGetAllCommunicationQuery,
 useSaveCommunicationMutation,
 useLazyGetCommunicationByIdQuery,
 useDeleteCommunicationMutation
} = fareApi;
