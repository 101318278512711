import { Form, Input, Col, Row, Button } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { IDriverByIdResponse } from "../../../../types/driver";

type WalletDetailsProps = {
  data?: IDriverByIdResponse;
};

export default function WalletDetails(props: WalletDetailsProps) {
  const [form] = Form.useForm();
  const { data } = props;
  const [showAmount, setShowAmount] = useState(false); 

  useEffect(() => {
    if(showAmount){
        form?.setFieldsValue({
            reimbursementAmount: data?.data.amount,
        });
    }else{
        form?.setFieldsValue({
            reimbursementAmount: "●●●●",
        });
    }
  }, [data,showAmount]); 

  const toggleVisibility = () => {
    setShowAmount(!showAmount); 
  };

  return (
    <>
      <Form
        form={form}
        name="WalletDetailsForm"
      >
        <Row gutter={[32, 22]}>
          <Col span={5}>
            <label>Reimbursement Amount</label>
            <Form.Item
              name="reimbursementAmount"
              rules={[{ required: true, message: "Reimbursement Amount is required" }]}
            >
              <Input readOnly/>
            </Form.Item> 
          </Col>
          <Col span={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft:"0px", paddingLeft: "0px"}}>
            <Button
                type="text"
                onClick={toggleVisibility}
                icon={showAmount ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}
