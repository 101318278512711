import { useState, useEffect } from "react";
import { Row, Col, Dropdown, Space, MenuProps, notification, Badge } from "antd";
import { useNavigate } from "react-router-dom";
import {
  MdKeyboardArrowDown,
  MdOutlineLogout,
  MdNotifications,
} from "react-icons/md";
import styled from "styled-components";
import secureLocalStorage from "react-secure-storage";
import { useGetAdminByIdQuery } from "../../../feature/Profile/ProfileApiSlice";
import constants from "../../../constants";
import { useLogOutMutation } from "../../../feature/logout/logoutApiSlice";
import { useLazyGetNotificationQuery } from "../../../feature/dashboard/dashboardApiSlice";
import { useDispatch } from "react-redux";
import { setNotifications } from "../../../feature/ui/uiSlice";
import { useAppSelector } from "../../../app/hooks";

const iconSize: number = 28;
const AuthStatusContainer = styled.div`
  display: flex;
  justify-content: end;
  & > div {
    width: 350px;
    display: flex;
    justify-content: space-between;
  }
`;

const NotificationIcon = styled.div`
display: flex;
justify-content: center;
align-items: center;
`

const AuthText = styled(Col)`
  text-align: right;
  div:first-child {
    color: #3e4685;
    font-size: large;
  }
`;

const UserImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
`;

function AuthStatus() {
  const dispatch = useDispatch();
  const id = secureLocalStorage.getItem("id")?.toString()!;
  const role = secureLocalStorage.getItem("role")!;
  const [logOut] = useLogOutMutation();
  const [menuItems, setMenuItems] = useState<MenuProps["items"] | any>([]);
  const { data } = useGetAdminByIdQuery(id);
  const [notificationLink, setNotificationLink] = useState("");
  const [getNotification] = useLazyGetNotificationQuery();
  const navigate = useNavigate();
  const [notificationData, setNotification] = useState([1]);
  const notifications = useAppSelector((state: any) => state.notifications);
  const handleNotification = () => {
    navigate(`${notificationLink}`);
    setNotification(notifications);
  };

  const logoutApiFunction = () => {
    logOut({ userId: id })
      .unwrap()
      .then(() => {
        notification.success({
          message: "Successfully logged out",
          placement: "bottomLeft",
        });
      })
      .then(() => {
        localStorage.clear();
      })
      .catch((error: any) => {
        notification.error({
          message: `${error}`,
          placement: "bottomLeft",
        });
      });
  };

  useEffect(() => {
    getNotification().then((res: any) => {
      setNotification(res?.data?.data);
      dispatch(setNotifications(res?.data?.data));
    });
    setInterval(() => {
      getNotification().then((res: any) => {
        setNotification(res?.data?.data);
        dispatch(setNotifications(res?.data?.data));
      });
    }, 1800000);
  }, [getNotification, dispatch]);





  const handleAdminLogout = async () => {
    await logoutApiFunction();
    navigate("/login");
  };



  useEffect(() => {
    if (role === "ROLE_ADMIN") {
      setNotificationLink("/admin-notification");
      setMenuItems([
        {
          key: "1",
          label: (
            <Space onClick={handleAdminLogout}>
              <MdOutlineLogout size={iconSize} />
              Logout
            </Space>
          ),
        },
      ]);
    }
  }, [role]);


  return (
    <AuthStatusContainer>
      <div>
        <NotificationIcon>
          <Badge count={notificationData?.map((not: any) => {
            return not.status ? 1 : 0;
          })?.reduce((acc: number, num: any) => acc + num,0)}>
            <MdNotifications
              className="cursor-dropdown"
              size={iconSize}
              onClick={handleNotification}
              style={{ cursor: "pointer" }}
            />
          </Badge>
        </NotificationIcon>
        <Row justify="center" align="middle" gutter={20}>
          <AuthText>
            <div className="text-white">{(data?.data?.firstName.concat(" ", data?.data?.lastName))}</div>
            {/* <div className="text-primary">{`${data?.data?.roles[0].name}`}</div> */}
          </AuthText>
          <Col>
            <UserImage
              src={`${data?.data.userImage}`}
              alt="profile"
            />
          </Col>
          <Dropdown menu={{ items: menuItems }} placement="bottomLeft">
            <MdKeyboardArrowDown size={iconSize} />
          </Dropdown>
        </Row>
      </div>
    </AuthStatusContainer >
  );
}

export default AuthStatus;
