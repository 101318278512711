import { Card, Col, DatePicker, Form, Row, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import { IBannerByIdResponse } from "../../../types/banner";

import constants from "../../../constants";
import dayjs from "dayjs";

const imageUploaderStyle = {
  width: "19rem",
  height: "11rem",
  borderRadius: "5%",
  border: "2px Solid #cbe3f7",
};

type BannerDetailsProps = {
  data?: IBannerByIdResponse;
  setBannerImg?: any;
};
function BannerDetails(props: BannerDetailsProps) {
  const { data, setBannerImg } = props;

  const [img, setImg] = useState({
    image_file: null,
    image_preview: "",
  });

  const imgFileHandler = (e: any) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setImg({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
    setBannerImg(image_as_files);
  };

  useEffect(() => {
    setImg({
      image_file: null,
      image_preview: `${constants.baseImgUrl}${data?.data.image} `,
    });
  }, [data?.data.image]);
 
  return (
    <>
      <Form
        layout="vertical"
        name="BannerDetailsForm"
        initialValues={{
          liveDate: dayjs(data?.data.liveDate),
          liveTime: dayjs(data?.data.liveTime, "HH:mm"),
          image: data?.data.image,
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row gutter={[32, 22]}>
              <Col span={24}>
                <Card>
                  <img
                    src={img.image_preview}
                    alt="Banner"
                    style={imageUploaderStyle}
                  />
                </Card>

                <input
                  type="file"
                  name="image"
                  className="custom-bannerbtn"
                  onChange={imgFileHandler}
                />
              </Col>

              <Col span={24}>
                <label>Live Date</label>
                <Form.Item name="liveDate">
                  <DatePicker format={"DD/MM/YYYY"} className="w-100" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Live Time</label>
                <Form.Item name="liveTime">
                  <TimePicker className="w-100" format={"HH:mm"} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default BannerDetails;
