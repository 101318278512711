import { MarkerF, GoogleMap } from '@react-google-maps/api';
import { useEffect, useState } from "react";
import { IPlaceByIdResponse } from "../../types/popularPlace";
import { type CSSProperties } from 'react';

type LocationProps = {
  data?: IPlaceByIdResponse;
  searchPlace?: any;
  setLatitudeValue?: any;
  setLongitudeValue?: any;
  setAddress: (e: any) => void;
};

const containerStyle = {
  height: "60vh",
  overflow: "hidden",
};

// const inputStyle: CSSProperties = {
//   boxSizing: `border-box`,
//   border: `1px solid transparent`,
//   width: `240px`,
//   height: `32px`,
//   padding: `0 12px`,
//   borderRadius: `3px`,
//   boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//   fontSize: `14px`,
//   outline: `none`,
//   textOverflow: `ellipses`,
//   position: 'absolute',
//   top: '10px',
//   right: '10px',
// };

function LocationPicker(props: LocationProps) {
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const { setLatitudeValue, setLongitudeValue, data } = props;
  const [center, setCenter] = useState({
    lat: Number(data?.data.latitude) || 32.11,
    lng: Number(data?.data.longitude) || 76.53,
  });

  const handleCenterChanged = (e: any) => {
    let loc = e.latLng;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: loc }, (results, status) => {
      if (status === "OK") {
        if (!!results) {
          props.setAddress(results[0]);
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
    setLatitudeValue(e.latLng.lat());
    setLongitudeValue(e.latLng.lng());
    setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  useEffect(() => {
    if (props.searchPlace) {
      setSelectedPlace(props.searchPlace);
      setCenter({
        lat: props.searchPlace?.geometry.location.lat(),
        lng: props.searchPlace?.geometry.location.lng()
      });
    }
  }, [props.searchPlace, setLatitudeValue, setLongitudeValue]);

  useEffect(() => {
    if (data) {
      setCenter({
        lat: +data?.data.latitude,
        lng: +data?.data.longitude
      });
      setSelectedPlace(data?.data.place);
    }
  }, [data]);

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: true,
        }}
      >
        {/* <StandaloneSearchBox
          onPlacesChanged={onPlacesChanged}
          onLoad={onSBLoad}>
          <input
            type='text'
            placeholder='Search place'
            style={inputStyle}
          />
        </StandaloneSearchBox> */}
        {selectedPlace && (
          <MarkerF position={center}
            draggable={true}
            onDragEnd={(e: any) => {
              handleCenterChanged(e);
            }}
          />
        )}
      </GoogleMap>
    </>
  );
}

export default LocationPicker;
