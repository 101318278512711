import React from "react";
import {useEffect, useState } from "react";
import {
  Row,
  Input,
  Table,
  Typography,
  Switch,
  Modal,
  notification,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  GlobalTableStyle,
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { ColumnsType } from "antd/es/table";
import {
  DeleteButton,
  PrimaryButton,
  ViewDetailsButton,
} from "../../styled/Button.styled";
import CustomCard from "../../countCard/CustomCard";
import pageSize from "../../../constants/pageSize";
import {
  useDeletePlaceByIdMutation,
  useGetAllPopularPlaceQuery,
  useGetPlaceCountQuery,
  useUpdateplaceStatusByIdMutation,
} from "../../../feature/PopularPlace/popularplaceApiSlice";
import { IPlace } from "../../../types/popularPlace";
import constants from "../../../constants";

export default function Popularplaces() {
  const { data, isLoading, isFetching, refetch } = useGetAllPopularPlaceQuery({ pageSize });
  const { data: countData } = useGetPlaceCountQuery();
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [stateValue, setStateValue] = useState("");
  const [placeValue, setPlaceValue] = useState("");
  const [location, setLocation] = useState("");
  const [deletePlaceById] = useDeletePlaceByIdMutation();
  const [updateplaceStatusById] = useUpdateplaceStatusByIdMutation();
  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Place?",
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const onDelete = (id: number) => {
    deletePlaceById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      });
  };
  const toogleSwitch = (id: any) => {
    updateplaceStatusById(id);
  };
  const columns: ColumnsType<IPlace> = [
    {
      key: "place",

      title: (
        <div>
          <Typography.Text> Place Name</Typography.Text>
          <div>
            <Input.Search
              onChange={(e) => {
                setPlaceValue(e.target.value);
              }}
              placeholder="Search here"
            />
          </div>
        </div>
      ),
      filteredValue: [placeValue],
      onFilter: (value: any, record) => {
        return record?.place?.toLowerCase().includes(value?.toLowerCase());
      },
      render: (value: any, record) => {
        const maxLength = 15;
        const placeName = record.placeName;
        const displayText = placeName.length > maxLength ? `${placeName.substring(0, maxLength)}...` : placeName;

        return (
          <>
            <Row>
              <Col span={6}>
              <div style={{ marginRight: '85px' }}>
              <img
                  alt="Place"
                  src={`${record.image}`}
                  style={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "30px",
                  }}
                />
              </div>
              </Col>
              <Col span={18} style={{ marginTop: "0.8rem" }}>{displayText}</Col>
            </Row>
          </>
        );
      },
      dataIndex: "place",
    },
    {
      key: "address",
      title: (
        <div>
          <Typography.Text>Location</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setLocation(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [location],
      onFilter: (value: any, record) => {
        return record.address?.toLowerCase().includes(value?.toLowerCase());
      },
      render:(_, record) => {
        const maxLength = 20;
        const location = record.address;
        const displayText = location.length > maxLength ? `${location.substring(0, maxLength)}...` : location;

        return(
            <>
            <Row>
               <Col span={18}>
                 <div style={{  marginBottom: "0.2rem" }}>{displayText}</div>
               </Col>
            </Row>
            </>
        )
      },
      dataIndex: "address",
    },
    {
      key: "state",
      title: (
        <div>
          <Typography.Text>State</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setStateValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [stateValue],
      onFilter: (value: any, record) => {
        return record.state?.toLowerCase().includes(value?.toLowerCase());
      },
      dataIndex: "state",
    },
    {
      key: "",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
        </div>
      ),
      render: (_, record) => {
        return (
          <>
            {record.status === true ? (
              <>
                <div>
                  Active
                </div>
                <Switch
                  onChange={(e) => toogleSwitch(record.id)}
                  checked={record.isActive}
                  style={{ backgroundColor: "green" }}
                />
              </>
            ) : (
              <>
                <div>
                  Inactive
                </div>
                <Switch
                  onChange={() => toogleSwitch(record.id)}
                  checked={record.isActive}
                  style={{ backgroundColor: "red" }}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openPlaceDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];
  const openPlaceDetails = (id: number) => {
    navigate(`edit/${id}`);
  };

  const sum = data?.data.length;
  const active = countData?.data.isActive;
  const inActive = countData?.data.inActive;

  useEffect(() => {
    refetch(); 
  }, [refetch]);

  return (
    <>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard
          sum={sum}
          text="Place"
          activeText="Active"
          active={active}
          inActiveText="Inactive"
          inActive={inActive}
        />
        <Link to="add">
          <PrimaryButton> Add Place</PrimaryButton>
        </Link>
      </Row>
      <LightBlueContainer>
        <WhiteContainer>
          <GlobalTableStyle>
            <Table<IPlace>
              loading={isLoading || isFetching}
              columns={columns}
              dataSource={data?.data}
              rowKey="id"
              pagination={{
                pageSize,
              }}
            />
          </GlobalTableStyle>
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}
