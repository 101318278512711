import { useEffect, useState } from "react";
import {
  Col,
  Input,
  Modal,
  notification,
  Row,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  GlobalTableStyle,
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { ColumnsType } from "antd/es/table";
import CustomCard from "../../countCard/CustomCard";
import pageSize from "../../../constants/pageSize";
import {
  DeleteButton,
  PrimaryButton,
  VerifyButton,
  ViewDetailsButton,
} from "../../styled/Button.styled";
import {
  useDeleteDriverByIdMutation,
  useGetAllDriverQuery,
  useGetDriverCountQuery,
  useUpdateDriverStatusByIdMutation,
} from "../../../feature/Driver/driverApiSlice";
import { IDriver } from "../../../types/driver";
import constants from "../../../constants";

export default function Driver() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const { data, isLoading, isFetching,refetch } = useGetAllDriverQuery();
  const { data: countData } = useGetDriverCountQuery();
  const [nameValue, setNameValue] = useState("");
  const [driverId, setDriverId] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [deleteDriverById] = useDeleteDriverByIdMutation();
  const [updateDriverStatusById] = useUpdateDriverStatusByIdMutation();

  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Driver?",
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const onDelete = (id: number) => {
    deleteDriverById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      })
      .catch((error: any) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };
  const toogleSwitch = (id: any) => {
    updateDriverStatusById(id);
  };

  const columns: ColumnsType<IDriver> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text>Name</Typography.Text>
          <div>
            <Input.Search
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
              placeholder="Search here"
            />
          </div>
        </div>
      ),
      filteredValue: [nameValue],
      onFilter: (value: any, record) => {
        return record.firstName
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (_: any, record) => {
        const defaultImageUrl = 'https://himcab.admin.ripple-anubudh.tech/static/media/defaultImage.b0698f102d5ca2413c6ab0dc0f4be3ad.svg';
        return (
          <>
            {record.isVerified === true ? (
              <>
                <Row>
                  <Col span={6}>
                  <div style={{ marginRight: '140px' }}>
                    <img
                      alt="Driver"
                      src={`${record.image}`|| `${defaultImageUrl}`}
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "30px",

                      }}
                    />
                    </div>
                  </Col>
                  <Col span={18} style={{ marginTop: "0.8rem" }}>
                    {record.firstName.concat(" ", record.lastName)}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col span={6}>
                    {" "}
                    <img
                      alt="Place"
                      src={`${record.image}`}
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "30px",
                      }}
                    />
                  </Col>
                  <Col span={18}>
                    <Row>
                      <Col >
                        {" "}
                        <Tag color="#14A011" style={{ fontSize: "8px" }}>
                          NEW
                        </Tag>
                      </Col>
                      <Col>

                        {record.firstName.concat(" ", record.lastName)}

                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </>
        );
      },
    },
    {
      key: "id",

      title: (
        <div>
          <Typography.Text>DriverId</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setDriverId(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [driverId],
      onFilter: (value: any, record) => {
        return record.id
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (value: any, record) => {
        return record.id;
      },
      dataIndex: "id",
    },
    {
      key: "phoneNumber",


      title: (
        <div>
          <Typography.Text>Phone</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [phoneValue],
      onFilter: (value: any, record) => {
        return record.phoneNumber

          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (value: any, record) => {
        return record?.phoneNumber;
      },
      dataIndex: "phoneNumber",
    },
    {
      key: "vehicle",

      title: (
        <div>
          <Typography.Text>Vehicle</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setVehicle(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [vehicle],
      onFilter: (value: any, record) => {
        return record?.vehicleDto.vehicleModel
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (value: any, record) => {
        return record?.vehicleDto.vehicleModel;
      },
    },
    {
      key: "",
      title: (
        <div>
          <Typography.Text>Driver Status</Typography.Text>
        </div>
      ),
      render: (_, record) => {
        return (
          <div>
            <p>
              {record.onlineStatus === true ? (
                <Tag color="green">ONLINE</Tag>
              ) : (
                <Tag color="red">OFFLINE</Tag>
              )}
            </p>
          </div>
        );
      },
    },
    {
      key: "",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
        </div>
      ),
      render: (_, record) => {
        return (
          <>
            {record.isActive === true ? (
              <>
                <div>
                  Active
                </div>
                <Switch
                  onChange={(e) => toogleSwitch(record.id)}
                  checked={record.isActive}
                  style={{ backgroundColor: "green" }}
                />
              </>
            ) : (
              <>
                <div>
                  Inactive
                </div>
                <Switch
                  onChange={() => toogleSwitch(record.id)}
                  checked={record.isActive}
                  style={{ backgroundColor: "red" }}
                />
              </>
            )}
          </>
        );
      },
    },

    {
      key: "",
      render: (_, record) => {
        return (
          <>
            {record.isVerified === true ? (
              <>
                <ViewDetailsButton
                  onClick={() => openDriverDetails(record.id)}
                />
              </>
            ) : (
              <>
                <VerifyButton onClick={() => openDriverDetails(record.id)} />
              </>
            )}
          </>
        );
      },
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];
  const openDriverDetails = (id: number) => {
    navigate(`edit/${id}`);
  };

  const sum = data?.data.content.length;
  const active = countData?.data.isActive;
  const inActive = countData?.data.inActive;

  useEffect(() => {
    refetch(); 
  }, [refetch]);

  return (
    <>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard
          sum={sum}
          text="Drivers"
          activeText="Active"
          active={active}
          inActiveText="Inactive"
          inActive={inActive}
        />
        <Link to="add">
          <PrimaryButton> Add Driver </PrimaryButton>
        </Link>
      </Row>
      <LightBlueContainer>
        <WhiteContainer>
          <GlobalTableStyle>
            <Table<IDriver>
              loading={isLoading || isFetching}
              columns={columns}
              dataSource={data?.data.content}
              rowKey="id"
              pagination={{
                pageSize,
              }}
            />
          </GlobalTableStyle>
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}
