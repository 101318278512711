import {useEffect, useState } from "react";
import { Row, Button, Modal, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Table, { ColumnsType } from "antd/es/table";

import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { DeleteButton, PrimaryButton } from "../../styled/Button.styled";
import pageSize from "../../../constants/pageSize";
import { useDeleteCommunicationMutation, useGetAllCommunicationQuery } from "../../../feature/Communication/communicationApiSlice";

export default function Communication() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const { data, isLoading ,refetch} = useGetAllCommunicationQuery();
  const [deleteComById] = useDeleteCommunicationMutation();

  const [dataNew,setData]= useState([])
  const viewDetails = (id: number) => {
    navigate(`edit/${id}`, { state: { comId: id } });
  };

  const receivers = [
    {
      label: "Users",
      value: {
        riders: []
      }
    },
    {
      label: "Drivers",
      value: {
        drivers: []
      }
    },
    {
      label: "All",
      value: {
        riders: [],
        drivers: []
      }
    }
  ];
  

  const returName=(item:any)=>{
    return  item.riders && item.drivers ? "All" : item.riders ? "Rider" : "Driver"
  }
  
  useEffect(() => {
    if (data && data.data && data.data.content) {
      const myData = data.data.content.map((item: any, index: any) => {
        return { message: item.message, name: returName(item), ...item };
      });
      setData(myData);
    }
  }, [data]); 

  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Driver?",
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const onDelete = (id: number) => {
    deleteComById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      })
      .catch((error: any) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Messages",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Receiver",
      dataIndex: "name",
      key: "name",
     
    },
    {
      title: "Date",
      key: "publishDate",
      dataIndex: "publishDate",
    },
    {
      title: "Time",
      dataIndex: "publishTime",
      key: "publishTime",
    },

    {
      key: "key",
      align: "right",
      render: (record: any) => {
        return (
          <Button
            style={{
              height: "38px",
              background: "#3E4685",
              borderRadius: "5px",
            }}
            type="primary"
            onClick={() => {
              viewDetails(record.id);
            }}
          >
            View Details
          </Button>
        );
      },
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];

  useEffect(() => {
    refetch(); 
  }, [refetch]);

  return (
    <>
      <Row justify="end" className="button-bottom-margin">
        <Link to="add">
          <PrimaryButton> Add </PrimaryButton>
        </Link>
      </Row>
      <LightBlueContainer>
        <WhiteContainer>
          <Table<any>
            loading={isLoading}
            columns={columns}
            dataSource={dataNew}
            rowKey="id"
            pagination={{
              pageSize,
            }}
          />
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}
