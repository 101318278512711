import { Col, Row, Tag, Typography } from "antd";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import styled from "styled-components";

type DashboardCardProps = {
  heading?: string;
  subText?: string;
  cardIcon?: any;
  totalCount?: number;
  newCount?: number;
  internalCount?: string;
  individualCount?: string;
  groupCount?: string;
};

const DashboardLightBlueContainer = styled(LightBlueContainer)`
  padding: 5px;
  border: none;
`;
function DashboardCard(props: DashboardCardProps) {
  const { Text } = Typography;
  const { heading, subText, cardIcon, totalCount, newCount, internalCount } =
    props;
  return (
    <>
      <DashboardLightBlueContainer>
        <Row align={"middle"}>
          <Text className="dashboard-card-heading-text-size">{heading}</Text>
        </Row>

        <WhiteContainer className="inner-white-container-style">
          <Row>
            <Col span={12}>
              <Row className="dashboard-card-row-height">
                <Row>
                  <Col span={24}>
                    <Text strong className="dashboard-count-text-size">
                      {totalCount}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Tag color="success">
                      <Text className="green-color-text">
                        {newCount}&nbsp;{subText}
                      </Text>
                    </Tag>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col span={12}>
              <Row className="dashboard-card-row-height" justify={"end"}>
                <Row>
                  <Col span={24}>{cardIcon}</Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Text>{internalCount}</Text>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </WhiteContainer>
      </DashboardLightBlueContainer>
    </>
  );
}

export default DashboardCard;