import { useEffect, useState } from "react";

import { Col, Input, Row, Table, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  GlobalTableStyle,
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { ColumnsType } from "antd/es/table";
import Card from "../../countCard/Card";
import pageSize from "../../../constants/pageSize";
import { useGetAllTripQuery } from "../../../feature/booking/bookingApiSlice";
import { ViewDetailsButton } from "../../styled/Button.styled";
import constants from "../../../constants";
import { IBooking } from "../../../types/booking";
import moment from "moment";
const defaultImage: string = require("../../../assets/logo/defaultImage.svg").default;

const Bookings = () => {
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useGetAllTripQuery();

  const [nameValue, setNameValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [statusValue, setStatusValue] = useState("");

  const columns: ColumnsType<IBooking> = [
    {
      key: "userName",
      width: "22%",
      ellipsis:true,
      title: (
        <div>
          <Typography.Text> Name</Typography.Text>
          <div>
          <Input.Search
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
            placeholder="Search here"
            />
            </div>
        </div>
      ),
      filteredValue: [nameValue],
      onFilter: (value: any, record) => {
        return record?.userName
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (_: any, record) => {
        return (
          <>
            <Row>
              <Col><img
              alt="user"
              src={`${record.userImage}`}
              onError={(e) => {
                (e.target as HTMLSourceElement).src= defaultImage
             }}
              style={{ height: "48px", width: "48px", borderRadius: "30px" }}
            />
           </Col>
              <Col style={{marginTop:"0.8rem"}}>  {record.userName}</Col>
            </Row>
            
          </>
        );
      },
      dataIndex: "userName",
    },
    {
      key: "userNumber",
      title: (
        <div>
          <Typography.Text>Phone</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [phoneValue],
      onFilter: (value: any, record) => {
        return record.userNumber
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      dataIndex: "userNumber",
    },
    {
      key: "date",
      title: (
        <div>
          <Typography.Text>Date</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setDateValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [dateValue],
      onFilter: (value: any, record) => {
        return record.date
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (_: any, record) => {
        return moment(new Date(record.date)).format("DD-MM-YYYY");
      },
      dataIndex: "date",
    },
    {
      key: "tripType",
      title: (
        <div>
          <Typography.Text>Type</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setTypeValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [typeValue],
      onFilter: (value: any, record) => {
        return record.tripType?.toLowerCase().includes(value?.toLowerCase());
      },
      dataIndex: "tripType",
    },
    {
      key: "status",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setStatusValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [statusValue],
      onFilter: (value: any, record) => {
        return record.tripStatus?.toLowerCase().includes(value?.toLowerCase());
      },
      dataIndex: "tripStatus",
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openBookingDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
  ];
  const openBookingDetails = (id: number) => {
    navigate(`edit/${id}`);
  };

  const sum = data?.data.content.length;

  useEffect(() => {
    refetch(); 
  }, [refetch]);

  return (
    <>
      <Row justify="space-between" className="button-bottom-margin">
        <Card sum={sum} text="Bookings" />
      </Row>
      <LightBlueContainer>
        <WhiteContainer>
          <GlobalTableStyle>
            <Table<IBooking>
              loading={isLoading}
              columns={columns}
              dataSource={data?.data.content}
              rowKey="id"
              pagination={{
                pageSize,
              }}
            />
          </GlobalTableStyle>
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
};
export default Bookings;