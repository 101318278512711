import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { LoadScript } from "@react-google-maps/api";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LoadScript id="script-loader" googleMapsApiKey="AIzaSyBHJ339Q0UfErQfihMr1qEi7JY7V07bn8M" libraries={["places"]}>
        <BrowserRouter>
          <ConfigProvider
            theme={{
              token: {

              },
            }}
          >
            <App />
          </ConfigProvider>
        </BrowserRouter>
      </LoadScript>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
