import { Routes, Route } from "react-router-dom";

import Login from "../../Components/Modules/Login/Login";
import ForgotPassword from "../../Components/Modules/Login/ForgotPassword";
import CreateNewPassword from "../../Components/Modules/Login/CreateNewPassword";

import Layout from "../../Components/Layouts/Layout";

import Bookings from "../../Components/Modules/Booking/Bookings";
import EditBooking from "../../Components/Modules/Booking/EditBookings";

import Popularplaces from "../../Components/Modules/PopularPlaces/PopluarPlaces";
import PopularplacesView from "../../Components/Modules/PopularPlaces/EditPlaces";
import PopularPlacesAdd from "../../Components/Modules/PopularPlaces/AddPlaces";

import Banner from "../../Components/Modules/Banner/Banner";
import BannerNew from "../../Components/Modules/Banner/AddBanner";
import BannerDetails from "../../Components/Modules/Banner/EditBannerDetails";

import Fare from "../../Components/Modules/Fare/Fare";
import AddFare from "../../Components/Modules/Fare/AddFare/AddFare";
import EditFare from "../../Components/Modules/Fare/EditFare";

import Communication from "../../Components/Modules/Communication/Communication";
import CommunicationAddDetails from "../../Components/Modules/Communication/Communication_AddDetails";
import CommunicationViewDetails from "../../Components/Modules/Communication/Communication_ViewDetails";

import Driver from "../../Components/Modules/Driver/Driver";
import AddDriver from "../../Components/Modules/Driver/addDriver/AddDriver";
import DriverDetails from "../../Components/Modules/Driver/EditDriverDetails";

import Category from "../../Components/Modules/Category/Category";
import AddCategory from "../../Components/Modules/Category/AddCategory";
import EditCategory from "../../Components/Modules/Category/EditCategory";

import Offers from "../../Components/Modules/Offers/Offers";
import EditOffers from "../../Components/Modules/Offers/EditOffers";
import AddOffers from "../../Components/Modules/Offers/AddOffers";

import Users from "../../Components/Modules/User/Users";
import UserDetails from "../../Components/Modules/User/UserDetails";

import Transaction from "../../Components/Modules/Transaction/Transaction";

import Profile from "../../Components/Modules/Profile/EditProfile";

import Dashboard from "../../Components/Modules/Dashboard/Dashboard";
import Notification from "../../Components/Modules/Notification/Notification";
import PrivateRoute from "../../Components/PrivateRoute/PrivateRoute";
import Rental from "../../Components/Modules/Fare/AddFare/Rental";
import Calender from "../../Components/DashboardComponent/CalenderFile";
import ViewTransction from "../../Components/Modules/Transaction/viewTransactions";
function App() {
  return (
    <>
      <Routes>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:id" element={<CreateNewPassword />} />
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="admin-notification" element={<Notification />} />
            <Route path="calender" element={<Calender />} />

            <Route path="bookings">
              <Route index element={<Bookings />} />
              <Route path="edit/:id" element={<EditBooking />} />
            </Route>

            <Route path="users">
              <Route index element={<Users />} />
              <Route path="edit/:id" element={<UserDetails />} />
            </Route>

            <Route path="drivers">
              <Route index element={<Driver />} />
              <Route path="add" element={<AddDriver />} />
              <Route path="edit/:id" element={<DriverDetails />} />
            </Route>
            <Route path="popularplaces">
              <Route index element={<Popularplaces />} />
              <Route path="add" element={<PopularPlacesAdd />} />
              <Route path="edit/:id" element={<PopularplacesView />} />
            </Route>
            <Route path="categories">
              <Route index element={<Category />} />
              <Route path="add" element={<AddCategory />} />
              <Route path="edit/:id" element={<EditCategory />} />
            </Route>

            <Route path="offers">
              <Route index element={<Offers />} />
              <Route path="add" element={<AddOffers />} />
              <Route path="edit/:id" element={<EditOffers />} />
            </Route>

            <Route path="banners">
              <Route index element={<Banner />} />
              <Route path="add" element={<BannerNew />} />
              <Route path="edit/:id" element={<BannerDetails />} />
            </Route>

            <Route path="communication">
              <Route index element={<Communication />} />
              <Route path="add" element={<CommunicationAddDetails />} />
              <Route path="edit/:id" element={<CommunicationAddDetails />} />
            </Route>

            <Route path="fares">
              <Route index element={<Fare />} />
              <Route path="add" element={<AddFare />} />
              <Route path="edit/:id" element={<EditFare />} />
            </Route>
            <Route path="transactions">
              <Route index element={<Transaction />} />
              <Route path="view/:id" element={<ViewTransction />} />
            </Route>
            <Route path="profile">
               <Route index element={<Profile />} />
            </Route>
            <Route path="/rental/:id" element={<Rental />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
