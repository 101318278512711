import { Row, Col, Form, Modal } from "antd";
import { MdDelete } from "react-icons/md";

import React, { Fragment, useEffect, useState } from "react";
import { AddButton, DeleteButton } from "../../styled/Button.styled";
import { ICategoryByIdResponse } from "../../../types/category";
import SelectVehicleMake from "../../selectOption/SelectVehicleMake";
import SelectVehicleModel from "../../selectOption/SelectVehicleModel";
import { useDeleteVehicleByIdMutation } from "../../../feature/Category/categoryApiSlice";
import { promises } from "dns";

type CategoryProps = {
  data?: ICategoryByIdResponse;
};

function CategoryForm(props: CategoryProps) {
  const { data } = props;
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const [deleteVehicle] = useDeleteVehicleByIdMutation();
  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Category?",
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const onDelete = (id: number) => {
    deleteVehicle({ categoryId: data?.data?.id, id: data?.data?.vehicles[id].id });
  };
 

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="categoryVehiclesForm"
        initialValues={{
          vehicles: data?.data.vehicles,
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={24}>
          {data?.data.name && (
              <Row gutter={[16,16]} justify="start" align="middle">
                <Col span={3}>
                  <label >Category Name:</label>
                </Col>
                <Col span={6}>
                  <span >{data?.data.name}</span>
                </Col>
              </Row>
            )}
            <Form.List name="vehicles">
              {(fields, { add, remove }) => (
                <>
                  <Row justify="end" className="button-bottom-margin">
                    <AddButton
                      onClick={() => {
                        add();
                      }}
                    >
                      Add Vehicle
                    </AddButton>
                  </Row>

                  <Row className="borderCategoryChart" justify={"center"}>
                    {fields.map((field, index) => (
                      <Row className="borderRecord">
                        <Fragment key={field.key}>
                          <Row
                            gutter={[10, 32]}
                            justify={"space-between"}
                            align={"middle"}
                          >
                            <Col>{index + 1}.</Col>
                            <Col className="common-select-dropdown">
                              <SelectVehicleMake
                                name={[index, `id`]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select vehicle make",
                                  },
                                ]}
                                label="Vehicle Make"
                                form={form}
                              />
                            </Col>
                            <Col className="common-select-dropdown">
                              <SelectVehicleModel
                                name={[index, `vehicleModel`]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select vehicle model",
                                  },
                                  ({ getFieldsValue }) => ({
                                    validator: async (_, value) => {
                                      const count = getFieldsValue().vehicles.filter((val: any) => { if (val.vehicleModel === value) { return val.vehicleModel; } });
                                      if (count.length > 1) {
                                        return Promise.reject("cannot have same vehicle model");
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                                label="Vehicle Model"
                                form={form}
                              />
                            </Col>
                            <Col>
                              {fields.length > 1 ? (
                                <DeleteButton onClick={() => { remove(field.name); }} />
                              ) : null}
                            </Col>
                          </Row>
                        </Fragment>
                      </Row>
                    ))}
                  </Row>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default CategoryForm;
