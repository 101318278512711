import { Form, Select } from "antd";
type IOptions = {
  value: number;
  label: string;
};

const options: IOptions[] = [
  {
    value: 0,
    label: "0%",
  }
];

for(let i=1;i<=100;i++){
    options.push({
        value: i,
        label: `${i+"%"}`,
      });
}

const { Option } = Select;
function SelectDriverPercentage() {
  return (
    <Form.Item name="driverPercentage"
      rules={[
        {
          required: true,
          message: 'Please select Driver Percentage'
        }
      ]}
      style={{
        marginBottom: '4px', 
      }}
      >
      <Select>
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectDriverPercentage;