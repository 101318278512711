import {useEffect, useState } from "react";
import {
  Row,
  Input,
  Table,
  Typography,
  Switch,
  Modal,
  notification,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  GlobalTableStyle,
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { ColumnsType } from "antd/es/table";
import {
  DeleteButton,
  PrimaryButton,
  ViewDetailsButton,
} from "../../styled/Button.styled";
import CustomCard from "../../countCard/CustomCard";
import pageSize from "../../../constants/pageSize";
import constants from "../../../constants";
import {
  useGetAllCategoryQuery,
  useGetCategoryCountQuery,
  useDeleteCategoryByIdMutation,
  useUpdateCategoryStatusByIdMutation,
} from "../../../feature/Category/categoryApiSlice";
import { ICategory } from "../../../types/category";
import dummyFace from "../../../assets/dummyFace.png";
const defaultImage: string = require("../../../assets/logo/defaultImage.svg").default;

function Category() {
  const { data, isLoading, isFetching,refetch } = useGetAllCategoryQuery();

  const { data: countData } = useGetCategoryCountQuery();
  const navigate = useNavigate();
  const { confirm } = Modal;

  const [nameValue, setNameValue] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [deleteCategoryById] = useDeleteCategoryByIdMutation();
  const [updateCategoryStatusById] = useUpdateCategoryStatusByIdMutation();
  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Category?",
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const onDelete = (id: number) => {
    deleteCategoryById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      });
  };
  const toogleSwitch = (id: any) => {
    updateCategoryStatusById(id);
  };
  const columns: ColumnsType<ICategory> = [
    {
      key: "name",
      width: "30%",
      title: (
        <div>
          <Typography.Text> Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [nameValue],
      onFilter: (value: any, record) => {
        return record?.name?.toLowerCase().includes(value?.toLowerCase());
      },
      render: (value: any, record) => {
        return (
          <>
            <Row align={"middle"}>
              <Col span={6}>
                <img
                  alt="vehicle"
                  src={`${record.image}`}
              onError={(e) => {
                (e.target as HTMLSourceElement).src= defaultImage
             }}
                  style={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "30px",
                  }}
                />
              </Col>
              <Col span={18}>{record.name}</Col>
            </Row>
          </>
        );
      },
      dataIndex: "name",
    },
    {
      key: "vehicle",
      width: "25%",
      title: (
        <div>
          <Typography.Text>Vehicle</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setVehicle(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [vehicle],
      onFilter: (value: any, record) => {
        return record?.vehiclesCount
          ?.toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (_, record) => {
        return record?.vehiclesCount;
      },
      dataIndex: "vehiclesCount",
    },
    {
      key: "",
      align: "right",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
        </div>
      ),
      render: (_, record) => {
        return (
          <>
            {record.status === true ? (
              <>
                <div>
                  Active
                </div>
                <Switch
                  onChange={(e) => toogleSwitch(record.id)}
                  checked={record.status}
                  style={{ backgroundColor: "green" }}
                />
              </>
            ) : (
              <>
                <div>
                  Inactive
                </div>
                <Switch
                  onChange={() => toogleSwitch(record.id)}
                  checked={record.status}
                  style={{ backgroundColor: "red" }}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      key: "viewDetails",
      align: "right",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openPlaceDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
    {
      key: "deleteButton",
      align: "right",
      width: "1%",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];
  const openPlaceDetails = (id: number) => {
    navigate(`edit/${id}`);
  };

  const sum = data?.data.content.length;
  const active = countData?.data.isActive;
  const inActive = countData?.data.inActive;
  
  useEffect(() => {
    refetch(); 
  }, [refetch]);

  return (
    <>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard
          sum={sum}
          text="Categories"
          activeText="Active"
          active={active}
          inActiveText="Inactive"
          inActive={inActive}
        />
        <Link to="add">
          <PrimaryButton> Add Category</PrimaryButton>
        </Link>
      </Row>
      <LightBlueContainer>
        <WhiteContainer>
          <GlobalTableStyle>
            <Table<ICategory>
              loading={isLoading || isFetching}
              columns={columns}
              dataSource={data?.data.content}
              rowKey="id"
              pagination={{
                pageSize,
              }}
            />
          </GlobalTableStyle>
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}
export default Category;
