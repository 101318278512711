import styled from "styled-components";
import { Button } from "antd";
import { ButtonProps } from "antd";

import { MdAddBox, MdDelete ,MdCalendarToday} from "react-icons/md";

const PrimaryButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} />
))`
  min-width: 120px;
  height: 3rem;
  font-size: 20px;
  background: #3e4685;
`;

const SentOTPButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} />
))`
  dispaly: flex;
  min-width: 15rem;
  height: 3rem;
  font-size: 1rem;
  background: #3e4685;
  border-radius: 30px;
`;



const SecondaryButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} />
))`
  font-size: 14px;
`;


const CloseButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props}>
    Close
  </Button>
))`
  min-width: 120px;
  height: 2.5rem;
  font-size: 20px;
  background: #3e4685;
  margin-top: 14%;
`;


const AddButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props} icon={<MdAddBox color="#3e4685" />} />
))`
  min-width: 120px;
  height: 2rem;
  background: #ffffff;
  border: 2px solid #3e4685;
 color: #3e4685; 
`;

const ViewDetailsButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props}>
    View Details
  </Button>
))`
  font-size: 14px;
  background: #3e4685;
`;
const VerifyButton = styled((props: ButtonProps) => (
  <Button type="primary" {...props}>
    Verify
  </Button>
))`
  min-width: 105px;
  font-size: 14px;
  background: #3e4685;
`;

const ResendButton = styled((props: ButtonProps) => (
  <Button type="link" {...props} />
))`
  color: #3e4685;
  
`;

const DeleteButton = styled((props: ButtonProps) => (
  <Button {...props}>
    <MdDelete  size={22}/>
  </Button>
))`
  color:black;
  border: none;
  border-radius: 3px;
  box-shadow: none;
`;
const CalenderButton = styled((props: ButtonProps) => (
  <Button {...props}>
    <MdCalendarToday  size={20}/>
  </Button>
))`
  color:black;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  background: transparent;
  margin-bottom:0px;
`;
export {
  PrimaryButton,
  ViewDetailsButton,
  CloseButton,
  AddButton,
  SecondaryButton,
  SentOTPButton,
  DeleteButton,
  VerifyButton,
  ResendButton,
  CalenderButton
};
