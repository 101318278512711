import { Form, Input, Select, Col, Row, Card, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import constants from "../../../../constants";
import { IDriverByIdResponse } from "../../../../types/driver";
import ServiceType from "../../../addDriverCheckBox/ServiceType";
import { UploadImage } from "../../../axios/imageUpload";
import { useGetVehiclesQuery } from "../../../../feature/Driver/driverApiSlice";
import dayjs from 'dayjs';
import { handleImageSize } from "../../../../utils/handleImageSize";

type VehicleDetailsProps = {
  data?: IDriverByIdResponse;
  setRCImage?: any;
  setTouristPermitBackImage?: any;
  setTouristPermitFrontImage?: any;
  vehicleDetailsResponse?: any;
};
const imageUploaderStyle = {
  width: "12rem",
  height: "8rem",
};
export default function VehicleDetails(props: VehicleDetailsProps) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const {
    data,
    setRCImage,
    setTouristPermitFrontImage,
    setTouristPermitBackImage,
    vehicleDetailsResponse,
  } = props;
  const [vehicleState, setVehicleState] = useState([]);
  const [vehicleMakeId, setVehicleMakeId] = useState<string>("");
  const [selectedService, setSelectedService] = useState("");
  const [vehicleModelState, setVehicleModel] = useState([]);
  const [rcImgFront, setRcImgFront] = useState({
    image_file: '',
    image_preview: "",
  });

  const [permitFrontImg, setPermitFrontImg] = useState({
    fImage_file: '',
    fImage_preview: "",
  });
  const [permitBackImg, setPermitBackImg] = useState({
    bImage_file: '',
    bImage_preview: "",
  });

  const rcImgHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
  
      const resultImage= await handleImageSize(file);

      if(resultImage!==undefined){
        const compressedFile:File=resultImage;
        const image_as_base64 = URL.createObjectURL(compressedFile);
  
        let fd = new FormData();
        fd.append('image', compressedFile);

        UploadImage(fd)
          .then((res) => {
            setRcImgFront({
              image_preview: image_as_base64,
              image_file: res.data,
            });
            setRCImage(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        }
    }
  };
  const frontImgHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const resultImage= await handleImageSize(file);

      if(resultImage!==undefined){
        const compressedFile:File=resultImage;
        const image_as_base64 = URL.createObjectURL(compressedFile);
  
        let fd = new FormData();
        fd.append('image', compressedFile);

        UploadImage(fd)
          .then((res) => {
            setPermitFrontImg({
              fImage_preview: image_as_base64,
              fImage_file: res.data,
            });
            setTouristPermitFrontImage(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        }
    }
  };

  const backImgHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
  
      const resultImage= await handleImageSize(file);

      if(resultImage!==undefined){
        const compressedFile:File=resultImage;
        const image_as_base64 = URL.createObjectURL(compressedFile);
  
        let fd = new FormData();
        fd.append('image', compressedFile);

        UploadImage(fd)
          .then((res) => {
            setPermitBackImg({
              bImage_preview: image_as_base64,
              bImage_file: res.data,
            });
            setTouristPermitBackImage(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        }
    }
  };



  const { data: vehicles } = useGetVehiclesQuery();
  useEffect(() => {
    const vehicleMake = vehicles?.data?.map((item: any) => (
      {
        id: item.id,
        name: item.vehicleMake
      }
    ));
    setVehicleState(vehicleMake);
  }, [vehicles]);

  // useEffect(() => {
  //   vehicles?.data?.find((item: any) => {
  //     if (item.id === vehicleMakeId) {
  //       setVehicleModel(item.vehicleModel.map((model: any) => { return { id: model, name: model }; }));
  //     }
  //     return item;
  //   });
  // }, [vehicleMakeId, vehicles, vehicleModelState]);

  useEffect(() => {
    setRcImgFront({
      image_file: `${data?.data.vehicleDto.rcImage.rcFrontImage}`,
      image_preview: `${data?.data.vehicleDto.rcImage.rcFrontImage}`,
    });
    setRCImage(data?.data.vehicleDto.rcImage.rcFrontImage);

    setPermitFrontImg({
      fImage_preview: `${data?.data.vehicleDto.touristPermitDTO.touristPermitFrontImage}`,
      fImage_file: `${data?.data.vehicleDto.touristPermitDTO.touristPermitFrontImage}`,
    });
    setTouristPermitFrontImage(data?.data.vehicleDto.touristPermitDTO.touristPermitFrontImage);

    setPermitBackImg({
      bImage_preview: `${data?.data.vehicleDto.touristPermitDTO.touristPermitBackImage}`,
      bImage_file: `${data?.data.vehicleDto.touristPermitDTO.touristPermitBackImage}`,
    });
    setTouristPermitBackImage(data?.data.vehicleDto.touristPermitDTO.touristPermitBackImage);
  }, [data]);


  const [formData, setFormData] = useState({
    vehicleMake: "",
    vehicleModel: "",
    vehicleYear: "",
    fuelType: "",
    vehicleNumber: "",
    chassisNumber: "",
    rcImage: {
      rcNumber: ""
    },
    taxiServiceTypeDTOList: "",

  });

  const handleFieldChange = (fieldName: any, value: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
    let res = Object.values(formData);
  };
  const handleService = (response: any) => {
    handleFieldChange("taxiServiceTypeDTOList", response[0]);
    setSelectedService(response[0]);
  };

  return (
    <>
      <Form
        form={form}
        name="vehicleDetailForm"
        initialValues={{
          vehicleMake: data?.data.vehicleDto.vehicleMake[0].id,
          vehicleYear: dayjs(data?.data.vehicleDto.vehicleYear.toString()),
          vehicleModel: data?.data.vehicleDto.vehicleModel,
          vehicleNumber: data?.data.vehicleDto.vehicleNumber,
          chassisNumber: data?.data.vehicleDto.chassisNumber,
          fuelType: data?.data.vehicleDto.fuelType,
          rcNumber: data?.data.vehicleDto.rcImage.rcNumber,
          rcFrontImage: data?.data.vehicleDto.rcImage.rcFrontImage,
          rcBackImage: data?.data.vehicleDto.rcImage.rcBackImage,
          taxiServiceTypeDTOList: data?.data.vehicleDto.taxiServiceTypeDTOList,
          touristPermitDTO: {
            touristPermitNumber:
              data?.data.vehicleDto.touristPermitDTO.touristPermitNumber,
          }
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <label>Vehicle Make</label>
                <Form.Item
                  name="vehicleMake"
                  rules={[
                    { required: true, message: "Vehicle Make is required" },
                  ]}
                >
                  <Select onSelect={(e) => {
                    handleFieldChange("vehicleMake", e);
                    vehicles?.data?.filter((item: any) => {
                      if (item.id === e) {
                        setVehicleModel(item.vehicleModel.map((model: any) => { return { id: model, name: model }; }));
                      }
                      return item;
                    });
                  }}>
                    {
                      !!vehicleState && vehicleState.map((vehicle: any) => {
                        return (
                          <Option key={vehicle.id} value={vehicle.id}>
                            {vehicle.name}
                          </Option>
                        );
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Vehicle Model</label>
                <Form.Item
                  name="vehicleModel"
                  rules={[
                    { required: true, message: "Vehicle Model is required" },
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      handleFieldChange("vehicleModel", e);
                    }}
                  >
                    {
                      !!vehicleModelState && vehicleModelState.map((v: any) => {
                        return (
                          <Option key={v.id} value={v.id}>{v.name}</Option>
                        );
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Vehicle year</label>
                <Form.Item
                  name="vehicleYear"
                  rules={[
                    { required: true, message: "Vehicle year is required" },
                  ]}
                >
                  <DatePicker picker="year" style={{ width: "100%" }} onChange={(e) => handleFieldChange("vehicleYear", e)} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Fuel Type</label>
                <Form.Item
                  name="fuelType"
                  rules={[{ required: true, message: "Fuel Type is required" }]}
                >
                  <Select onChange={(e) => handleFieldChange("fuelType", e)}>
                    <Option value="PETROL">Petrol</Option>
                    <Option value="DIESEL">Diesel</Option>
                    <Option value="CNG">CNG</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Vehicle Number</label>
                <Form.Item
                  name="vehicleNumber"
                  rules={[
                    {
                      required: true,
                      message: " Vehicle Number is required",
                    },
                  ]}
                >
                  <Input
                    className="borderb"
                    onChange={(e) =>
                      handleFieldChange("vehicleNumber", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Chassis Number</label>
                <Form.Item
                  name="chassisNumber"
                  rules={[
                    {
                      required: true,
                      message: "Chassis Number is required",
                    },
                  ]}
                >
                  <Input
                    className="borderb"
                    onChange={(e) =>
                      handleFieldChange("chassisNumber", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <label>Service Type</label>
              <div className="borderBoxService">
                <ServiceType handleService={handleService} />
              </div>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <label>Vehicle Registration Certificate</label>
                <div className="borderBoxPermit">
                  <Form.Item
                    name="rcNumber"
                    rules={[
                      { required: true, message: "Vehicle RC is required" },
                    ]}
                  >
                    <Input
                      onChange={(e) =>
                        handleFieldChange("rcNumber", e.target.value)
                      }
                    />
                  </Form.Item>
                  <label>Vehicle RC</label>
                  <Row>
                    <Col span={12}>
                      <Card>
                        <img
                          src={rcImgFront.image_preview}
                          alt=" RC Img"
                          style={imageUploaderStyle}
                        />
                      </Card>
                      <input
                        type="file"
                        className="custom-frontbtn"
                        name="rcImage.rcFrontImage"
                        onChange={rcImgHandler}
                      />
                    </Col>

                    {/* <Col span={12}>
                      <Card>
                        <img
                          src={permitBackImg.bImage_preview}
                          alt=" Back Img"
                          style={imageUploaderStyle}
                        />
                      </Card>
                      <input
                        type="file"
                        className="custom-backbtn"
                        name="backImage"
                        onChange={backImgHandler}
                      />
                    </Col> */}
                  </Row>
                </div>
              </Col>
              {selectedService === "OUTSTATION_TAXI" ? (
                <Col span={24}>
                  <label>Tourist Permit</label>
                  <div className="borderBoxPermit">
                    <Form.Item
                      name="touristPermitNumber"
                      rules={[
                        {
                          required: true,
                          message: "Tourist Permit is required",
                        },
                      ]}
                    >
                      <Input

                      />
                    </Form.Item>
                    <label>Tourist Permit</label>
                    <Row>
                      <Col span={12}>
                        <Card>
                          <img
                            src={permitFrontImg.fImage_preview}
                            alt=" Front Img"
                            style={imageUploaderStyle}
                          />
                        </Card>
                        <input
                          type="file"
                          className="custom-frontbtn"
                          name="touristPermitDTO.touristPermitFrontImage"
                          onChange={frontImgHandler}
                        />
                      </Col>

                      <Col span={12}>
                        <Card>
                          <img
                            src={permitBackImg.bImage_preview}
                            alt=" Back Img"
                            style={imageUploaderStyle}
                          />
                        </Card>
                        <input
                          type="file"
                          className="custom-backbtn"
                          name="touristPermitDTO.touristPermitBackImage"
                          onChange={backImgHandler}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}
