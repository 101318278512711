import { Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../../styled/Button.styled";
import { useGetRiderByIdQuery } from "../../../feature/user/userApiSlice";
import UserDetailsForm from "./UserDetailsForm";


function UserDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetRiderByIdQuery(id)
  const onClose = () => {
    navigate(-1);
  };

  return (
    <>
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <PrimaryButton onClick={onClose}>Back</PrimaryButton>
        </Col>
      </Row>
      {!isError && !isLoading && data && (
        <UserDetailsForm data={data} />
      )}
    </>
  );
}
export default UserDetails