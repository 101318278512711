import { Form, Row, Col, notification, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useAddCategoryMutation,
  useUploadCategoryImageMutation,
} from "../../../feature/Category/categoryApiSlice";

import { PrimaryButton } from "../../styled/Button.styled";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import BasicDetails from "./BasicDetails";
import CategoryForm from "./CategoryForm";
import { useState } from "react";

const AddCategory = () => {
  const [addCategory] = useAddCategoryMutation();
  const [categoryImageForm, setCategoryImageForm] = useState<FormData>(
    new FormData()
  );
  const [uploadCategoryImage] = useUploadCategoryImageMutation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  const onChange = (activeKey: string) => setActiveKey(activeKey);

  const back = () => {
    navigate("/categories");
  };
  const tabItems = [
    {
      label: "Basic Details",
      key: "1",
      children: (
        <WhiteContainer>
          <BasicDetails
            setCategoryImageForm={setCategoryImageForm}
            setActiveKey={setActiveKey}
          />
        </WhiteContainer>
      ),
    },

    {
      label: "Category Vehicle",
      key: "2",
      activeKey: activeKey,
      children: (
        <WhiteContainer>
          <CategoryForm />
        </WhiteContainer>
      ),
    },
  ];
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { CategoryDetailsForm, categoryVehiclesForm } = forms;
            const categoryData = { ...CategoryDetailsForm.getFieldsValue() };
            const categoryVehicleData = {
              ...categoryVehiclesForm.getFieldsValue(),
            };
            const categoryServices = categoryData?.taxiServiceType?.map(
              (item: any) => ({
                taxiType: item,
                isActive: true,
                isDeleted: false,
              })
            );
            CategoryDetailsForm.validateFields()
              .then(() => {
                categoryVehiclesForm
                  .validateFields()
                  .then(() => {
                    if (categoryVehicleData.vehicles.length < 1) {
                      notification.info({
                        message: "Alert",
                        description: "Please add vehicles",
                        placement: "bottomLeft",
                      });
                      return;
                    }
                    const obj = {
                      ...categoryData,
                      taxiServiceType: categoryServices,
                      ...categoryVehicleData,
                    };
                    addCategory(obj)
                      .unwrap()
                      .then((res: any) => {
                        uploadCategoryImage({
                          id: res?.data?.id,
                          formData: categoryImageForm,
                        });
                        if (res.statusCode === 500) {
                          notification.error({
                            message: res.message,
                            placement: "bottomLeft",
                          });
                        } else {
                          notification.success({
                            message: "Successfully added",
                            placement: "bottomLeft",
                          });
                          navigate(-1);
                        }
                      });
                  })
                  .catch(() => {
                    notification.info({
                      message: "Alert",
                      description: "Please add vehicles",
                      placement: "bottomLeft",
                    });
                  });
              })
              .catch(() => {
                notification.info({
                  message: "Alert",
                  description: "Please fill name and service-type",
                  placement: "bottomLeft",
                });
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <PrimaryButton onClick={back}>Back</PrimaryButton>
          </Col>
          <Col>
            <Form name="submitForm" disabled={activeKey !== "2"}>
              <PrimaryButton htmlType="submit">Save</PrimaryButton>
            </Form>
          </Col>
        </Row>
        <LightBlueContainer>
          <Tabs items={tabItems} activeKey={activeKey} onChange={onChange} />
        </LightBlueContainer>
      </Form.Provider>
    </>
  );
};

export default AddCategory;
