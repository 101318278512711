import { Col, Row, Tabs, Form, notification } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import { useGetFareByIdQuery, useUpdateFareByIdMutation } from "../../../feature/Fare/fareApiSlice";
import { PrimaryButton } from "../../styled/Button.styled";
import {
  LightBlueContainer,
  WhiteContainer
} from "../../styled/Container.styled";
import Local from "./AddFare/Local";
import Outstation from "./AddFare/Outstation";
import Rental from "./AddFare/Rental";
import { useState } from "react";

function EditFare() {
  const { id } = useParams();
  const { data, isError, isLoading } = useGetFareByIdQuery(id);
  const [updateFareById] = useUpdateFareByIdMutation();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [form] = Form.useForm();
  const [tab, setTab] = useState<string>(location?.state?.tab || "1"); 
  const [outStationform] = Form.useForm();
  const [rentalForm] = Form.useForm();
  const onClose = () => {
    navigate(-1);
  };


  const onsubmit = () => {
    const { name, baseFare, flatCharges, bookingCharges, gst, nightChargeAmount, waitingCharges, nightChargeEndTime, nightChargeStartTime } = form.getFieldsValue();
    const fd = {
      id: Number(id),
      localFareDTO: {
        flatCharges: +flatCharges,
        categoryId: typeof name === 'string' && name.length <= 10 ? data?.data?.localFareDTO.categoryId : +JSON.parse(name).id,
        name: typeof name === 'string' && name.length <= 10 ? data?.data?.localFareDTO.name : name,
        baseFare: +baseFare,
        bookingCharges: +bookingCharges,
        gst: +gst,
        nightCharges: {
          id: data?.data?.localFareDTO?.nightCharges?.id,
          nightChargeAmount: +nightChargeAmount,
          nightChargeEndTime: dayjs(nightChargeEndTime).format("HH:mm"),
          nightChargeStartTime: dayjs(nightChargeStartTime).format("HH:mm")
        },
        waitingCharges: +waitingCharges,
      },
      outstationFares: data?.data?.outstationFares,
      rentalChartList: data?.data?.rentalChartList
    };
    updateFareById({ id, post: fd })
      .then((res: any) => {
        if (res?.data) {
          notification.success({
            message: "Successfully Updated!",
            placement: "bottomLeft",
          });
          navigate(-1);
        } else {
          notification.error({
            message: res?.error?.data?.message || "Failed to save",
            placement: "bottomLeft",
          });
        }
      });
  };

  const outStationSubmit = () => {
    const { name, baseFare, bookingCharges, gst, nightChargeAmount, waitingCharges, nightChargeEndTime, nightChargeStartTime } = outStationform.getFieldsValue();
    const fd = {
      id: Number(id),
      outstationFares: {
        categoryId: name.length <= 10 ? data?.data?.outstationFares.categoryId : + JSON.parse(name).id,
        name: name.length <= 10 ? data?.data?.outstationFares.name : name,
        baseFare: +baseFare,
        bookingCharges: +bookingCharges,
        gst: +gst,
        nightCharges: {
          id: data?.data?.outStationFares?.nightCharges?.id,
          nightChargeAmount: +nightChargeAmount,
          nightChargeEndTime: dayjs(nightChargeEndTime).format("HH:mm"),
          nightChargeStartTime: dayjs(nightChargeStartTime).format("HH:mm")
        },
        waitingCharges: +waitingCharges,
      },
      localFareDTO: data?.data?.localFareDTO,
      rentalChartList: data?.data?.rentalChartList
    };
    updateFareById({ id, post: fd })
      .then((res: any) => {
        if (res?.data) {
          notification.success({
            message: "Successfully Updated!",
            placement: "bottomLeft",
          });
          navigate(-1);
        } else {
          notification.error({
            message: res?.error?.data?.message || "Failed to save",
            placement: "bottomLeft",
          });
        }
      });
  };

  const rentalFareSubmit = () => {
    const { name, gst, nightChargeAmount, nightChargeEndTime, nightChargeStartTime, rentalCharts } = rentalForm.getFieldsValue();
    const fd = {
      id: Number(id),
      outstationFares: data?.data?.outstationFares,
      localFareDTO: data?.data?.localFareDTO,
      rentalChartList: {
        categoryId: name.length < 20 ? data?.data?.rentalChartList?.categoryId : +JSON.parse(name).id,
        gst: +gst,
        name: name.length < 20 ? data?.data?.rentalChartList?.name : JSON.parse(name).name,
        nightCharges: {
          id: data?.data?.rentalChartList?.nightCharges?.id,
          nightChargeAmount: +nightChargeAmount,
          nightChargeEndTime: dayjs(nightChargeEndTime).format("HH:mm"),
          nightChargeStartTime: dayjs(nightChargeStartTime).format("HH:mm")
        },
        rentalCharts: rentalCharts.map((rentalChart: any) => {
          let obj: any = {};
          for (const key in rentalChart) {
            obj[key] = +rentalChart[key];
          }
          return obj;
        })
      },
    };
    
    updateFareById({ id, post: fd })
      .then((res: any) => {
        if (res?.data) {
          notification.success({
            message: "Successfully Updated!",
            placement: "bottomLeft",
          });
          navigate(-1);
        } else {
          notification.error({
            message: res?.error?.data?.message || "Failed to save",
            placement: "bottomLeft",
          });
        }
      });
  };
  return (
    <>
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <PrimaryButton onClick={onClose}>Back</PrimaryButton>
        </Col>
        <Col>
          <PrimaryButton onClick={(e) => {
            if (tab === "1") {
              form.submit();
            } else if (tab === "2") {
              outStationform.submit();
            } else {
              rentalForm.submit();
            }
          }}>update</PrimaryButton>
        </Col>
      </Row>

      <LightBlueContainer>
        <Tabs defaultActiveKey={tab} onTabClick={(e) => setTab(e)}>
          <Tabs.TabPane tab="Local" key="1">
            <WhiteContainer>
              {!isError && !isLoading &&
                <Form
                  form={form}
                  onFinish={onsubmit}
                  layout="vertical"
                  name="dynamic_rule"
                  initialValues={{
                    name: data?.data?.localFareDTO?.name,
                    categoryId: data?.data?.localFareDTO?.categoryId,
                    flatCharges:data?.data?.localFareDTO?.flatCharges,
                    baseFare: data?.data?.localFareDTO?.baseFare.toString(),
                    bookingCharges: data?.data?.localFareDTO?.bookingCharges.toString(),
                    waitingCharges: data?.data?.localFareDTO?.waitingCharges,
                    gst: data?.data?.localFareDTO?.gst.toString(),
                    nightChargeAmount: data?.data?.localFareDTO?.nightCharges?.nightChargeAmount.toString(),
                    nightChargeStartTime: dayjs(data?.data?.localFareDTO?.nightCharges?.nightChargeStartTime, "HH:mm"),
                    nightChargeEndTime: dayjs(data?.data?.localFareDTO?.nightCharges?.nightChargeEndTime, "HH:mm"),
                  }}>
                  <Local fareList={location.state} />
                </Form>
              }
            </WhiteContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Outstation" key="2">
            <WhiteContainer>
              {!isError && !isLoading &&
                <Form
                  form={outStationform}
                  onFinish={outStationSubmit}
                  layout="vertical"
                  name="dynamic_rule"
                  initialValues={{
                    name: data?.data?.outstationFares?.name,
                    categoryId: data?.data?.outstationFares?.categoryId,
                    baseFare: data?.data?.outstationFares?.baseFare.toString(),
                    bookingCharges: data?.data?.outstationFares?.bookingCharges.toString(),
                    waitingCharges: data?.data?.outstationFares?.waitingCharges,
                    gst: data?.data?.outstationFares?.gst.toString(),
                    nightChargeAmount: data?.data?.outstationFares?.nightCharges?.nightChargeAmount.toString(),
                    nightChargeStartTime: dayjs(data?.data?.outstationFares?.nightCharges?.nightChargeStartTime, "HH:mm"),
                    nightChargeEndTime: dayjs(data?.data?.outstationFares?.nightCharges?.nightChargeEndTime, "HH:mm"),
                  }}>
                  <Outstation fareList={location.state} />
                </Form>
              }
            </WhiteContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Rental" key="3">
            <WhiteContainer>

              {!isError && !isLoading &&
                <Form
                  form={rentalForm}
                  onFinish={rentalFareSubmit}
                  layout="vertical"
                  name="dynamic_rule"
                  initialValues={{
                    categoryId: data?.data?.rentalChartList?.categoryId,
                    name: data?.data?.rentalChartList?.name,
                    gst: data?.data?.rentalChartList?.gst?.toString(),
                    nightChargeAmount: data?.data?.rentalChartList?.nightCharges?.nightChargeAmount?.toString(),
                    nightChargeStartTime: dayjs(data?.data?.rentalChartList?.nightCharges?.nightChargeStartTime, "HH:mm"),
                    nightChargeEndTime: dayjs(data?.data?.rentalChartList?.nightCharges?.nightChargeEndTime, "HH:mm"),
                    rentalCharts: data?.data?.rentalChartList?.rentalCharts
                  }}>
                  <Rental fareList={location.state} />
                </Form>}
            </WhiteContainer>
          </Tabs.TabPane>
        </Tabs>
      </LightBlueContainer>
    </>
  );
}


export default EditFare;
