import "../src/App.css";

import AdminRoutes from "./routes/adminRoutes";

function App() {
  return (
    <>
      {process.env.REACT_APP_IS_ADMIN === "true" ? (
        <AdminRoutes />
      ) : (
        "Some thing wrong went."
      )}
    </>
  );
}

export default App;
