import { createSlice } from "@reduxjs/toolkit";

export interface uiState {
  selectedCourse: number | null;
  admin: boolean | null;
  token: string | null;
  vehicleMake: number | null;
  notifications: any;
}

const initialState: uiState = {
  selectedCourse: null,
  admin: null,
  token: null,
  vehicleMake: null,
  notifications: null
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSelectedCourse: (state, { payload }) => {
      state.selectedCourse = payload;
    },
    setUserTypeState: (state, { payload }) => {
      state.admin = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setVehicleMake: (state, { payload }) => {
      state.vehicleMake = payload;
    },
    setNotifications: (state, { payload }) => {
      state.notifications = payload;
    }
  },
});

export const { setSelectedCourse, setUserTypeState, setToken, setVehicleMake, setNotifications } =
  uiSlice.actions;

export default uiSlice.reducer;
