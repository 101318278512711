import { Form, Select } from "antd";
import { useGetAllVehicleMakeQuery } from "../../feature/VehicleList/vehicleListApiSlice";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "rc-field-form/lib/interface";
import useFormInstance from "antd/es/form/hooks/useFormInstance";


type ISectionOptionsProps = {
  label: string;
  name: [number, string] | string;
  rules?: Rule[];
  form: FormInstance;
};

const { Option } = Select;

const SelectVehicleMake = (props: ISectionOptionsProps) => {
  const { data } = useGetAllVehicleMakeQuery();
  const form = useFormInstance();

  const options: any[] | undefined=[];
  
  data?.data.map((option: any) => {
    options.push({
      label: option.vehicleMake,
      value: option.id
    })
  });

  const filterOption = (input: string, option:any) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Form.Item {...props}>
      <Select onSelect={(e) => {
        const a = form.getFieldsValue().vehicles;
        a.splice(props.name[0], 1, {
          id: e,
          vehicleMake: data?.data.find((option: any) => option.id === e && option).vehicleMake,
          vehicleModel: ""
        });
        form.setFieldValue("vehicles", a);
      }}
      showSearch
      options={options}
      filterOption={filterOption}
      />
        {/* {data?.data.map((option: any) => (
          <Option key={option.id} value={option.id}>
            {option.vehicleMake}
          </Option>
        ))}
      </Select> */}
    </Form.Item>
  );
};

export default SelectVehicleMake;
