import { useLocation } from "react-router-dom";
import styled from "styled-components";

const BreadcrumbContainer = styled.div`
  padding: 1rem 1rem;
`;

function Breadcrumbs() {
  const { pathname } = useLocation();

  const parts = pathname.split("/").slice(1);

  const filteredParts = parts.filter((part) => isNaN(Number(part)));

  const breadcrumbText = filteredParts.join(" > ");

  return <BreadcrumbContainer>{breadcrumbText}</BreadcrumbContainer>;
}

export default Breadcrumbs;
