import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col, Tabs, notification } from "antd";

import { LightBlueContainer } from "../../styled/Container.styled";
import { PrimaryButton } from "../../styled/Button.styled";

import OffersForm from "./OffersDetailsForm";
import OffersTerms from "./offersTerms";
import dayjs from "dayjs";
import { useState } from "react";
import {
  useGetOfferByIdQuery,
  useUpdateOfferByIdMutation,
} from "../../../feature/Offer/offerApiSlice";

const EditOffers = () => {
  let { id } = useParams();
  const [imgFile, setImgFile] = useState("");
  const navigate = useNavigate();
  const { data, isError, isLoading } = useGetOfferByIdQuery(id);
  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };
  const [updateOfferById] = useUpdateOfferByIdMutation();
  const onClose = () => {
    navigate(-1);
  };
  const tabItems = [
    {
      label: "Details",
      key: "1",
      children: <OffersForm data={data} setImgFile={setImgFile} />,
    },
    {
      label: "Terms And Condition",
      key: "2",
      children: <OffersTerms data={data} />,
    },
  ];
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { offerDetailsForm, offerTermsForm } = forms;
            offerDetailsForm.submit();
            offerTermsForm.submit();

            let offerData = { ...offerDetailsForm.getFieldsValue() };
            let terms = { ...offerTermsForm.getFieldsValue() };

            let obj = {
              ...offerData,
              liveDate: dayjs(offerData.liveDate).toISOString(),
              expireDate: dayjs(offerData.expireDate).toISOString(),
              image: imgFile,
              offerStatus: "ACTIVE",
              status: true,
              termsAndConditions: Object.values((terms))[0],
            };

            updateOfferById({ id: data?.data.id, post: obj })
              .unwrap()
              .then((res: any) => {
                notification.success({
                  message: "Successfully Added!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col style={{ display: "flex" }}>
            {
              activeTab === "1" ? <PrimaryButton onClick={() => setActiveTab("2")} >Next</PrimaryButton> :
                <Form name="submitForm" style={{ marginLeft: "10px" }}>
                  <PrimaryButton htmlType="submit">Save</PrimaryButton>
                </Form>

            }
          </Col>
        </Row>
        <LightBlueContainer>
          {!isError && !isLoading && data && (
            <Tabs defaultActiveKey={activeTab} activeKey={activeTab} items={tabItems} onChange={handleTabChange} />
          )}
        </LightBlueContainer>
      </Form.Provider>
    </>
  );
};

export default EditOffers;
