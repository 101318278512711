import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col, notification, Tabs } from "antd";

import { PrimaryButton } from "../../styled/Button.styled";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import CategoryForm from "./CategoryForm";
import {
  useGetCategoryByIdQuery,
  useUpdateCategoryByIdMutation,
} from "../../../feature/Category/categoryApiSlice";
import BasicDetails from "./BasicDetails";
import { useState } from "react";

const EditCategory = () => {
  let { id } = useParams();
  const { data, isLoading, isError } = useGetCategoryByIdQuery(id);
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  const [updateCategoryById] = useUpdateCategoryByIdMutation();
  const onClose = () => {
    navigate(-1);
  };
  const onChange = (activeKey: string) => setActiveKey(activeKey);

  const tabItems = [
    {
      label: "Basic Details",
      key: "1",
      children: (
       
        <WhiteContainer>
          
          {data && !isLoading && (
            <BasicDetails setActiveKey={setActiveKey} data={data} />
          )}
        </WhiteContainer>
      ),
    },

    {
      label: "Category Vehicle",
      key: "2",
      activeKey: activeKey,
      children: (
        <WhiteContainer>
          {data && !isLoading && <CategoryForm data={data} />}
        </WhiteContainer>
      ),
    },
    
  ];

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { CategoryDetailsForm, categoryVehiclesForm } = forms;
            categoryVehiclesForm.validateFields().then(() => {
              const categoryData = { ...CategoryDetailsForm.getFieldsValue() };
              const categoryVehicleData = categoryVehiclesForm.getFieldsValue();

              if (categoryVehicleData.vehicles.length < 1) {
                notification.info({
                  message: "Alert",
                  description: "Please add vehicles",
                  placement: "bottomLeft",
                });
                return;
              }
              const categoryServices = categoryData?.taxiServiceType?.map(
                (item: any) => ({
                  taxiType: item,
                  isActive: true,
                  isDeleted: false,
                })
              );

              const obj = {
                id: data?.data?.id,
                ...categoryData,
                taxiServiceType: categoryServices,
                ...categoryVehicleData,
              };
              updateCategoryById({ id: data?.data.id, post: obj })
                .unwrap()
                .then((res: any) => {
                  if (res?.statusCode === 500) {
                    notification.error({
                      message: res?.message,
                      placement: "bottomLeft",
                    });
                  } else {
                    notification.success({
                      message: "Successfully updated",
                      placement: "bottomLeft",
                    });
                    navigate(-1);
                  }
                });
            });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <PrimaryButton onClick={onClose}>Back</PrimaryButton>
          </Col>
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" disabled={activeKey !== "2"}>
                Save
              </PrimaryButton>
            </Form>
          </Col>
        </Row>
       
        {!isError && !isLoading && data && (
          
          <LightBlueContainer>
             
            <Tabs items={tabItems} activeKey={activeKey}  onChange={onChange} />
          </LightBlueContainer>
        )}
      </Form.Provider>
    </>
  );
};

export default EditCategory;
