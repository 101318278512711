import { Col, DatePicker, Form, Input, Row } from "antd";
import { useParams } from "react-router-dom";
import { useLazyGetBookingHistoryQuery } from "../../../../feature/Driver/driverApiSlice";
import dayjs from 'dayjs';
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";

function BookingHistory() {

  const [getBookingHistory] = useLazyGetBookingHistoryQuery();
  const { id } = useParams();
  const [form] = useForm();

  useEffect(() => {
    const currentDate = dayjs();
    const firstDayOfMonth = currentDate.startOf('month').format("YYYY-MM-DD");
    const lastDayOfMonth = currentDate.endOf('month').format("YYYY-MM-DD");
    getBookingHistory({ id, endDate: lastDayOfMonth, startDate: firstDayOfMonth }).then((bookingHistory: any) => {
      form.setFieldsValue({
        totalTrip: bookingHistory.data.data.totalTrip,
        totalCancelBooking: bookingHistory.data.data.totalCancelBooking
      });
    });
  }, [getBookingHistory, form, id]);

  const { RangePicker } = DatePicker;
  return (
    <div>
      <Row gutter={[32, 22]}>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <label>Select Period</label>
              <RangePicker
                className="w-100"
                format={"DD-MM-YYYY"}
                defaultValue={[dayjs().startOf("month"), dayjs().endOf("month")]}
                onChange={(e) =>
                  getBookingHistory({ id, endDate: dayjs(e?.[1]).format("YYYY-MM-DD"), startDate: dayjs(e?.[0]).format("YYYY-MM-DD") }).then((bookingHistory: any) => {
                    form.setFieldsValue({
                      totalTrip: bookingHistory.data.data.totalTrip,
                      totalCancelBooking: bookingHistory.data.data.totalCancelBooking
                    });
                  })}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Form
            form={form}>
            <Row>
              <Col span={24}>
                <label>Total Bookings (Monthly)</label>
                <Form.Item
                  name="totalTrip"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Total Bookings Cancellation (Monthly)</label>
                <Form.Item
                  name="totalCancelBooking"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default BookingHistory;
