import { mainApi } from "../../app/services/mainApiSlice";

import { IOffer, IOfferByIdResponse, IOfferResponse,IOfferCountResponse } from "../../types/offers";


const commonUrl: string = "/admin/offer";
const offerApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        // Get All Offer
        getAllOffers: builder.query<IOfferResponse, void>({
            query: () => ({
                url: `${commonUrl}/get-all-offer?page=0&size=1000`,
                method: "GET",
               
            }),
            providesTags: [{ type: "Main", id: "OFFERS" }],
        }),


        getOfferById: builder.query<IOfferByIdResponse, string | undefined>({
            query: (id) => ({
                url: `${commonUrl}/get-offer/${id}`,
                method: "GET",
                
            }),
            providesTags: [{ type: "Main", id: "OFFERS" }],
        }),
        updateOfferStatusById: builder.mutation<
            IOffer,
            { id: number | undefined }
        >({
            query: (id) => ({
                url: `${commonUrl}/update-offer-status/${id}`,
                method: "PUT",
                
            }),
            invalidatesTags: [{ type: "Main", id: "OFFERS" }],
        }),

        addOffer: builder.mutation<IOffer, Partial<IOffer>>({
            query: (offerData: IOffer) => ({
                url: `${commonUrl}/save`,
                method: "POST",
                
                body: offerData,
            }),
            invalidatesTags: [{ type: "Main", id: "OFFERS" }],
        }),
        updateOfferById: builder.mutation<
            IOffer,
            { id: number | undefined; post: Partial<IOffer> }
        >({
            query: ({ id, post }) => ({
                url: `${commonUrl}/update-offer/${id}`,
                method: "PUT",
                
                body: post,
            }),
            invalidatesTags: [{ type: "Main", id: "OFFERS" }],
        }),
        
        getOfferCount: builder.query<IOfferCountResponse, void>({
            query: () => ({
              url: `${commonUrl}/count`,
              method: "GET",
              
            }),
            providesTags: [{ type: "Main", id: "OFFERS" }],
          }),
        deleteOfferById: builder.mutation<void, number | undefined>({
            query: (id) => ({
                url: `${commonUrl}/delete/${id}`,
                method: "DELETE",
                
            }),
            invalidatesTags: [{ type: "Main", id: "OFFERS" }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAllOffersQuery,
    useUpdateOfferStatusByIdMutation,
    useDeleteOfferByIdMutation,
    useAddOfferMutation,
    useUpdateOfferByIdMutation,
    useGetOfferByIdQuery,
useGetOfferCountQuery
} = offerApi;
