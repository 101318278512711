import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import uiReducer from "../feature/ui/uiSlice";

import { mainApi, mainApiLogin, mainApiLogout } from "./services/mainApiSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

export const store = configureStore({
  reducer: {
    ui: uiReducer,

    [mainApi.reducerPath]: mainApi.reducer,
    [mainApiLogin.reducerPath]: mainApiLogin.reducer,
    [mainApiLogout.reducerPath]: mainApiLogout.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    mainApi.middleware,
    mainApiLogin.middleware,
    mainApiLogout.middleware
  ),
});

setupListeners(store.dispatch);
export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
