import { mainApi } from "../../app/services/mainApiSlice";

type IForgotResponse = {
  message: string;
  errorMessage: string;
  statusCode: number;
  data: boolean;
};
const forgotPasswordApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    sendForgotMail: builder.mutation<IForgotResponse, { toAddresses: string }>({
      query: ({ toAddresses }) => ({
        url: `public/sent-mailForResetPass?toAddresses=${encodeURIComponent(toAddresses)}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Main", id: "FORGOTPASSWORD" }],
    }),
    resetPassword: builder.mutation<
      IForgotResponse,
      { confirmPassword: string; token: string }
    >({
      query: ({ confirmPassword, token }) => ({
        url: `public/password?token=${token}`,
        method: "PATCH",
        body:{password:confirmPassword}
      }),
      invalidatesTags: [{ type: "Main", id: "FORGOTPASSWORD" }],
    }),
  }),
});

export const { useSendForgotMailMutation, useResetPasswordMutation } =
  forgotPasswordApi;
