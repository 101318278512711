import { Col, Form, Input, Row, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import { LightBlueContainer, WhiteContainer } from "../../styled/Container.styled";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import TextArea from "antd/es/input/TextArea";
import dayjs from 'dayjs';
import { useGetTransactionByIdQuery } from "../../../feature/Transactions/transactionApiSlice";
import { DownloadOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../styled/Button.styled";
const ViewTransction = () => {

 const navigate = useNavigate();
 const [form] = useForm();
 const location: any = useLocation();
 const { data, isLoading } = useGetTransactionByIdQuery(location.state.transaction.tripId);

 useEffect(() => {
  form.setFieldsValue({
   transactionId: data?.data.paymentId,
   driverName: data?.data.driverName,
   gst: data?.data.gst,
   dateOfTransaction: dayjs(data?.data.dateOfTransaction).format("MM/DD/YYYY"),
   vehicleNumber: data?.data.vehicleNumber,
   transactionAmount: data?.data.transactionAmount,
   fromAddress: data?.data.fromAddress,
   coupon: data?.data.coupon,
   paymentMode: data?.data.paymentMode
  });
 }, [form, data]);

 return (
  <>
    <Row justify="end" gutter={20} className="button-bottom-margin">
      <Col>
        <Form name="submitForm" onClick={() => navigate(-1)}>
            <PrimaryButton>Back</PrimaryButton>
        </Form>
      </Col>
    </Row>
   <LightBlueContainer>
    <Col style={{ padding: "0.5% 0", marginLeft: "auto", fontWeight: 500, fontSize: "1.2rem" }}>
     <a target="_blank" rel="noreferrer" href={data?.data.invoice}><DownloadOutlined style={{ fontSize: "1.75rem" }} /> Download Transaction Receipt</a>
    </Col>
    <WhiteContainer>
     {
      !isLoading ?
       <Form form={form} name="dynamic_rule"
        layout="vertical">
        <Row gutter={10}>
         <Col md={12}>
          <Form.Item label="Payment ID" name="transactionId">
           <Input disabled={true} />
          </Form.Item>
         </Col>
         <Col md={12}>
          <Form.Item label="Driver" name="driverName">
           <Input disabled={true} />
          </Form.Item>
         </Col>
         <Col md={12}>
          <Form.Item label="Transaction Date" name="dateOfTransaction">
           <Input disabled={true} />
          </Form.Item>
         </Col>
         <Col md={12}>
          <Form.Item label="Vehicle Number" name="vehicleNumber">
           <Input disabled={true} />
          </Form.Item>
         </Col>
         <Col md={12}>
          <Form.Item label="Transaction Amount" name="transactionAmount">
           <Input disabled={true} />
          </Form.Item>
         </Col>
         <Col md={12}>
          <Form.Item label="From Address" name="fromAddress">
           <TextArea disabled={true} />
          </Form.Item>
         </Col>
         <Col md={12}>
          <Form.Item label="To Address" name="toAddress">
           <TextArea disabled={true} />
          </Form.Item>
         </Col>
         <Col md={12}>
          <Form.Item label="Coupon Applied" name="coupon">
           <Input disabled={true} />
          </Form.Item>
         </Col>
         <Col md={12}>
          <Form.Item label="Payment Mode" name="paymentMode">
           <Input disabled={true} />
          </Form.Item>
         </Col>

         <Col md={12}>
          <Form.Item label="GST" name="gst">
           <Input disabled={true} />
          </Form.Item>
         </Col>

        </Row>
       </Form> :
       <Spin size="large" />
     }
    </WhiteContainer>
   </LightBlueContainer>

  </>
 );
};

export default ViewTransction;