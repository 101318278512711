import {useEffect, useState } from "react";

import { Input, Modal, notification, Row, Table, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { ColumnsType } from "antd/es/table";
import Card from "../../countCard/Card";
import pageSize from "../../../constants/pageSize";

import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { DeleteButton, PrimaryButton, ViewDetailsButton } from "../../styled/Button.styled";
import { useDeleteFareByIdMutation, useGetAllFareQuery } from "../../../feature/Fare/fareApiSlice";
import { IFare } from "../../../types/fare";
import styled from "styled-components";


const ViewChart = styled.div`
  color: dodgerblue;
`;

const Fare = () => {
  const navigate = useNavigate();
  const { data, isLoading,refetch} = useGetAllFareQuery();
  const [deleteFareById] = useDeleteFareByIdMutation();
  const [nameValue, setNameValue] = useState("");
  const [localFare, setLocalFare] = useState("");
  const [outstationFare, setOutstationFare] = useState("");
  const { confirm } = Modal;
  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Fare?",
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const onDelete = (id: number) => {
    deleteFareById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      });
  };

  const columns: ColumnsType<any> = [
    {
      key: "name",
      width: "22%",
      title: (
        <div>
          <Typography.Text> Category</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (value: any, record) => {
        return record?.localFareDTO?.name || record?.outstationFares?.name || record?.rentalChartList?.name;
      },
      dataIndex: "name",
    },
    {
      key: "baseFare",
      width: "22%",
      title: (
        <div>
          <Typography.Text>Local Fare/Per Km</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setLocalFare(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (value: any, record) => {
        return record?.localFareDTO?.baseFare;

      },
      dataIndex: "baseFare",
    },
    {
      key: "outstationFare",
      width: "25%",
      title: (
        <div>
          <Typography.Text>Outstation Fare/Per Km</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setOutstationFare(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      render: (value: any, record) => {

        return record?.outstationFares?.baseFare;

      },
      dataIndex: "outstationFares",
    },

    {
      key: "",
      title: (
        <div>
          <Typography.Text>Rental</Typography.Text>
        </div>
      ),
      render: (_, record) => (
        !!record.rentalChartList &&
        <ViewChart
          onClick={() => openChart(record.id)}
          style={{ cursor: "pointer" }}
        >
          View Chart
        </ViewChart>
      ),
    },

    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openBookingDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
    {
      key: "deleteButton",
      width: "2%",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];
  const openChart = (id: number) => {
    navigate(`edit/${id}`, { state: { tab: "3" } });
  };

  const openBookingDetails = (id: number) => {
    navigate(`edit/${id}`, {
      state: {
        fares: data?.data?.content
      }
    });
  };

  const sum = data?.data.content.length;

  useEffect(() => {
    refetch(); 
  }, [refetch]);

  return (
    <>
      <Row justify="space-between" className="button-bottom-margin">
        <Card sum={sum} text=" Fare" />
        <Link to="add" state={{ fares: data?.data?.content }}>
          <PrimaryButton> Add Fare </PrimaryButton>
        </Link>
      </Row>
      <LightBlueContainer>
        <WhiteContainer>
          <Table<IFare>
            loading={isLoading}
            columns={columns}
            dataSource={data?.data.content}
            rowKey="id"
            pagination={{
              pageSize,
            }}
          />
       </WhiteContainer>
      </LightBlueContainer>
    </>
  );
};
export default Fare;