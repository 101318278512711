import { Row, Col, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { Form } from "antd";

import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { PrimaryButton } from "../../styled/Button.styled";
import BannerDetailsForm from "./BannerDetailsForm";
import { useGetBannerByIdQuery, useUpdateBannerByIdMutation } from "../../../feature/Banner/BannerApiSlice";
import { useState } from "react";


import dayjs from "dayjs";

export default function BannerDetails() {
  let { id } = useParams();

  const { data, isLoading, isError } = useGetBannerByIdQuery(id);
  const [updateBannerById] = useUpdateBannerByIdMutation();
  const navigate = useNavigate();

  const [bannerImg, setBannerImg] = useState("");
  const onClose = () => {
    navigate(-1);
  };
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { BannerDetailsForm } = forms;
            BannerDetailsForm.submit();
            let bannerData = { ...BannerDetailsForm.getFieldsValue() };

            const date = dayjs(bannerData.liveDate).format("DD/MM/YYYY");
            const time = dayjs(bannerData.liveTime).format("HH:mm");
            const formData = new FormData();
            formData.append("image", bannerImg);
            updateBannerById({ id: data?.data.id, formData, date, time })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully Edited!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <PrimaryButton onClick={onClose}>Back</PrimaryButton>
          </Col>
          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit">Save</PrimaryButton>
            </Form>
          </Col>
        </Row>
        <LightBlueContainer>
          <WhiteContainer>
            {!isError && !isLoading && data && (
              <BannerDetailsForm data={data} setBannerImg={setBannerImg} />
            )}
          </WhiteContainer>
        </LightBlueContainer>
      </Form.Provider>
    </>
  );
}
