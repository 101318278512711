import SubAdminRoutes from "./admin/subAdminRoutes";

const AdminRoutes = () => {
  return (
    <>
      <SubAdminRoutes />
    </>
  );
};

export default AdminRoutes;
