const options: Intl.DateTimeFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const date: string = new Date().toLocaleDateString("en-US", options);

export default date;
