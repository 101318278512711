import { Alert, Card, Col, Input, notification, Row, Form, Button, Typography } from "antd";
import { useState } from "react";
import { useResetPasswordMutation } from "../../../feature/forgotPassword/forgotPasswordApiSlice";
import { logo, textLogo } from "../../../constants/logos";
import { useLocation,useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PrimaryButton } from "../../styled/Button.styled";

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

const cardBodyStyle = {
    width: "100%",
};

function CreateNewPassword() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(true);
    const [resetPassword] = useResetPasswordMutation();
    const location = useLocation();
    const token = location.pathname.split("/")[2];

    const handleFormSubmit = async (val: { confirmPassword: string; newPassword: string; token: string }) => {
        if (val.newPassword === val.confirmPassword) {
            resetPassword({ confirmPassword: val.confirmPassword, token: token })
                .unwrap()
                .then(() => {
                    setShowForm(false);
                    setTimeout(() => {
                        navigate("/login");
                    }, 3000);
                });
        } else {
            notification.error({
                message: "Passwords don’t match.",
                placement: "bottomLeft",
            });
        }
    };

    return (
        <LoginContainer className="bgImage">
            <Row justify={"space-between"} align={"middle"} className="login-container-inner-row">
                <Col className="logo brand-logo-position" span={6}>
                    <Row gutter={[0, 12]}>
                        <Col span={24}>
                            <Row justify={"center"}>
                                <img src={logo} alt="brandLogo" className="brand-logo-size" />
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify={"center"}>
                                <img src={textLogo} alt="textLogo" />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={11}>
                    {showForm ? (
                        <Card className="reset-password-screen-card" bordered={false} bodyStyle={{ textAlign: "center", padding: "1rem" }}>
                            <h4 className="welcome-text"> Create Password</h4>
                            <Form
                                form={form}
                                initialValues={{ remember: true }}
                                layout="vertical"
                                onFinish={(values) => handleFormSubmit(values)}
                                style={{ marginTop: "15px" }}
                            >
                                <Form.Item
                                    label="New Password"
                                    name="newPassword"
                                    rules={[
                                        { required: true, message: "Please enter password" },
                                        { pattern: /^(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,32}$/, message: 'Minimum 8 characters, one uppercase letter and one special character' },
                                    ]}
                                    className="p-1"
                                >
                                    <Input.Password placeholder="Password" style={{ height: "40px" }}/>
                                </Form.Item>
                                <Form.Item
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    style={{ marginBottom: "35px" }}
                                    rules={[
                                        { required: true, message: "Please confirm password" },
                                        { pattern: /^(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,32}$/, message: 'Minimum 8 characters, one uppercase letter and one special character' },
                                    ]}
                                    className="p-1"
                                >
                                    <Input.Password placeholder="Confirm Password" style={{ height: "40px" }}/>
                                </Form.Item>
                                <Row justify={"center"} className="mt-2">
                                    <Form.Item>
                                        <Button type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                            style={{ backgroundColor: "#3e4685", width: "100%",height: "3rem", maxWidth: "200px", fontSize: "20px" }}
                                            size="large">
                                            Create Password
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </Card>
                    ) : (
                        <Card className="reset-password-screen-card" bordered={false} bodyStyle={{ textAlign: "center", padding: "1rem" }}>
                            <Typography.Title level={3} className="mt-0">
                                Password updated successfully
                            </Typography.Title>
                            <Typography.Text>
                                You will be redirected to login screen.
                            </Typography.Text>
                        </Card>
                    )}
                </Col>
            </Row>
        </LoginContainer>
    );
}

export default CreateNewPassword;
