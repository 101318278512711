import { Col, Row } from "antd";
import styled from "styled-components";
import { LightBlueContainer } from "../styled/Container.styled";

const CardContainer = styled(LightBlueContainer)`
  width: 250px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p:first-child {
    font-size: 19px;
  }
`;

type IProps = {
  text: string;
  sum: number | undefined;
  activeText: string;
  active: number | undefined;
  inActiveText: string;
  inActive: number | undefined;
};

function CustomCard({
  text,
  sum,
  activeText,
  active,
  inActiveText,
  inActive,
}: IProps) {
  return (
    <CardContainer>
      <Row>
        <Col span={12}>
          <p>{text}</p>

        </Col>
        <Col span={12}>
          <span className="text-color">{sum}</span>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Row gutter={[4, 0]}>
            <Col>
              <div>{active} </div>
            </Col>
            <Col>
              <div>{activeText}</div>
            </Col>
          </Row> 
        </Col>
        <Col span={12}>
          <Row gutter={[4, 0]}>
            <Col>
              <div>{inActive}</div>
            </Col>
            <Col>
              <div>{inActiveText}</div>
            </Col>
          </Row> 
        </Col>


      </Row>
    </CardContainer>
  );
}

export default CustomCard;
