import {
  Input,
  Form,
  Row,
  Col,
  notification,
} from "antd";
import {   useGetSupportDetailsQuery, useUpdateSupportDetailsByIdMutation } from "../../../feature/Profile/ProfileApiSlice";
import { useEffect } from "react";

function SupportDetailsForm() {
  const [updateSupportDetailsById] = useUpdateSupportDetailsByIdMutation();
  // const [getSupportDetails] = useGetSupportDetailsQuery()
  const [form] = Form.useForm();
  // useGetSupportDetailsQuery()
  const { setFieldsValue } = form;

  const { data, isLoading, isError } =
  useGetSupportDetailsQuery();
  useEffect(()=>{
 

      //     .unwrap()
    //     .then(() => {
    //        alert("response")
    //     });
    // getSupportDetails()
    //     .unwrap()
    //     .then(() => {
    //        alert("response")
    //     });
  },[])

  const validateEmail = (_: any, value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value && !emailRegex.test(value)) {
      return Promise.reject('Please enter a valid email');
    }
    return Promise.resolve();
  };

  const validateNumber = (_: any, value: string) => {
    const numberRegex = /^[0-9]+$/;
    if (value && !numberRegex.test(value)) {
      return Promise.reject('Please enter only numbers');
    }
    return Promise.resolve();
  };

const formSubmit=(values:any)=>{
    const { supportNumber, supportEmail } = values;
    const obj={
        phoneNumber: supportNumber,
        emailId: supportEmail
    }
    updateSupportDetailsById({ formData: obj })
        .unwrap()
        .then(() => {
            notification.success({
                message: "Added Successfully!",
                placement: "bottomLeft",
            });
        });
}


useEffect(()=>{
 if(data?.data?.phoneNumber){
  console.log("data?.data?.phoneNumber",data?.data?.phoneNumber)
  const formData = {
    supportNumber:data?.data?.phoneNumber,
    supportEmail:data?.data?.emailId,
    // Add more fields as needed
  };

  // Set data in the form fields
  setFieldsValue(formData);
 }
},[data])
  return (
    <>
      <Form
  
        name="supportDetailForm"
        form={form}
        onFinish={formSubmit}
      >
        <Row gutter={[32, 22]}>
        <Col span={12}>
            <label>Support Number</label>
            <Form.Item
            
                name="supportNumber"
                rules={[
                { required: true, message: 'Please enter the support number' },
                { validator: validateNumber },
                ]}
            >
                <Input value={data?.data?.phoneNumber} />
            </Form.Item>

            <label>Support Email</label>
            <Form.Item
                name="supportEmail"
                rules={[
                { required: true, message: 'Please enter the support email' },
                { validator: validateEmail },
                ]}
            >
                <Input type="email" value={data?.data?.emailId} />
            </Form.Item>
            </Col>
        </Row>
      </Form>
    </>
  );
}

export default SupportDetailsForm;
