import { mainApi} from "../../app/services/mainApiSlice";
import { IUserByIdResponse, IUserResponse } from "../../types/user";
const commonUrl: string = "/admin/user";

const userApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({

    // Get All rider
    getAllRider: builder.query<IUserResponse, void>({
      query: () => ({
        url: `${commonUrl}/get-all-rider?page=0&size=1000`,
        method: "GET",
     
      }),
      providesTags: [{ type: "Main", id: "USER" }],
    }),

    getRiderById: builder.query<IUserByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/rider/${id}`,
        method: "GET",
        
      }),
      providesTags: [{ type: "Main", id: "USER" }],
    }),
 
    deleteRiderById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/delete-rider/${id}`,
        method: "DELETE",
        
      }),
      invalidatesTags: [{ type: "Main", id: "USER" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllRiderQuery,
  useGetRiderByIdQuery,
  useDeleteRiderByIdMutation,
} = userApi;