import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

export const useAuthStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);

  useEffect(() => {
    const token = secureLocalStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    setCheckingStatus(false);
  }, [loggedIn]);

  return { loggedIn, checkingStatus };
};
