import { Col, Row, Tabs, Form, notification } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteDriverByIdMutation,
  useGetDriverByIdQuery,
  useUpdateDriverByIdMutation,
  useUpdateDriverStatusByIdMutation,
} from "../../../feature/Driver/driverApiSlice";
import { PrimaryButton } from "../../styled/Button.styled";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import BankDetails from "./addDriver/BankDetails";
import DocumentDetails from "./addDriver/DocumentDetails";
import PersonalDetails from "./addDriver/PersonalDetails";
import VehicleDetails from "./addDriver/VehicleDetails";
import WalletDetails from "./addDriver/WalletDetails";


import dayjs from "dayjs";
import appendData from "../../../utils/appendData";
import BookingHistory from "./addDriver/BookingHistory";
import styled from "styled-components"

const CustomTabs = styled(Tabs)`
.ant-tabs-tab {
  &:hover {
    pointer-events: none; 
  }
}
.ant-tabs-tab-disabled {
  pointer-events: none;
}
`;
function DriverDetails() {
  const { id } = useParams();
  const { data, isError, isLoading } = useGetDriverByIdQuery(id);
  const [updateDriverById] = useUpdateDriverByIdMutation();
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState("");
  const [rcImage, setRCImage] = useState("");
  const [touristPermitFrontImage, setTouristPermitFrontImage] = useState("");
  const [touristPermitBackImage, setTouristPermitBackImage] = useState("");
  const [aadharFrontImage, setAadharFrontImage] = useState("");
  const [aadharBackImage, setAadharBackImage] = useState("");
  const [drivingLicenceFrontImage, setDrivingLicenceFrontImage] = useState("");
  const [drivingLicenceBackImage, setDrivingLicenceBackImage] = useState("");
  const [panFrontImage, setPanFrontImage] = useState("");
  const [statusUpdate, setStatusUpdate] = useState<boolean>();
  const [panBackImage, setPanBackImage] = useState("");
  const [deleteDriverById] = useDeleteDriverByIdMutation();
  const [updateDriverStatusById] = useUpdateDriverStatusByIdMutation();

  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };
  const handleNextTab = () => {
    const tabs = ["tab1", "tab2", "tab3", "tab4", "tab5","tab6"];
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = currentIndex === tabs.length - 1 ? 0 : currentIndex + 1;
    const nextTab = tabs[nextIndex];
    setActiveTab(nextTab);
  };
  const handlePrevTab = () => {
    const tabs = ["tab1", "tab2", "tab3", "tab4", "tab5","tab6"];
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = currentIndex === tabs.length - 1 ? 0 : currentIndex - 1;
    const nextTab = tabs[nextIndex];
    setActiveTab(nextTab);
  };


  const declineHandler = (id: any) => {
    deleteDriverById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Driver successfully un-verified",
          placement: "bottomLeft",
        });
      })
      .catch((error: any) => {
        notification.error({
          message: "Error",
          description: error.data.message,
          placement: "bottomLeft",
        });
      });
  };

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const {
              personalDetailForm,
              vehicleDetailForm,
              documentDetailForm,
              bankDetailForm,
            } = forms;

            personalDetailForm?.submit();
            vehicleDetailForm?.submit();
            documentDetailForm?.submit();
            bankDetailForm?.submit();

            let driverData = { ...personalDetailForm.getFieldsValue() };
            let vehicleData = vehicleDetailForm ? { ...vehicleDetailForm.getFieldsValue() } : data?.data.vehicleDto;
            let documentData = documentDetailForm ? { ...documentDetailForm.getFieldsValue() } : {
                  aadhaarNumber: data?.data.documentList[0]?.cardNumber,
                  drivingNumber: data?.data.documentList[2]?.cardNumber,
                  panNumber: data?.data.documentList[1]?.cardNumber,
            };
            let bankData = bankDetailForm ? { ...bankDetailForm.getFieldsValue() } : data?.data.bankDetails;
            let accountId = bankDetailForm ? bankDetailForm.getFieldsValue().accountId : data?.data.accountId;
            let obj = {
              ...driverData,
              image: imageFile,
              dob: dayjs(driverData.dob).format("DD/MM/YYYY"),
              isActive: data?.data.isActive,
              isVerified: data?.data.isVerified,
              vehicleDto: vehicleDetailForm ? {
                ...vehicleData,
                vehicleMake: [
                  {
                    id: vehicleData.vehicleMake
                  }
                ],
                vehicleYear: dayjs(vehicleData.vehicleYear).format("YYYY"),
                touristPermitDTO: {
                  touristPermitNumber: vehicleData.touristPermitNumber ? vehicleData.touristPermitNumber : ""
                },
                rcImage: {
                  rcNumber: vehicleData.rcNumber,
                  rcFrontImage: rcImage
                }
              } : {
                ...vehicleData
              },
              bankDetails: bankData,
              accountId: accountId,
              ...documentData,
              aadhaarFrontImage: documentDetailForm ? aadharFrontImage : data?.data?.documentList[0]?.cardFrontImage,
              aadhaarBackImage: documentDetailForm ? aadharBackImage : data?.data?.documentList[0]?.cardBackImage,
              drivingFrontImage: documentDetailForm ? drivingLicenceFrontImage : data?.data?.documentList[2]?.cardFrontImage,
              drivingBackImage: documentDetailForm ? drivingLicenceBackImage : data?.data?.documentList[2]?.cardBackImage,
              panImage: documentDetailForm ? panFrontImage : data?.data?.documentList[1]?.cardFrontImage,
            };
            updateDriverById({ id: data?.data.id, formData: obj })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully Added!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              })
              .catch((error: any) =>
                notification.error({
                  message: "Error!",
                  description: error.data.message,
                  placement: "bottomLeft",
                })
              );
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            {activeTab !== "tab1" ? (
              <PrimaryButton onClick={handlePrevTab}>Back</PrimaryButton>
            ) : (
              ""
            )}
          </Col>
          <Col>
            {activeTab !== "tab6" ? (
              <PrimaryButton onClick={handleNextTab}>Next</PrimaryButton>
            ) : (
              ""
            )}
          </Col>
          <Col>
            <Form name="submitForm">
              {data?.data.isVerified === true ?
                (
                  <PrimaryButton htmlType="submit">Update</PrimaryButton>
                ) : activeTab === "tab4" && (
                  <>
                    <PrimaryButton style={{ background: "red" }} onClick={() => declineHandler(data?.data.id)}>Decline</PrimaryButton>
                    <PrimaryButton style={{ marginLeft: "10px", background: "green" }} onClick={() => { if (data?.data.id) { updateDriverStatusById(data?.data.id).then((res: any) => { if (res) { navigate(-1); } }); } }}>Approve</PrimaryButton>
                  </>
                )}
            </Form>
          </Col>
        </Row>

        <LightBlueContainer>
          <CustomTabs activeKey={activeTab} onChange={handleTabChange}>
            <Tabs.TabPane tab="Personal" key="tab1" disabled={true}>
              <WhiteContainer>
                {!isError && !isLoading && data && (
                  <PersonalDetails data={data} setImageFile={setImageFile} />
                )}
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Vehicle" key="tab2" disabled={true}>
              <WhiteContainer>
                <VehicleDetails
                  data={data}
                  setRCImage={setRCImage}
                  setTouristPermitFrontImage={setTouristPermitFrontImage}
                  setTouristPermitBackImage={setTouristPermitBackImage}
                />
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Document" key="tab3" disabled={true}>
              <WhiteContainer>
                <DocumentDetails
                  data={data}
                  setAadharFrontImage={setAadharFrontImage}
                  setAadharBackImage={setAadharBackImage}
                  setDrivingLicenceFrontImage={setDrivingLicenceFrontImage}
                  setDrivingLicenceBackImage={setDrivingLicenceBackImage}
                  setPanFrontImage={setPanFrontImage}
                  setPanBackImage={setPanBackImage}
                />
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bank" key="tab4" disabled={true}>
              <WhiteContainer>
                <BankDetails data={data} />
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Booking History" key="tab5" disabled={true}>
              <WhiteContainer>
                <BookingHistory />
              </WhiteContainer>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Wallet" key="tab6" disabled={true}>
              <WhiteContainer>
                <WalletDetails data={data}/>
              </WhiteContainer>
            </Tabs.TabPane>
          </CustomTabs>
        </LightBlueContainer>
      </Form.Provider>
    </>
  );
}

export default DriverDetails;
