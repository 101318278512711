import axios from "axios";


const url = process.env.REACT_APP_BACKEND_BASE_URL;

const UploadImage = async (formData: any) => {
 return await axios.post(`${url}/public/register/upload-image/`, formData)
  .then((response) => {
   return response.data;
  }).catch((err) => {
   return err.message;
  });
};


export {
 UploadImage
};
