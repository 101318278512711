import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
`;
const GlobalTableStyle = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > td,
  .ant-table-wrapper tfoot > tr > th,
  .ant-table-wrapper tfoot > tr > td {
    padding: 3px 3px;
  }
  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 2px 0;
}
`;
const LightBlueContainer = styled(MainContainer)`
  background-color: #cbe3f7;
`;

const WhiteContainer = styled(MainContainer)`
  background-color: #fff;
  padding:1.2rem;
`;

export { LightBlueContainer, WhiteContainer, GlobalTableStyle };
