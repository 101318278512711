import { Form, Input, Row, Col, TimePicker, Divider, Select } from "antd";
import React, { Fragment, useState } from "react";
import { MdDelete } from "react-icons/md";
import { AddButton } from "../../../styled/Button.styled";
import SelectGst from "../../../selectOption/SelectGst";
import { useGetFareCategoryQuery } from "../../../../feature/Fare/fareApiSlice";

type rentalProps = {
  data?: any;
  fareList?: any;
};
const Rental = (props: rentalProps) => {
  const { Option } = Select;
  const [error, setError] = useState("");
  const { data: category } = useGetFareCategoryQuery('RENTAL_TAXI', {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const form = Form.useFormInstance();

  const handleInputChange = (e: any) => {
    if (e.target.value > 0) {
      setError("");
    } else {
      setError("Amount must greater than 0");
    }
  };

  return (
    <>
      <Row gutter={[32, 22]}>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <label> Select Category </label>
              <Form.Item
                name={'name'}
                rules={[
                  {
                    required: true,
                    message: 'Please select a category'
                  }
                ]}
              >
                <Select
                  placeholder={`Select category`}
                >
                  {
                    category?.data.map((cat: any) => {
                      return (
                        <Option key={cat.id} value={JSON.stringify(cat)}>
                          {cat.name}
                        </Option>
                      );
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <label>GST</label>
              <SelectGst />
            </Col>
          </Row>
        </Col >

        <Col span={12}>
          <Row gutter={[4, 4]}>
            <label>Night Charges</label>
            <div className="borderBox">
              <Col span={24}>
                <label>Amount</label>
                <Form.Item name="nightChargeAmount"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter amount'
                    }
                  ]}>
                  <Input onChange={(e) => handleInputChange(e)} />
                </Form.Item>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </Col>
              <Row gutter={[4, 4]}>
                <Col span={12}>
                  <label>Start Time</label>
                  <Form.Item name="nightChargeStartTime"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter start time'
                      }
                    ]}>
                    <TimePicker className="w-100" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label>End Time</label>
                  <Form.Item name="nightChargeEndTime"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter end time'
                      }
                    ]}>
                    <TimePicker className="w-100" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Row>
        </Col>
      </Row >
      <Divider />
      <Row>
        <Col span={24}>
          <Form.List name="rentalCharts">
            {(fields, { add, remove }) => (
              <>
                <Row justify="space-between" className="button-bottom-margin">
                  <label>Rental Chart</label>
                  <AddButton onClick={() => add()}>Add Slot</AddButton>
                </Row>

                <Row className="borderRentalChart">
                  {fields.map((field, index) => (
                    <Fragment key={field.key}>
                      <Row gutter={[32, 8]} justify="space-around">
                        <Col span={1}>{index + 1}.</Col>
                        <Col span={6}>
                          <Form.Item
                            label=" Rental Hrs(Max.)"
                            name={[index, `maxHour`]}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter hours'
                              },
                              () => ({
                                validator: async (_, value) => {
                                  if (value < 0) {
                                    return Promise.reject(
                                      new Error("Entered hours should not be in negative")
                                    );
                                  }
                                },
                              }),
                            ]}
                          >
                            <Input className="w-100" />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label=" Rental kilometer"
                            name={[index, `maxKilometer`]}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter kilometer'
                              },
                              () => ({
                                validator: async (_, value) => {
                                  if (value < 0) {
                                    return Promise.reject(
                                      new Error("Entered kilometer should not be negative value")
                                    );
                                  }
                                },
                              }),
                            ]}
                          >
                            <Input className="w-100" />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            label=" Fare (per km)"
                            name={[index, `farePerKilometer`]}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter fare per Kilometer'
                              },
                              () => ({
                                validator: async (_, value) => {
                                  if (value < 0) {
                                    return Promise.reject(
                                      new Error("Please enter an amount greater than or equal to zero")
                                    );
                                  }
                                },
                              }),
                            ]}

                          >
                            <Input className="w-100" />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            label="Fare(>/km)"
                            name={[index, `fareGreaterKilometer`]}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter fare if kilometer limit is exceeded'
                              },
                              () => ({
                                validator: async (_, value) => {
                                  if (value < 0) {
                                    return Promise.reject(
                                      new Error("Please enter an amount greater than or equal to zero")
                                    );
                                  }
                                },
                              }),
                            ]}
                          >
                            <Input className="w-100" />
                          </Form.Item>
                        </Col>

                        <Col span={1}>
                          {fields.length > 1 ? (
                            <MdDelete
                              size={18}
                              onClick={() => remove(field.name)}
                              style={{ marginTop: "2.5rem" }}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Fragment>
                  ))}
                </Row>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </>
  );
};

export default Rental;
