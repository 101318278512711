import React from "react";
import {useEffect, useState } from "react";
import {
  Row,
  Input,
  Table,
  Typography,
  Switch,
  Modal,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  GlobalTableStyle,
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { ColumnsType } from "antd/es/table";
import {
  DeleteButton,
  PrimaryButton,
  ViewDetailsButton,
} from "../../styled/Button.styled";
import CustomCard from "../../countCard/CustomCard";
import pageSize from "../../../constants/pageSize";

import {
  useDeleteOfferByIdMutation,
  useGetAllOffersQuery,
  useUpdateOfferStatusByIdMutation,
  useGetOfferCountQuery
} from "../../../feature/Offer/offerApiSlice";
import { IOffer } from "../../../types/offers";
import moment from "moment";

function Offers() {
  const { data, isLoading,refetch } = useGetAllOffersQuery();
  const { data: countData } = useGetOfferCountQuery();
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [couponValue, setCouponValue] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [dateValue, setDateValue] = useState("");

  const [couponName, setCouponName] = useState("");
  const [deleteOfferById] = useDeleteOfferByIdMutation();
  const [updateOfferStatusById] = useUpdateOfferStatusByIdMutation();
  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Offer?",
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const onDelete = (id: number) => {
    deleteOfferById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      });
  };
  const toogleSwitch = (id: any) => {
    updateOfferStatusById(id);
  };
  const columns: ColumnsType<IOffer> = [
    {
      key: "couponCode",
      title: (
        <div>
          <Typography.Text> Coupon Code</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCodeValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [codeValue],
      onFilter: (value: any, record) => {
        return record?.couponCode?.toLowerCase().includes(value?.toLowerCase());
      },

      dataIndex: "couponCode",
    },
    {
      key: "couponName",
      title: (
        <div>
          <Typography.Text>Coupon Name</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCouponName(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [couponName],
      onFilter: (value: any, record) => {
        return record.couponName?.toLowerCase().includes(value?.toLowerCase());
      },
      dataIndex: "couponName",
    },
    {
      key: "couponValue",
      title: (
        <div>
          <Typography.Text>Coupon Value(in Rs.)</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setCouponValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [couponValue],
      render: (value: any, record) => {
        return record.couponValue;
      },
      dataIndex: "couponValue",
    },
    {
      key: "expireDate",
      title: (
        <div>
          <Typography.Text>Valid upto</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setDateValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [dateValue],
      render: (value: any, record) => {
        return moment(new Date(record?.expireDate)).format("DD-MM-YYYY");
      },
      dataIndex: "expireDate",
    },
    {
      key: "",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
        </div>
      ),
      render: (_, record) => {
        return (
          <>
            {record.status === true ? (
              <>
                <div>
                  Active
                </div>
                <Switch
                  onChange={(e) => toogleSwitch(record.id)}
                  checked={record.status}
                  style={{ backgroundColor: "green" }}
                />
              </>
            ) : (
              <>
                <div>
                  Inactive
                </div>
                <Switch
                  onChange={() => toogleSwitch(record.id)}
                  checked={record.status}
                  style={{ backgroundColor: "red" }}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openPlaceDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];
  const openPlaceDetails = (id: number) => {
    navigate(`edit/${id}`);
  };
  const sum = data?.data.content.length;
  const active = countData?.data.activeCount;
  const inActive = countData?.data.inActiveCount;

  useEffect(() => {
    refetch(); 
  }, [refetch]);
  
  return (
    <>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard
          sum={sum}
          text="Offers"
          activeText="Active"
          active={active}
          inActiveText="Inactive"
          inActive={inActive}
        />
        <Link to="add">
          <PrimaryButton> Add Offers</PrimaryButton>
        </Link>
      </Row>
      <LightBlueContainer>
          <WhiteContainer>
            <GlobalTableStyle>
              <Table<IOffer>
                loading={isLoading}
                columns={columns}
                dataSource={data?.data.content}
                rowKey="id"
                pagination={{
                  pageSize,
                }}
              />
            </GlobalTableStyle>
          </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}
export default Offers;
