import { Input, Row, Col, DatePicker, Form, Card } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import SelectStateOption from "../../selectOption/SelectStateOption";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { IUserByIdResponse } from "../../../types/user";
import constants from "../../../constants";
const defaultImage: string = require("../../../assets/logo/defaultImage.svg").default;

type userProps = {
  data?: IUserByIdResponse;
};

const imageUploaderStyle = {
  width: "8rem",
  height: "6rem",
  borderRadius: "5%",
  border: "2px Solid #cbe3f7",
};
function UserDetailsForm(props: userProps) {
  const { data } = props;
  const [img, setImg] = useState({
    image_file: null,
    image_preview: " ",
  });

  useEffect(() => {
    setImg({
      image_file: null,
      image_preview: `${constants.baseImgUrl}${data?.data.userImage} `,
    });
  }, [data?.data.userImage]);
  return (
    <>
      <Form
        disabled
        initialValues={{
          firstName: data?.data.firstName,
          lastName: data?.data.lastName,
          dob: dayjs(data?.data.userDateOfBirth),
          phoneNumber: data?.data.phoneNumber,
          email: data?.data.email,
          homeAddress: data?.data.homeAddress,
          officeAddress: data?.data.officeAddress,
          state: data?.data.userState,
          image: data?.data.userImage,
        }}
      >
        <LightBlueContainer>
          <WhiteContainer>
            <Row gutter={[32, 22]}>
              <Col span={12}>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={6}>
                        <Card>
                          <img
                            src={img.image_preview}
                            onError={(e) => {
                              (e.target as HTMLSourceElement).src = defaultImage;
                            }}
                            alt="Profile"
                            style={imageUploaderStyle}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <label>First Name</label>
                    <Form.Item name="firstName" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <label>Last Name</label>
                    <Form.Item name="lastName" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <label>Phone Number</label>
                    <Form.Item name="phoneNumber" rules={[{ required: true }]}>
                      <Input maxLength={10} type="number" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <label>Email</label>
                    <Form.Item name="email" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <label>Home Address</label>
                    <Form.Item name="homeAddress" rules={[{ required: true }]}>
                      <TextArea rows={3} disabled />
                    </Form.Item>
                 </Col>
                </Row>
              </Col>

              <Col span={12}>
                <label>Work Address</label>
                <Form.Item name="officeAddress" rules={[{ required: true }]}>
                  <TextArea rows={3} disabled />
                </Form.Item>
                {/* <label>State</label>

                <SelectStateOption /> */}
              </Col>
            </Row>
          </WhiteContainer>
        </LightBlueContainer>
      </Form>
    </>
  );
}

export default UserDetailsForm;
