import imageCompression from "browser-image-compression";

export const handleImageSize = async (file: any) => {
    try {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options); 
        return new File([compressedFile], file.name, { type: file.type });
    } catch (error) {
        console.error('Image compression error:', error);
    }
};
