import {
  menuItemBgColorHoverSelected,
  menuItemFontSize,
  menuItemTextColorHoverSelected,
  bgColor,
  menuItemFontColor,
} from "../../constants/theme";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider, Menu } from "antd";
import { adminItems } from "./Items";

const SiderMenuItems = () => {
  const { pathname } = useLocation();

  let selection = pathname.split("/")[1];

  const navigate = useNavigate();
  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: menuItemFontSize,
          colorText: menuItemFontColor,
        },
        components: {
          Menu: {
            colorItemTextHover: menuItemTextColorHoverSelected,
            colorItemTextSelected: menuItemTextColorHoverSelected,
            colorItemBgHover: menuItemBgColorHoverSelected,
            colorItemBgSelected: menuItemBgColorHoverSelected,
          },
        },
      }}
    >
      <Menu
        onClick={onClick}
        defaultSelectedKeys={["/"]}
        mode="inline"
        items={adminItems}
        selectedKeys={[selection]}
        style={{ backgroundColor: `${bgColor}` }}
      />
    </ConfigProvider>
  );
};

export default SiderMenuItems;
