import { mainApi } from "../../app/services/mainApiSlice";
import { IBookingByIdResponse, IBookingResponse } from "../../types/booking";
const commonUrl: string = "/admin/trip";

const bookingApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All booking
    getAllTrip: builder.query<IBookingResponse, void>({
      query: () => ({
        url: `${commonUrl}/get-all?page=0&size=1000`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "BOOKING" }],
    }),

    getTripById: builder.query<IBookingByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "BOOKING" }],
    }),


  }),
  overrideExisting: false,
});

export const {
  useGetAllTripQuery,
  useGetTripByIdQuery

} = bookingApi;