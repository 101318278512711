import { Form, Input, Col, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { useEffect, useState } from "react";
import { IDriverByIdResponse } from "../../../../types/driver";
type BankDetailsProps = {
  data?: IDriverByIdResponse;
  bankDetailsResponse?: any;
};

export default function BankDetails(props: BankDetailsProps) {
  const [form] = Form.useForm();
  const { data, bankDetailsResponse } = props;
  const { Option } = Select;
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [bankData, setBankData] = useState<any>();
  const [error, setError] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `https://ifsc.razorpay.com/${e.target.value}`
      );
      setBankData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    form?.setFieldsValue({
      bankName: data?.data.bankDetails.bankName || bankData?.BANK,
      branchName: data?.data.bankDetails.branchName || bankData?.BRANCH,
      address: data?.data.bankDetails.address || bankData?.ADDRESS,
      accountId:data?.data.accountId,
    });
  }, [bankData, data, form]);

  const [formData, setFormData] = useState({
    ifscCode: "",
    accountNumber: "",
    accountHolderName: "",
  });

  const handleFieldChange = (fieldName: any, value: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
    let res = Object.values(formData).every((value) => value.trim() !== "");
    bankDetailsResponse(res);
  };

  function isValidIFSCCode(e: any) {
    handleFieldChange("ifscCode", e.target.value);
    if (/^[A-Z]{4}[0][A-Z0-9]{6}$/.test(e.target.value)) {
      handleSubmit(e);
      setIfscCodeError("");
    } else {
      setIfscCodeError("Invalid IFSC Code");
    }
  }
  const handleAccount = (e: any) => {
    handleFieldChange("accountNumber", e.target.value);
    if (/^\d{15}$/.test(e.target.value)) {
      setError("");
    } else {
      setError("Invalid account number");
    }
  };

  return (
    <>
      <Form
        form={form}
        name="bankDetailForm"
        initialValues={{
          ifscCode: data?.data.bankDetails.ifscCode,
          accountHolderName: data?.data.bankDetails.accountHolderName,
          accountNumber: data?.data.bankDetails.accountNumber,
          accountId: data?.data.accountId,
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <label>IFSC Code</label>
                <Form.Item
                  name="ifscCode"
                  rules={[{ required: true, message: "IFSC Code is required" }]}
                >
                  <Input onChange={(e) => isValidIFSCCode(e)} />
                </Form.Item>
                {ifscCodeError && (
                  <p style={{ color: "red" }}>{ifscCodeError}</p>
                )}
              </Col>
              <Col span={24}>
                <label>Bank</label>
                <Form.Item
                  name="bankName"
                  rules={[{ required: true, message: "Bank is required" }]}
                >
                  <Select disabled>
                    <Option>Select Bank</Option>
                    <Option value={"State Bank Of India"}>
                      State Bank Of India
                    </Option>
                    <Option value={"Punjab National Bank"}>
                      Punjab National Bank
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <label>Branch</label>
                <Form.Item
                  name="branchName"
                  rules={[
                    { required: true, message: "Branch Name is required" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Address</label>
                <Form.Item
                  name="address"
                  rules={[{ required: true, message: "Address is required" }]}
                >
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row>
            <Col span={24}>
                <label>Account ID</label>
                <Form.Item
                  name="accountId"
                  rules={[
                    { required: true, message: "Account ID is required" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>Account Number</label>
                <Form.Item
                  name="accountNumber"
                  rules={[
                    { required: true, message: "Account number is required" },
                  ]}
                >
                  <Input type="text" onChange={(e) => handleAccount(e)} />
                </Form.Item>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </Col>
              <Col span={24}>
                <label>Account Holder Name</label>
                <Form.Item
                  name="accountHolderName"
                  rules={[
                    { required: true, message: "Account Holder is required" },
                  ]}
                >
                  <Input
                    minLength={3}
                    onChange={(e) =>
                      handleFieldChange("accountHolderName", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}
