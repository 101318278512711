import { Card, Col, Divider, Form, Input, Row, Spin, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useState } from "react";
import { IPlaceByIdResponse } from "../../../types/popularPlace";
import LocationPicker from "../../popularPlace/LocationPicker";
import SelectStateOption from "../../selectOption/SelectStateOption";
import { StandaloneSearchBox } from "@react-google-maps/api";
import {
  PictureOutlined
} from '@ant-design/icons';
import { useUploadImageMutation } from "../../../feature/PopularPlace/popularplaceApiSlice";
type PlaceDetailsProps = {
  data?: IPlaceByIdResponse;
  setImgFile?: any;
  loading?: (status: boolean) => void;
};
const imageUploaderStyle = {
  width: "18rem",
  height: "10rem",
  borderRadius: "5%",
  border: "2px Solid #cbe3f7",
};


function Place(props: PlaceDetailsProps) {
  const { data, setImgFile } = props;
  const [latitudeValue, setLatitudeValue] = useState(
    data?.data.latitude || " "
  );
  const [longitudeValue, setLongitudeValue] = useState(
    data?.data.longitude || " "
  );
  const [uploadImage, { isLoading }] = useUploadImageMutation();
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [searchBox, setSearchBox] = useState<any>(null);
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [img, setImg] = useState({
    image_file: null,
    image_preview: " ",
  });
  const handleImageSelect = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setIsImageSelected(true);
    } else {
      setIsImageSelected(false);
    }
  };

  const imgFileHandler = (e: any) => {
    props.loading!(true);
    handleImageSelect(e);
    if (e.target.files[0].size <= 500000 && e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png' && e.target.files[0].size <= 500000) {
      let image_as_base64 = URL.createObjectURL(e.target.files[0]);
      let image_as_files = e.target.files[0];
      let fd = new FormData();
      fd.append('image', e.target.files[0]);
      uploadImage({ formData: fd }).then((response: any) => {
        if (response) {
          setImg({
            image_preview: image_as_base64,
            image_file: image_as_files,
          });
          setImgFile(response.data.data);
          props.loading!(false);
        }
      }).catch(err => {
        console.log(err.message);
        props.loading!(false);
      });
    } else {
      props.loading!(false);
      notification.error({
        message: "Image size should be less than 500kb or image should in jpeg or in png format",
        placement: "bottomLeft",
      });
    }
  };
  const [form] = Form.useForm();

  useEffect(() => {
    if (!!data) {
      setImgFile(data?.data.image);
    }
  }, [data, setImgFile]);

  const onPlacesChanged = useCallback(() => {
    if (!!searchBox) {
      const places = searchBox.getPlaces();
      if (places && places.length > 0) {
        setSelectedPlace(places[0]);
        form.setFieldValue("latitude", places[0]?.geometry.location.lat());
        form.setFieldValue("longitude", places[0]?.geometry.location.lng());
        form.setFieldValue("place", places[0]?.vicinity);
        form.setFieldsValue(
          {
            district: "",
            state: "",
            address: ""
          }
        );
      }
    }
  }, [form, searchBox]);

  const onSBLoad = (ref: any) => {
    setSearchBox(ref);
  };

  const imageSecondary = () => {
    return (
      !!img.image_file ? <img
        src={img.image_preview}
        alt=" Place"
        style={imageUploaderStyle}
      /> : < PictureOutlined style={{ fontSize: "10rem" }} />
    );
  };

  const imageTernary = () => {
    return (
      !!data?.data.image && !img.image_file ? <img
        src={data?.data.image}
        alt=" Place"
        style={imageUploaderStyle}
      /> : imageSecondary()
    );
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="placeDetailsForm"
        initialValues={{
          placeName: data?.data.placeName,
          place: data?.data.place,
          address: data?.data.address,
          district: data?.data.district,
          state: data?.data.state,
          image: data?.data.image,
          latitude: data?.data.latitude,
          longitude: data?.data.longitude
        }}
      >
        <Row>
          <Col span={24}>
            <Col >
              <label>Location on Map</label>
              <Col span={24}>
                <LocationPicker
                  data={data}
                  setLatitudeValue={setLatitudeValue}
                  setLongitudeValue={setLongitudeValue}
                  searchPlace={selectedPlace}
                  setAddress={(e: any) => {
                    form.setFieldValue('place', e?.address_components.filter((v: any) => v.types.includes('locality') ? v : null)[0].long_name);
                    form.setFieldValue('district', e?.address_components.filter((v: any) => v.types.includes('administrative_area_level_3') ? v : null)[0].long_name);
                    form.setFieldValue('state', e?.address_components.filter((v: any) => v.types.includes('administrative_area_level_1') ? v : null)[0].long_name);
                    form.setFieldValue('address', e?.formatted_address);
                    form.setFieldValue("latitude", e?.geometry.location.lat());
                    form.setFieldValue("longitude", e?.geometry.location.lng());
                  }}
                />
              </Col>
            </Col>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <label>Place</label>

                <StandaloneSearchBox
                  onPlacesChanged={onPlacesChanged}
                  onLoad={onSBLoad}>
                  <Form.Item
                    name="place"
                    rules={[{ required: true, message: "Place is required" }]}
                  >
                    <Input
                      type='text'
                      placeholder='Search place'

                    />
                  </Form.Item>
                </StandaloneSearchBox>
              </Col>

              <Col span={24}>
                <label>Place Name</label>
                <Form.Item
                  name="placeName"
                  rules={[{ required: true, message: "placeName is required" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address"
                  rules={[{ required: true, message: "Address is required" }]}
                >
                  <TextArea />
                </Form.Item>
              </Col>

              <Col span={24}>
                <label>District</label>
                <Form.Item
                  name="district"
                  rules={[{ required: true, message: "District is required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>State</label>
                <Form.Item name="state">
                  <SelectStateOption />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Card>
                      {
                        !isLoading ? imageTernary() : <Spin style={{ display: "flex", justifyContent: "center" }} />
                      }


                      {!data?.data.image && (isImageSelected ? null : (
                        <p style={{ color: "red" }}>Image is required.</p>)
                      )}
                    </Card>
                  </Col>
                  <Col span={12}>
                    <input
                      type="file"
                      name="image"
                      className="custom-placebtn"
                      onChange={imgFileHandler}
                    />
                  </Col>
                </Row>
                <Col span={12}>
                  <label>Latitude</label>
                  <Form.Item name="latitude">
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label>Longitude</label>
                  <Form.Item name="longitude">
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Place;
