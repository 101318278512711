import { Form, Row, Col, Input, Card } from "antd";
import { useEffect, useState } from "react";
import { IDriverByIdResponse } from "../../../../types/driver";
import { UploadImage } from "../../../axios/imageUpload";
import { handleImageSize } from "../../../../utils/handleImageSize";

type DocumentDetailsProps = {
  data?: IDriverByIdResponse;
  setAadharFrontImage?: any;
  setAadharBackImage?: any;
  setDrivingLicenceFrontImage?: any;
  setDrivingLicenceBackImage?: any;
  setPanFrontImage?: any;
  setPanBackImage?: any;
  documentDetailsResponse?: any;
};
const imageUploaderStyle = {
  width: "12rem",
  height: "8rem",
};
export default function DocumentDetails(props: DocumentDetailsProps) {
  const [form] = Form.useForm();

  const [aadharError, setAadharError] = useState("");

  const [panError, setPanError] = useState("");
  const [licenseError, setLicenseError] = useState("");

  const {
    data,
    setAadharFrontImage,
    setAadharBackImage,
    setDrivingLicenceFrontImage,
    setDrivingLicenceBackImage,
    setPanFrontImage,
    setPanBackImage,
    documentDetailsResponse,
  } = props;

  const [aadharFrontImg, setAadharFrontImg] = useState({
    fImage_file: '',
    fImage_preview: "",
  });
  const [aadharBackImg, setAadharBackImg] = useState({
    bImage_file: '',
    bImage_preview: "",
  });

const aadharFrontImgHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
  
      const resultImage= await handleImageSize(file);

      if(resultImage!==undefined){
        const compressedFile:File=resultImage;
        const image_as_base64 = URL.createObjectURL(compressedFile);
  
        let fd = new FormData();
        fd.append('image', compressedFile);

        UploadImage(fd)
          .then((res) => {
            setAadharFrontImg({
              fImage_preview: image_as_base64,
              fImage_file: res.data,
            });
            setAadharFrontImage(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        }
    }
  };

const aadharBackImgHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const resultImage= await handleImageSize(file);

      if(resultImage!==undefined){
        const compressedFile:File=resultImage;
        const image_as_base64 = URL.createObjectURL(compressedFile);
  
        let fd = new FormData();
        fd.append('image', compressedFile);

        UploadImage(fd)
          .then((res) => {
            setAadharBackImg({
              bImage_preview: image_as_base64,
              bImage_file: res.data,
            });
            setAadharBackImage(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        }
    }
  };

  const [panFrontImg, setPanFrontImg] = useState({
    fImage_file: '',
    fImage_preview: "",
  });

const panFrontImgHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
  
      const resultImage= await handleImageSize(file);

      if(resultImage!==undefined){
        const compressedFile:File=resultImage;
        const image_as_base64 = URL.createObjectURL(compressedFile);
  
        let fd = new FormData();
        fd.append('image', compressedFile);

        UploadImage(fd)
          .then((res) => {
            setPanFrontImg({
              fImage_preview: image_as_base64,
              fImage_file: res.data,
            });
            setPanFrontImage(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        }
    }
  };

  const [drivingLicenceFrontImg, setDrivingLicenceFrontImg] = useState({
    fImage_file: '',
    fImage_preview: "",
  });
  const [drivingLicenceBackImg, setDrivingLicenceBackImg] = useState({
    bImage_file: '',
    bImage_preview: "",
  });

const drivingLicenceFrontImgHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
  
      const resultImage= await handleImageSize(file);

      if(resultImage!==undefined){
        const compressedFile:File=resultImage;
        const image_as_base64 = URL.createObjectURL(compressedFile);
  
        let fd = new FormData();
        fd.append('image', compressedFile);

        UploadImage(fd)
          .then((res) => {
            setDrivingLicenceFrontImg({
              fImage_preview: image_as_base64,
              fImage_file: res.data,
            });
            setDrivingLicenceFrontImage(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        }
    }
  };

const drivingLicenceBackImgHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
  
      const resultImage= await handleImageSize(file);

      if(resultImage!==undefined){
        const compressedFile:File=resultImage;
        const image_as_base64 = URL.createObjectURL(compressedFile);
  
        let fd = new FormData();
        fd.append('image', compressedFile);

        UploadImage(fd)
          .then((res) => {
            setDrivingLicenceBackImg({
              bImage_preview: image_as_base64,
              bImage_file: res.data,
            });
            setDrivingLicenceBackImage(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        }
    }
  };

  const [formData, setFormData] = useState({
    aadhar: "",
    pan: "",
    drivingLicence: "",
  });

  const handleFieldChange = (fieldName: any, value: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
    let response = Object.values(formData).every(
      (value) => value.trim() !== ""
    );
    documentDetailsResponse(response);
  };
  const isValid_Aadhaar_Number = (e: any) => {
    handleFieldChange("aadhar", e.target.value);
    if (!/^[1-9][0-9]{11}$/.test(e.target.value)) {
      setAadharError("Invalid aadhar number");
    } else {
      setAadharError("");
    }
  };

  const isValidPanCardNo = (e: any) => {
    handleFieldChange("pan", e.target.value);
    if (/^[A-Za-z0-9]{10}$/.test(e.target.value)) {
      setPanError("");
    } else {
      setPanError("Invalid PAN number");
    }
  };
  const isValidLicenseNumber = (e: any) => {
    handleFieldChange("drivingLicence", e.target.value);
    if (
      /^[A-Za-z0-9]{16}$/.test(
        e.target.value
      )
    ) {
      setLicenseError("");
    } else {
      setLicenseError("Invalid license number");
    }
  };

  useEffect(() => {
    setAadharFrontImg({
      fImage_preview: `${data?.data?.documentList[0]?.cardFrontImage}`,
      fImage_file: `${data?.data?.documentList[0]?.cardFrontImage}`,
    });
    setAadharFrontImage(data?.data?.documentList[0]?.cardFrontImage);

    setAadharBackImg({
      bImage_file: `${data?.data?.documentList[0]?.cardBackImage}`,
      bImage_preview: `${data?.data?.documentList[0]?.cardBackImage}`,
    });
    setAadharBackImage(data?.data?.documentList[0]?.cardBackImage);

    setPanFrontImg({
      fImage_preview: `${data?.data?.documentList[1]?.cardFrontImage}`,
      fImage_file: `${data?.data?.documentList[1]?.cardFrontImage}`,
    });
    setPanFrontImage(data?.data?.documentList[1]?.cardFrontImage);

    setDrivingLicenceFrontImg({
      fImage_preview: `${data?.data?.documentList[2]?.cardFrontImage}`,
      fImage_file: `${data?.data?.documentList[2]?.cardFrontImage}`,
    });
    setDrivingLicenceFrontImage(data?.data?.documentList[2]?.cardFrontImage);

    setDrivingLicenceBackImg({
      bImage_file: `${data?.data?.documentList[2]?.cardBackImage}`,
      bImage_preview: `${data?.data?.documentList[2]?.cardBackImage}`,
    });
    setDrivingLicenceBackImage(data?.data?.documentList[2]?.cardBackImage);
  }, [data]);

  return (
    <>
      <Form
        form={form}
        name="documentDetailForm"
        initialValues={{
          aadhaarNumber: data?.data.documentList[0]?.cardNumber,
          drivingNumber: data?.data.documentList[2]?.cardNumber,
          panNumber: data?.data.documentList[1]?.cardNumber,
        }}
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <label>Aadhar</label>
            <div className="borderBoxPermit">
              <div>
                <Form.Item
                  name="aadhaarNumber"
                  rules={[
                    { required: true, message: "Aadhar number is required" },
                  ]}
                >
                  <Input onChange={(e) => isValid_Aadhaar_Number(e)} />
                </Form.Item>
                {aadharError && <p style={{ color: "red" }}>{aadharError}</p>}

                <label>Aadhar Card</label>
                <Row>
                  <Col span={12}>
                    <div className="borderImg">
                      <Card>
                        <img
                          src={aadharFrontImg.fImage_preview}
                          alt="Front Img"
                          style={imageUploaderStyle}
                        />
                      </Card>
                    </div>
                    <input
                      type="file"
                      className="custom-frontbtn"
                      name="aadhaarFrontImage"
                      onChange={aadharFrontImgHandler}
                    />
                  </Col>
                  <Col span={12}>
                    <Card>
                      <img
                        src={aadharBackImg.bImage_preview}
                        alt=" Back Img"
                        style={imageUploaderStyle}
                      />
                    </Card>
                    <input
                      type="file"
                      className="custom-backbtn"
                      name="aadhaarBackImage"
                      onChange={aadharBackImgHandler}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <label>PAN</label>
            <div className="borderBoxPermit">
              <Form.Item
                name="panNumber"
                rules={[{ required: true, message: "PAN number is required" }]}
              >
                <Input onChange={(e) => isValidPanCardNo(e)} />
              </Form.Item>
              {panError && <p style={{ color: "red" }}>{panError}</p>}

              <label>PAN Card</label>
              <Row>
                <Col span={12}>
                  <Card>
                    <img
                      src={panFrontImg.fImage_preview}
                      alt=" Front Img"
                      style={imageUploaderStyle}
                    />
                  </Card>
                  <input
                    type="file"
                    className="custom-frontbtn"
                    name="panImage"
                    onChange={panFrontImgHandler}
                  />
                </Col>
                {/* <Col span={12}>
                  <Card>
                    <img
                      src={panBackImg.bImage_preview}
                      alt=" Back Img"
                      style={imageUploaderStyle}
                    />
                  </Card>
                  <input
                    type="file"
                    className="custom-backbtn"
                    name="backImage"
                    onChange={panBackImgHandler}
                  />
                </Col> */}
              </Row>
            </div>
          </Col>

          <Col span={12}>
            <label>Driving Licence</label>
            <div className="borderBoxPermit">
              <Form.Item
                name="drivingNumber"
                rules={[
                  {
                    required: true,
                    message: "Driving licence number is required",
                  },
                ]}
              >
                <Input onChange={(e) => isValidLicenseNumber(e)} />
              </Form.Item>
              {licenseError && <p style={{ color: "red" }}>{licenseError}</p>}

              <label>Driving Licence Card</label>
              <Row>
                <Col span={12}>
                  <Card>
                    <img
                      src={drivingLicenceFrontImg.fImage_preview}
                      alt=" Front Img"
                      style={imageUploaderStyle}
                    />
                  </Card>
                  <input
                    type="file"
                    className="custom-frontbtn"
                    name="drivingFrontImage"
                    onChange={drivingLicenceFrontImgHandler}
                  />
                </Col>
                <Col span={12}>
                  <Card>
                    <img
                      src={drivingLicenceBackImg.bImage_preview}
                      alt=" Back Img"
                      style={imageUploaderStyle}
                    />
                  </Card>
                  <input
                    type="file"
                    className="custom-backbtn"
                    name="drivingBackImage"
                    onChange={drivingLicenceBackImgHandler}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}
