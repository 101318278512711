import { mainApi } from "../../app/services/mainApiSlice";
import {
    IFare,
    IFareByIdResponse,
    IFareResponse
} from "../../types/fare";


const commonUrl: string = "/admin/fare";
const getCategory: string = "/admin/category";
const fareApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({

        getAllFare: builder.query<any, void>({
            query: () => ({
                url: `${commonUrl}/get-all-fares?page=0&size=1000`,
                method: "GET",

            }),
            providesTags: [{ type: "Main", id: "FARE" }],
        }),

        getFareById: builder.query<any, string | undefined>({
            query: (id) => ({
                url: `${commonUrl}/get-by-id/${id}`,
                method: "GET",

            }),
            keepUnusedDataFor: 1
        }),


        addFare: builder.mutation<any, any>({
            query: (offerData) => ({
                url: `${commonUrl}/save`,
                method: "POST",

                body: offerData,
            }),
            invalidatesTags: [{ type: "Main", id: "FARE" }],
        }),


        updateFareById: builder.mutation<
            IFare,
            { id: string | undefined; post: any; }
        >({
            query: ({ id, post }) => ({
                url: `${commonUrl}/update-by-id/${id}`,
                method: "PUT",

                body: post,
            }),
            invalidatesTags: [{ type: "Main", id: "FARE" }],
        }),

        getFareCategory: builder.query({
            query: (type: string) => ({
                url: `${getCategory}/all-list?taxiType=${type}`,
                method: "GET",
                keepUnusedDataFor: 0
            }),
            keepUnusedDataFor: 0
        }),

        deleteFareById: builder.mutation<void, number | undefined>({
            query: (id) => ({
                url: `${commonUrl}/delete-by-id/${id}`,
                method: "DELETE",

            }),
            invalidatesTags: [{ type: "Main", id: "FARE" }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAllFareQuery,
    useGetFareByIdQuery,
    useAddFareMutation,
    useDeleteFareByIdMutation,
    useUpdateFareByIdMutation,
    useGetFareCategoryQuery
} = fareApi;
