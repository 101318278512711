import { Button, Col, Form, Input, Row, notification } from "antd";
import React, { useEffect, useState } from "react";
import { ICategoryByIdResponse } from "../../../types/category";
import SelectTaxiService from "../../selectOption/TaxiService";
import { useUploadCategoryImageMutation } from "../../../feature/Category/categoryApiSlice";
import { GCP_URL } from "../../../utils/gcpConstant";
import ImageUploader from "../../uploader/ImageUploader";

type CategoryProps = {
  data?: ICategoryByIdResponse;
  setCategoryImageForm?: React.Dispatch<React.SetStateAction<FormData>>;
  setActiveKey: React.Dispatch<React.SetStateAction<string>>;
};

function BasicDetails(props: CategoryProps) {
  const { data, setCategoryImageForm, setActiveKey } = props;
  const [form] = Form.useForm();
  const [uploadCategoryImage] = useUploadCategoryImageMutation();
  const [img, setImg] = useState({
    image_file: null,
    image_preview: "",
  });
  const [categoryImageUpload, setCategoryImageUpload] = useState<FormData>(
    new FormData()
  );
  useEffect(() => {
    const imageUrl = `${data?.data?.image}`;
    setImg({
      image_preview: imageUrl,
      image_file: null,
    });
  }, [data]);

  useEffect(() => {
    if (categoryImageUpload.has("image")) {
      uploadCategoryImage({ id: data?.data.id, formData: categoryImageUpload })
        .unwrap()
        .then(() => {
          notification.success({
            message: "Successfully edited",
            placement: "bottomLeft",
          });
        })
        .catch(() => {
          notification.error({
            message: "Error occured",
            placement: "bottomLeft",
          });
        });
    }
  }, [categoryImageUpload]);

  const validateAndProceed = () => {
    form.validateFields().then(() => {
      setActiveKey("2");
    });
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="CategoryDetailsForm"
        initialValues={{
          name: data?.data.name,
          taxiServiceType: data?.data.taxiServiceType.map(
            (item) => item.taxiType
          ),
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row>
              <Col>
                <ImageUploader
                  src={img.image_preview}
                  type={"file"}
                  name={"image"}
                  id={"file"}
                  setImg={setImg}
                  setCategoryImageUpload={
                    data ? setCategoryImageUpload : setCategoryImageForm
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <label>Categories Name </label>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Please enter name" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <label>In Service </label>
                <div className="Ctgservices">
                  <SelectTaxiService
                    form={form}
                    rules={[
                      { required: true, message: "Please select service type" },
                    ]}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row justify={"end"}>
        <Col className="m-1">
          <Button htmlType="button" onClick={validateAndProceed}>
            Proceed to add vehicles
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default BasicDetails;
