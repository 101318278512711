import { mainApi } from "../../app/services/mainApiSlice";
import {
  ICategory,
  ICategoryByIdResponse,
  ICategoryList,
  ICategoryResponse,
} from "../../types/category";
import { IDriverCountResponse } from "../../types/driver";

const commonUrl: string = "/admin/category";

const categoryApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategory: builder.query<ICategoryResponse, void>({
      query: () => ({
        url: `${commonUrl}/all?page=0&size=1000`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "CATEGORY" }],
    }),
    getCategoryCount: builder.query<IDriverCountResponse, void>({
      query: () => ({
        url: `${commonUrl}/count-category`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "CATEGORY" }],
    }),

    getCategoryList: builder.query<ICategoryList, void>({
      query: () => ({
        url: `${commonUrl}/all-list`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "CATEGORY" }],
    }),

    getCategoryById: builder.query<ICategoryByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "CATEGORY" }],
    }),

    addCategory: builder.mutation<ICategory, Partial<ICategory>>({
      query: (categoryData: ICategory) => ({
        url: `${commonUrl}/`,
        method: "POST",

        body: categoryData,
      }),
      invalidatesTags: [{ type: "Main", id: "CATEGORY" }],
    }),

    updateCategoryById: builder.mutation<
      ICategory,
      { id: number | undefined; post: Partial<ICategory>; }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/${id}`,
        method: "PUT",

        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "CATEGORY" }],
    }),

    updateCategoryStatusById: builder.mutation<
      ICategory,
      { id: number | undefined; }
    >({
      query: (id) => ({
        url: `${commonUrl}/update-status/${id}`,
        method: "GET",
      }),
      invalidatesTags: [{ type: "Main", id: "CATEGORY" }],
    }),
    deleteCategoryById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "CATEGORY" }],
    }),
    deleteVehicleById: builder.mutation<any, { categoryId: any, id: any; }>({
      query: ({ categoryId, id }) => ({
        url: `${commonUrl}/category-vehicles/${categoryId}?vehicleId=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Main", id: "CATEGORY" }],
    }),
    uploadCategoryImage: builder.mutation<
      FormData,
      { id: number | undefined; formData: FormData; }
    >({
      query: ({ id, formData }) => ({
        url: `${commonUrl}/save-image/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "CATEGORY" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllCategoryQuery,
  useGetCategoryCountQuery,
  useGetCategoryListQuery,
  useGetCategoryByIdQuery,
  useDeleteCategoryByIdMutation,
  useAddCategoryMutation,
  useUpdateCategoryByIdMutation,
  useUpdateCategoryStatusByIdMutation,
  useUploadCategoryImageMutation,
  useDeleteVehicleByIdMutation
} = categoryApi;
