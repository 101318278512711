const appendData = (keyArray: string[], valueArray: string[]) => {
    const formData = new FormData();

    for (let index = 0; index < keyArray.length; index++) {
        formData.append(keyArray[index], valueArray[index]);
    }
    return formData;
};

export default appendData;
