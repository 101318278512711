import { mainApi } from "../../app/services/mainApiSlice";
import { IProfile, IProfileByIdResponse, ISupport } from "../../types/profile";

const commonUrl: string = "/admin/user";

const profileApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfileById: builder.query<IProfileByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-admin/${id}`,
        method: "GET",
        
      }),
      providesTags: [{ type: "Main", id: "PROFILE" }],
    }),

    updateProfileById: builder.mutation<
      IProfile,
      { id: number | undefined; post: Partial<IProfile> }
    >({
      query: ({ id, post }) => ({
        url: `${commonUrl}/update-admin/${id}`,
        method: "PUT",
        
        body: post,
      }),
      invalidatesTags: [{ type: "Main", id: "PROFILE" }],
    }),

    addAdminImageById: builder.mutation<
      FormData,
      { id: number | undefined; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `${commonUrl}/save-image/${id}`,
        method: "POST", 
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "ADMIN" }],
    }),
    
    getAdminById: builder.query<IProfileByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-admin/${id}`,
        method: "GET",
       
      }),
      providesTags: [{ type: "Main", id: "PROFILE" }],
    }),

    updateSupportDetailsById: builder.mutation<
      ISupport,
      { formData: ISupport }
    >({
      query: ({ formData })=>({
        url:`private/common/save-support-details`,
        method: "POST",
        body: formData
      })
    }),

    getSupportDetails:builder.query<any, void>({
    query: () => ({
      url: "/private/common/support-details",
      method: "GET",
    }),
  }),
  

  }),
  overrideExisting: false,
});

export const {
  useGetProfileByIdQuery,
  useUpdateProfileByIdMutation,
  useAddAdminImageByIdMutation,
  useGetAdminByIdQuery,
  useUpdateSupportDetailsByIdMutation,
  useGetSupportDetailsQuery
} = profileApi;