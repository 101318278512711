import React from "react";
import {useEffect, useState } from "react";
import {
  Row,
  Input,
  Table,
  Typography,
  Switch,
  Modal,
  notification,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  GlobalTableStyle,
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { ColumnsType } from "antd/es/table";
import {
  DeleteButton,
  PrimaryButton,
  ViewDetailsButton,
} from "../../styled/Button.styled";
import CustomCard from "../../countCard/CustomCard";
import pageSize from "../../../constants/pageSize";


import constants from "../../../constants";
import { IBanner } from "../../../types/banner";
import { useDeleteBannerByIdMutation, useGetAllBannerQuery, useGetBannerCountQuery, useUpdateBannerStatusByIdMutation } from "../../../feature/Banner/BannerApiSlice";
import moment from "moment";

function Banner() {
  const { data, isLoading,refetch } = useGetAllBannerQuery();

  const { data: countData } = useGetBannerCountQuery();
  const navigate = useNavigate();
  const { confirm } = Modal;

  const [nameValue, setNameValue] = useState("");
  const [liveDate, setLiveDate] = useState("");
  const [liveTime, setLiveTime] = useState("");

  const [deleteBannerById] = useDeleteBannerByIdMutation();
  const [updateBannerStatusById] = useUpdateBannerStatusByIdMutation();
  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete Banner?",
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const onDelete = (id: number) => {
    deleteBannerById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      });
  };
  const toogleSwitch = (id: any) => {
    updateBannerStatusById(id);
  };
  const columns: ColumnsType<IBanner> = [
    {
      key: "name",
      title: (
        <div>
          <Typography.Text> Banner</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [nameValue],

      render: (value: any, record) => {
        return (
          <>
            <Row>
              <Col span={6}>
                <img
                  alt="vehicle"
                  src={`${record.image}`}
                  style={{
                    height: "5rem",
                    width: "8rem",
                    borderRadius: "5px",
                  }}
                />
              </Col>
            </Row>
          </>
        );
      },
      dataIndex: "name",
    },
    {
      key: "liveDate",
      title: (
        <div>
          <Typography.Text>Live Date</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setLiveDate(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [liveDate],
      onFilter: (value: any, record) => {
        return record?.liveDate
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (value: any, record) => {
        return moment(new Date(record?.liveDate)).format("DD-MM-YYYY");
      },
      dataIndex: "liveDate",
    },
    {
      key: "liveTime",
      title: (
        <div>
          <Typography.Text>Live Time</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setLiveTime(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [liveTime],
      onFilter: (value: any, record) => {
        return record?.liveTime
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (value: any, record) => {
        return record?.liveTime;
      },
      dataIndex: "liveTime",
    },
    {
      key: "",
      title: (
        <div>
          <Typography.Text>Status</Typography.Text>
        </div>
      ),

      render: (_, record) => {
        return (
          <>
            {record.status === true ? (
              <>
                <div>
                  Active
                </div>
                <Switch
                  onChange={(e) => toogleSwitch(record.id)}
                  checked={record.status}
                  style={{ backgroundColor: "green" }}
                />
              </>
            ) : (
              <>
                <div>
                  Inactive
                </div>
                <Switch
                  onChange={() => toogleSwitch(record.id)}
                  checked={record.status}
                  style={{ backgroundColor: "red" }}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openPlaceDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];
  const openPlaceDetails = (id: number) => {
    navigate(`edit/${id}`);
  };

  const sum = data?.data.content.length;
  const active = countData?.data.activeBanner;
  const inActive = countData?.data.inActiveBanner;

  useEffect(() => {
    refetch(); 
  }, [refetch]);

  return (
    <>
      <Row justify="space-between" className="button-bottom-margin">
        <CustomCard
          sum={sum}
          text="Banners"
          activeText="Active"
          active={active}
          inActiveText="Inactive"
          inActive={inActive}
        />
        <Link to="add">
          <PrimaryButton> Add Banner</PrimaryButton>
        </Link>
      </Row>
      <LightBlueContainer>
        <WhiteContainer>
          <GlobalTableStyle>
            <Table<IBanner>
              loading={isLoading}
              columns={columns}
              dataSource={data?.data.content}
              rowKey="id"
              pagination={{
                pageSize,
              }}
            />
          </GlobalTableStyle>
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}
export default Banner;
