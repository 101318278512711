import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../styled/Button.styled";
import BookingDetails from "./BookingDetails";

const EditBooking = () => {
  const navigate = useNavigate();

  const onClose = () => {
    navigate(-1);
  };
  return (
    <>
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <PrimaryButton onClick={onClose}>Back</PrimaryButton>
        </Col>
      </Row>
      
      <BookingDetails />
    </>
  );
};
export default EditBooking;