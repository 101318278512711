import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { useGetTripByIdQuery } from "../../../feature/booking/bookingApiSlice";
import TextArea from "antd/lib/input/TextArea";
import { Card, Col, DatePicker, Form, Input, Row } from "antd";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import constants from "../../../constants";
const defaultImage: string = require("../../../assets/logo/defaultImage.svg").default;

function BookingDetails() {
  let { id } = useParams();
  const { data,isLoading,isError } = useGetTripByIdQuery(id);
  return (
    <>
      { !isError && !isLoading && data && (
      <Form disabled
        initialValues={{
          name: data?.data.userName,
          phoneNumber: data?.data.userNumber,
          email: data?.data.userEmail,
          bookingStatus: data?.data.tripStatus,
          bookingType: data?.data.tripType,
          bookingDate: dayjs(data?.data.date),
          routeFrom: data?.data.fromDirectionAddress.address,
          routeTo: data?.data.toDirectionAddress.length > 0 ? data?.data.toDirectionAddress[0].address : "",
        }}
      >
        <LightBlueContainer>
          <WhiteContainer>
            <Row gutter={[32, 22]}>
              <Col span={12}>
                <Card>
                  <img
                    src={`${constants.baseImgUrl}${data.data.userImage}`}
                    onError={(e) => {
                      (e.target as HTMLSourceElement).src = defaultImage;
                    }}
                    alt=" User"
                    style={{
                      width: "150px",
                      height: "100px",
                      border: "2px solid #BBD4FF",
                    }}
                  />
                </Card>

                <label>Name</label>
                <Form.Item name="name">
                  <Input />
                </Form.Item>

                <label>Phone Number</label>
                <Form.Item name="phoneNumber">
                  <Input maxLength={10} />
                </Form.Item>

                <label>Email</label>
                <Form.Item name="email">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} className="mt-5">
                <label>Booking Status</label>
                <Form.Item name="bookingStatus">
                  <Input />
                </Form.Item>

                <label>Booking Type</label>
                <Form.Item name="bookingType">
                  <Input />
                </Form.Item>

                <label>Booking Date</label>
                <Form.Item name="bookingDate">
                  <DatePicker className="w-100" format={"DD/MM/YYYY"} />
                </Form.Item>

                <label>Route-From</label>
                <Form.Item name="routeFrom">
                  <TextArea rows={5}  disabled/>
                </Form.Item>

                <label>Route-To</label>
                <Form.Item name="routeTo">
                  <TextArea rows={5} disabled/>
                </Form.Item>
              </Col>
            </Row>
          </WhiteContainer>
        </LightBlueContainer>
      </Form>
      )}
    </>
  );
}

export default BookingDetails;