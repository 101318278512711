import { Row, Tabs, Col, Form, notification } from "antd";
import { PrimaryButton } from "../../../styled/Button.styled";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../../../styled/Container.styled";

import PersonalDetails from "./PersonalDetails";
import VehicleDetails from "./VehicleDetails";
import DocumentDetails from "./DocumentDetails";
import BankDetails from "./BankDetails";
import { useAddDriverMutation, useUpdateDriverByIdMutation } from "../../../../feature/Driver/driverApiSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useState } from "react";
import appendData from "../../../../utils/appendData";
import BookingHistory from "./BookingHistory";
import styled from "styled-components"

const CustomTabs = styled(Tabs)`
.ant-tabs-tab {
  &:hover {
    pointer-events: none; 
  }
}
.ant-tabs-tab-disabled {
  pointer-events: none;
}
`;
function AddDriver() {
  const [addDriver] = useAddDriverMutation();
  const [updateDriver] = useUpdateDriverByIdMutation();
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState("");
  const [rcImage, setRCImage] = useState("");
  const [touristPermitFrontImage, setTouristPermitFrontImage] = useState("");
  const [touristPermitBackImage, setTouristPermitBackImage] = useState("");
  const [aadharFrontImage, setAadharFrontImage] = useState("");
  const [aadharBackImage, setAadharBackImage] = useState("");
  const [drivingLicenceFrontImage, setDrivingLicenceFrontImage] = useState("");
  const [drivingLicenceBackImage, setDrivingLicenceBackImage] = useState("");
  const [panFrontImage, setPanFrontImage] = useState("");
  const [panBackImage, setPanBackImage] = useState("");
  const [formFilled, setFormFilled] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);

  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };
  const onClose = () => {
    navigate(-1);
  };

  const personalDetailsResponse = (response: any) => {
    setFormFilled(response);
  };
  const documentDetailsResponse = (res: any) => {
    setFormFilled(res);
  };
  const vehicleDetailsResponse = (res: any) => {
    setFormFilled(res);

  };
  const bankDetailsResponse = (res: any) => {
    setSaveDisable(res);
  };
  const tabItems = [
    {
      label: "Personal",
      disabled: true,
      key: "tab1",
      children: (
        <WhiteContainer>
          <PersonalDetails
            setImageFile={setImageFile}
            personalDetailsResponse={personalDetailsResponse}
          />
        </WhiteContainer>
      ),
    },
    {
      label: "Vehicle ",
      disabled: true,
      key: "tab2",
      children: (
        <WhiteContainer>
          <VehicleDetails
            setRCImage={setRCImage}
            setTouristPermitFrontImage={setTouristPermitFrontImage}
            setTouristPermitBackImage={setTouristPermitBackImage}
            vehicleDetailsResponse={vehicleDetailsResponse}
          />
        </WhiteContainer>
      ),
    },
    {
      label: "Document ",
      disabled: true,
      key: "tab3",
      children: (
        <WhiteContainer>
          <DocumentDetails
            setAadharFrontImage={setAadharFrontImage}
            setAadharBackImage={setAadharBackImage}
            setDrivingLicenceFrontImage={setDrivingLicenceFrontImage}
            setDrivingLicenceBackImage={setDrivingLicenceBackImage}
            setPanFrontImage={setPanFrontImage}
            setPanBackImage={setPanBackImage}
            documentDetailsResponse={documentDetailsResponse}
          />
        </WhiteContainer>
      ),
    },
    {
      label: "Bank ",
      disabled: true,
      key: "tab4",
      children: (
        <WhiteContainer>
          <BankDetails bankDetailsResponse={bankDetailsResponse} />
        </WhiteContainer>
      ),
    },
    {
      label: "Booking History ",
      disabled: true,
      key: "tab5",
      children: (
        <WhiteContainer>
          <BookingHistory />
        </WhiteContainer>
      ),
    },
  ];

  const handleNextTab = () => {
    const tabs = ["tab1", "tab2", "tab3", "tab4"];
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = currentIndex === tabs.length - 1 ? 0 : currentIndex + 1;
    const nextTab = tabs[nextIndex];
    setActiveTab(nextTab);
    setFormFilled(false);
  };
  const handlePrevTab = () => {
    const tabs = ["tab1", "tab2", "tab3", "tab4"];
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = currentIndex === tabs.length - 1 ? 0 : currentIndex - 1;
    const nextTab = tabs[nextIndex];
    setActiveTab(nextTab);
  };

  return (
    <Form.Provider
      onFormFinish={(name, { forms }) => {
        if (name === "submitForm") {
          const {
            personalDetailForm,
            vehicleDetailForm,
            documentDetailForm,
            bankDetailForm,
          } = forms;

          personalDetailForm.submit();
          vehicleDetailForm.submit();
          documentDetailForm.submit();
          bankDetailForm.submit();

          let driverData = { ...personalDetailForm.getFieldsValue() };
          let vehicleData = { ...vehicleDetailForm.getFieldsValue() };
          let documentData = { ...documentDetailForm.getFieldsValue() };
          let bankData = { ...bankDetailForm.getFieldsValue() };

          let obj = {
            ...driverData,
            image: imageFile,
            dob: dayjs(driverData.dob).format("DD/MM/YYYY"),
            isActive: true,
            isVerified: true,
            vehicleDto: {
              ...vehicleData,
              vehicleMake: [
                {
                  id: vehicleData.vehicleMake
                }
              ],
              vehicleYear: dayjs(vehicleData.vehicleYear).format("YYYY"),
              touristPermitDTO: {
                touristPermitNumber: vehicleData.touristPermitNumber ? vehicleData.touristPermitNumber : ""
              },
              rcImage: {
                rcNumber: vehicleData.rcNumber,
                rcFrontImage: rcImage
              }
            },
            bankDetails: bankData,
            ...documentData,
            aadhaarFrontImage: aadharFrontImage,
            aadhaarBackImage: aadharBackImage,
            drivingFrontImage: drivingLicenceFrontImage,
            drivingBackImage: drivingLicenceBackImage,
            panImage: panFrontImage,
          };

          addDriver(obj)
            .unwrap()
            .then(() => {
              notification.success({
                message: "Successfully Added!",
                placement: "bottomLeft",
              });
              navigate(-1);
            })
            .catch((error) =>
              notification.error({
                message: "Error!",
                description: error.data.message,
                placement: "bottomLeft",
              })
            );
        }
      }}
    >
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          {activeTab !== "tab1" ? (
            <PrimaryButton onClick={handlePrevTab}>Back</PrimaryButton>
          ) : (
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          )}
        </Col>
        <Col>
          {activeTab === "tab4" ? (
            <Form name="submitForm">
              <PrimaryButton htmlType="submit" >Save</PrimaryButton>
              {/* disabled={!saveDisable} */}
            </Form>
          ) : (
            <PrimaryButton onClick={handleNextTab}>
              {/* disabled={!formFilled}  to disable button if form is not filled correctly*/}
              Next
            </PrimaryButton>
          )}
        </Col>
      </Row>

      <LightBlueContainer>
        <CustomTabs
          items={tabItems}
          activeKey={activeTab}
          onChange={handleTabChange}
        />
      </LightBlueContainer>
    </Form.Provider>
  );
}

export default AddDriver;
