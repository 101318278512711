import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col, notification } from "antd";

import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { PrimaryButton } from "../../styled/Button.styled";
import {
  useGetPlaceByIdQuery,
  useUpdatePlaceByIdMutation,
} from "../../../feature/PopularPlace/popularplaceApiSlice";
import Place from "./Place";
import { useState } from "react";
import appendData from "../../../utils/appendData";

const PopularPlaces_view = () => {
  let { id } = useParams();
  const { data, isError, isLoading } = useGetPlaceByIdQuery(id);
  const [updatePlaceById] = useUpdatePlaceByIdMutation();
  const [imgStatus, setStatus] = useState<boolean>();
  const [imgFile, setImgFile] = useState("");
  const navigate = useNavigate();

  const onClose = () => {
    navigate(-1);
  };
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { placeDetailsForm } = forms;
            placeDetailsForm.submit();
            let placeData = { ...placeDetailsForm.getFieldsValue() };

            const body: any = {
              placeName: placeData.placeName,
              place: placeData.place,
              address: placeData.address,
              district: placeData.district,
              state: placeData.state,
              image: imgFile,
              latitude: placeData.latitude,
              longitude: placeData.longitude,
              status: true
            };

            for (const key in body) {
              if (body[key] === undefined || body[key] === "") {
                notification.error({
                  message: "Fields are required!",
                  placement: "bottomLeft",
                });
                return;
              }
            }
            updatePlaceById({ id: data?.data.id, formData: body })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully updated!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }
        }
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            <PrimaryButton onClick={onClose}>Back</PrimaryButton>
          </Col>
          <Col>
            <Form name="submitForm">
              <PrimaryButton loading={imgStatus} htmlType="submit">Save</PrimaryButton>
            </Form>
          </Col>
        </Row>
        <LightBlueContainer>
          <WhiteContainer>
            {!isError && !isLoading && data && (
              <Place data={data} setImgFile={setImgFile} loading={(status: boolean) => setStatus(status)} />
            )}
          </WhiteContainer>
        </LightBlueContainer>
      </Form.Provider>
    </>
  );
};

export default PopularPlaces_view;
