import { Form, Input, Row, Col, TimePicker, Select } from "antd";
import SelectGst from "../../../selectOption/SelectGst";
import { useState } from "react";
import { useGetFareCategoryQuery } from "../../../../feature/Fare/fareApiSlice";
type OutstationProps = {
  data?: any;
  fareList?: any;
};
const Outstation = (props: OutstationProps) => {
  const { Option } = Select;
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const { data: category } = useGetFareCategoryQuery('OUTSTATION_TAXI', {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });


  return (
    <>
      <Row gutter={[32, 22]}>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <label> Select Category </label>
              <Form.Item
                name={'name'}
                rules={[
                  {
                    required: true,
                    message: 'Please select a category'
                  }
                ]}
              >
                <Select
                  placeholder={`Select category`}
                >
                  {
                    category?.data.map((cat: any) => {
                      return (
                        <Option key={cat.id} value={JSON.stringify(cat)}>
                          {cat.name}
                        </Option>
                      );
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <label>Base Fare (Rs.)</label>
              <Form.Item name="baseFare"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a basefare'
                  },
                  () => ({
                    validator: async (_, value) => {
                      if (value < 0) {
                        return Promise.reject(
                          new Error("Please enter an amount greater than zero")
                        );
                      }
                    },
                  }),
                ]}>
                <Input />
              </Form.Item>

            </Col>
            <Col span={24}>
              <label>Booking Charges (Rs.)</label>
              <Form.Item name="bookingCharges"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a booking charges'
                  },
                  () => ({
                    validator: async (_, value) => {
                      if (value < 0) {
                        return Promise.reject(
                          new Error("Please enter an amount greater than zero")
                        );
                      }
                    },
                  }),
                ]}>
                <Input />
              </Form.Item>

            </Col>
            <Col span={24}>
              <label>Waiting Charges (Rs./per min)</label>
              <Form.Item name="waitingCharges"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a waiting charges'
                  },
                  () => ({
                    validator: async (_, value) => {
                      if (value < 0) {
                        return Promise.reject(
                          new Error("Please enter an amount greater than zero")
                        );
                      }
                    },
                  }),
                ]}>
                <Input />
              </Form.Item>

            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={[4, 4]}>
            <label>Night Charges</label>
            <div className="borderBox">
              <Col span={24}>
                <label>Amount</label>
                <Form.Item name="nightChargeAmount"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter amount'
                    },
                    () => ({
                      validator: async (_, value) => {
                        if (value < 0) {
                          return Promise.reject(
                            new Error("Please enter an amount greater than zero")
                          );
                        }
                      },
                    }),
                  ]}>
                  <Input />
                </Form.Item>

              </Col>
              <Row gutter={[4, 4]}>
                <Col span={12}>
                  <label>Start Time</label>
                  <Form.Item name="nightChargeStartTime"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter start time'
                      }
                    ]}>
                    <TimePicker className="w-100" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <label>End Time</label>
                  <Form.Item name="nightChargeEndTime"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter end time'
                      }
                    ]}>
                    <TimePicker className="w-100" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Col span={24}>
              <label>GST</label>
              <SelectGst />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Outstation;
