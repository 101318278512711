import { Form, Row, Col, notification, Tabs } from "antd";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import {
  useGetProfileByIdQuery,
  useUpdateProfileByIdMutation,
} from "../../../feature/Profile/ProfileApiSlice";
import secureLocalStorage from "react-secure-storage";
import { PrimaryButton } from "../../styled/Button.styled";
import ProfileDetailsForm from "./ProfileDetailsForm";
import dayjs from "dayjs";
import { useState } from "react";
import SupportDetailsForm from "./SupportDetailsForm";
import styled from "styled-components"

const CustomTabs = styled(Tabs)`
.ant-tabs-tab {
  &:hover {
    pointer-events: none; 
  }
}
.ant-tabs-tab-disabled {
  pointer-events: none;
}
`;

const EditProfile = () => {
  const idFromLocalStorage: any = secureLocalStorage.getItem("id")?.toString();
  const { data, isLoading, isError } =
    useGetProfileByIdQuery(idFromLocalStorage);
  const [updateProfileById] = useUpdateProfileByIdMutation();
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  const handleNextTab = () => {
    const tabs = ["tab1", "tab2"];
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = currentIndex === tabs.length - 1 ? 0 : currentIndex + 1;
    const nextTab = tabs[nextIndex];
    setActiveTab(nextTab);
    // setFormFilled(false);
  };
  const handlePrevTab = () => {
    const tabs = ["tab1", "tab2"];
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = currentIndex === tabs.length - 1 ? 0 : currentIndex - 1;
    const nextTab = tabs[nextIndex];
    setActiveTab(nextTab);
  };

  const tabItems = [
    {
      label: "Profile",
      key: "tab1",
      disabled: true,
      children: (
        <WhiteContainer>
          <ProfileDetailsForm data={data} />
        </WhiteContainer>
      ),
    },
    {
      label: "Support",
      key: "tab2",
      disabled: true,
      children: (
        <WhiteContainer>
          <SupportDetailsForm/>
        </WhiteContainer>
      ),
    }
  ];

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { profileDetailForm, supportDetailForm } = forms;
            profileDetailForm.submit();
            supportDetailForm?.submit();
            const profileData = { ...profileDetailForm.getFieldsValue() };
            const obj = {
              ...profileData,
              dob: dayjs(profileData.dob).format("YYYY-MM-DD HH:mm:ss.0"),
              isActive: true,
            };

            updateProfileById({ id: idFromLocalStorage, post: obj })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully Added!",
                  placement: "bottomLeft",
                });
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col>
            {activeTab !== "tab1"?(
              <PrimaryButton onClick={handlePrevTab}>Back</PrimaryButton>
            ):(
              <PrimaryButton onClick={handleNextTab} >
                Next
              </PrimaryButton>
            )}
          </Col>
          <Col>
              <Form name="submitForm">
                <PrimaryButton htmlType="submit" >Update</PrimaryButton>
              </Form>
          </Col>
      </Row>

        <LightBlueContainer>
        {!isError && !isLoading && data && (
          <CustomTabs
            items={tabItems}
            activeKey={activeTab}
            onChange={handleTabChange}
          />
        )}
        </LightBlueContainer>
      </Form.Provider>
    </>
  );
};
export default EditProfile;
