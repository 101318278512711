import { Form, Select, Spin } from "antd";
import { useLazyGetVehicleModelQuery } from "../../feature/VehicleList/vehicleListApiSlice";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "rc-field-form/lib/interface";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useState } from "react";


type ISectionOptionsProps = {
  label: string;
  name: [number, string] | string;
  rules?: Rule[];
  form: FormInstance;
};

const { Option } = Select;

const SelectVehicleModel = (props: ISectionOptionsProps) => {
  const form = useFormInstance();
  const [models, setModels] = useState<any>(['model']); //string is used in array as place holder for loader
  const [getVehicleModels] = useLazyGetVehicleModelQuery();

  const options: any[] | undefined=[];
  
  models.map((option: any, index: number) => {
    options.push({
      label: option,
      value: option
    })
  });

  const filterOption = (input: string, option:any) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Form.Item {...props}>
        <Select 
          loading={models.length === 0} 
          onClick={() => { setModels([]); getVehicleModels(form.getFieldsValue().vehicles[props.name[0]].id).then((res) => setModels(res?.data?.data[0].vehicleModel)); }}
          showSearch
          options={options}
          filterOption={filterOption} />
          {/* {
            models.map((option: any, index: number) => (
              <Option key={index} value={option}>
                {option}
              </Option>
            ))
          }
        </Select> */}
      </Form.Item>
    </>
  );
};

export default SelectVehicleModel;
