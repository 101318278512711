import {
  MdAssignmentInd,
  MdDashboard,
  MdDirectionsCarFilled,
  MdLocationPin,
  MdCardGiftcard,
  MdDashboardCustomize,
  MdBookmark,
  MdNotifications,
  MdCreditCard,
  MdSwapHoriz,
  MdAccountBox,
} from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import type { MenuProps } from "antd";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const adminItems: MenuItem[] = [
  getItem("Dashboard", "dashboard", <MdDashboard />),
  getItem("Bookings", "bookings", <MdDirectionsCarFilled />),
  getItem("Users", "users", <FaUserCircle />),
  getItem("Drivers", "drivers", <MdAssignmentInd />),
  getItem("Popular places", "popularplaces", <MdLocationPin />),
  getItem("Offers", "offers", <MdCardGiftcard />),
  getItem("Categories", "categories", <MdDashboardCustomize />),
  getItem("Banners", "banners", <MdBookmark />),
  getItem("Communication", "communication", <MdNotifications />),
  getItem("Fares", "fares", <MdCreditCard />),
  getItem("Transactions", "transactions", <MdSwapHoriz />),
  getItem("Profile", "profile", <MdAccountBox />),
];

export { adminItems };
