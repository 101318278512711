import {useEffect, useState } from "react";
import { Input, Modal, notification, Row,Col, Table, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  GlobalTableStyle,
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { ColumnsType } from "antd/es/table";
import pageSize from "../../../constants/pageSize";
import { useDeleteRiderByIdMutation, useGetAllRiderQuery } from "../../../feature/user/userApiSlice";
import { DeleteButton, ViewDetailsButton } from "../../styled/Button.styled";
import constants from "../../../constants";
import { IUser } from "../../../types/user";
import Card from "../../countCard/Card";
const defaultImage: string = require("../../../assets/logo/defaultImage.svg").default;

function User() {
  const navigate = useNavigate();
  const { data, isLoading,refetch} = useGetAllRiderQuery();
  const [deleteRiderById] = useDeleteRiderByIdMutation();
  const { confirm } = Modal;
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const showConfirm = (id: number) => {
    confirm({
      title: "Do You Want To Delete User?",
      onOk() {
        onDelete(id);
      },
      onCancel() { },
    });
  };

  const onDelete = (id: number) => {
    deleteRiderById(id)
      .unwrap()
      .then(() => {
        notification.success({
          message: "Sucessfully Deleted!",
          placement: "bottomLeft",
        });
      })
      .catch((error) =>
        notification.error({
          message: "Error!",
          description: error.data.message,
          placement: "bottomLeft",
        })
      );
  };
  const columns: ColumnsType<IUser> = [
    {
      key: "userName",
      // ellipsis: true,
      width: "30%",
      title: (
        <div>
          <Typography.Text> Name</Typography.Text>
          <div>
          <Input.Search
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
            placeholder="Search here"
            />
            </div>
        </div>
      ),
      filteredValue: [nameValue],
      onFilter: (value: any, record) => {
        return record.firstName
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (_: any, record) => {
        return (
          <>
            <Row>
              <Col><img
                alt="user"
                src={`${constants.baseImgUrl}${record.userImage}` }
                onError={(e) => {
                  (e.target as HTMLSourceElement).src= defaultImage
               }}
              style={{ height: "48px", width: "48px", borderRadius: "30px" }}
            /></Col>
              <Col style={{marginTop:"0.8rem"}}>{record.firstName? record.firstName.concat(" ", record.lastName): record.phoneNumber}</Col>
            </Row>
            
            
          </>
        );
      },

    },
    {
      key: "email",
      title: (
        <div>
          <Typography.Text>Email</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setEmailValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [emailValue],
      onFilter: (value: any, record) => {
        return record.email
          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (value: any, record) => {
        return record?.email ? record.email :"-------";
      },
      dataIndex: "email",
    },

    {
      key: "phoneNumber",
      title: (
        <div>
          <Typography.Text>Phone</Typography.Text>
          <Input.Search
            onChange={(e) => {
              setPhoneValue(e.target.value);
            }}
            placeholder="Search here"
          />
        </div>
      ),
      filteredValue: [phoneValue],
      onFilter: (value: any, record) => {
        return record.phoneNumber

          .toString()
          .toLowerCase()
          .includes(value?.toLowerCase());
      },
      render: (value: any, record) => {
        return record.phoneNumber;
      },
      dataIndex: "phoneNumber",
    },

    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openUserDetails(record.id)}>
          View Details
        </ViewDetailsButton>
      ),
    },
    {
      key: "deleteButton",
      render: (_, record) => (
        <DeleteButton onClick={() => showConfirm(record.id)} />
      ),
    },
  ];

  const openUserDetails = (id: number) => {
    navigate(`edit/${id}`);
  };
  const sum = data?.data.content.length;
  
  useEffect(() => {
    refetch(); 
  }, [refetch]);

  return (
    <>
      <Row justify="space-between" className="button-bottom-margin">
        <Card sum={sum} text="Users" />
      </Row>
      <LightBlueContainer>
        <WhiteContainer>
          <GlobalTableStyle>
            <Table<IUser>
              loading={isLoading}
              columns={columns}
              dataSource={data?.data.content}
              pagination={{
                pageSize,
              }}
              rowKey="id"
            />
          </GlobalTableStyle>
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}
export default User;