import { Form, Row, Col, Tabs, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddFareMutation } from "../../../../feature/Fare/fareApiSlice";
import { PrimaryButton } from "../../../styled/Button.styled";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../../../styled/Container.styled";
import Local from "./Local";
import Outstation from "./Outstation";
import Rental from "./Rental";
import dayjs from "dayjs";
import { useState } from "react";

const AddFare = () => {
  const [localForm] = Form.useForm();
  const [outStationform] = Form.useForm();
  const [rentalForm] = Form.useForm();
  const [addFare] = useAddFareMutation();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [tab, setTab] = useState<string>("1");

  const onsubmit = () => {
    const { name, baseFare, flatCharges, bookingCharges, gst, nightChargeAmount, waitingCharges, nightChargeEndTime, nightChargeStartTime } = localForm.getFieldsValue();
    const fd = {
      localFareDTO: {
        flatCharges: +flatCharges,
        categoryId: JSON.parse(name).id,
        name: JSON.parse(name).name,
        baseFare: +baseFare,
        bookingCharges: +bookingCharges,
        gst: +gst,
        nightCharges: {
          nightChargeAmount: +nightChargeAmount,
          nightChargeEndTime: dayjs(nightChargeEndTime).format("hh:mm"),
          nightChargeStartTime: dayjs(nightChargeStartTime).format("hh:mm")
        },
        waitingCharges: +waitingCharges,

      },
    };
    addFare(fd)
      .then((res: any) => {
        if (res?.data) {
          notification.success({
            message: "Successfully Added!",
            placement: "bottomLeft",
          });
          navigate(-1);
        } else {
          notification.error({
            message: res?.error?.data?.message || "Failed to save",
            placement: "bottomLeft",
          });
        }
      });
  };

  const outStationSubmit = () => {
    const { name, baseFare, bookingCharges, gst, nightChargeAmount, waitingCharges, nightChargeEndTime, nightChargeStartTime } = outStationform.getFieldsValue();
    const fd = {
      outstationFares: {
        categoryId: JSON.parse(name).id,
        name: JSON.parse(name).name,
        baseFare: +baseFare,
        bookingCharges: +bookingCharges,
        gst: +gst,
        nightCharges: {
          nightChargeAmount: +nightChargeAmount,
          nightChargeEndTime: dayjs(nightChargeEndTime).format("hh:mm"),
          nightChargeStartTime: dayjs(nightChargeStartTime).format("hh:mm")
        },
        waitingCharges: +waitingCharges,

      },
    };
    addFare(fd)
      .then((res: any) => {
        if (res?.data) {
          notification.success({
            message: "Successfully Added!",
            placement: "bottomLeft",
          });
          navigate(-1);
        } else {
          notification.error({
            message: res?.error?.data?.message || "Failed to save",
            placement: "bottomLeft",
          });
        }
      });
  };

  const rentalFareSubmit = () => {
    const { name, gst, nightChargeAmount, nightChargeEndTime, nightChargeStartTime, rentalCharts } = rentalForm.getFieldsValue();
    const fd = {
      rentalChartList: {
        categoryId: JSON.parse(name).id,
        name: JSON.parse(name).name,
        gst: +gst,
        nightCharges: {
          nightChargeAmount: +nightChargeAmount,
          nightChargeEndTime: dayjs(nightChargeEndTime).format("hh:mm"),
          nightChargeStartTime: dayjs(nightChargeStartTime).format("hh:mm")
        },
        rentalCharts: rentalCharts
      },
    };
    addFare(fd)
      .then((res: any) => {
        if (res?.data) {
          notification.success({
            message: "Successfully Added!",
            placement: "bottomLeft",
          });
          navigate(-1);
        } else {
          notification.error({
            message: res?.error?.data?.message || "Failed to save",
            placement: "bottomLeft",
          });
        }
      });
  };

  const tabItems = [
    {
      label: "Local",
      key: "1",
      children: (
        <WhiteContainer>
          <Form
            form={localForm}
            onFinish={onsubmit}
            layout="vertical"
            name="dynamic_rule"
          >
            <Local fareList={location.state} />
          </Form>
        </WhiteContainer>
      ),
    },
    {
      label: "Outstation ",
      key: "2",
      children: (
        <WhiteContainer>
          <Form
            form={outStationform}
            onFinish={outStationSubmit}
            layout="vertical"
            name="dynamic_rule"
          >
            <Outstation fareList={location.state} />
          </Form>
        </WhiteContainer>
      ),
    },
    {
      label: "Rental",
      key: "3",
      children: (
        <WhiteContainer>
          <Form
            form={rentalForm}
            name="dynamic_rule"
            layout="vertical"
            onFinish={rentalFareSubmit}
            initialValues={{
              rentalCharts: [
                {
                  fareGreaterKilometer: '',
                  farePerKilometer: '',
                  maxHour: '',
                  maxKilometer: ''
                }
              ]
            }}
          >
            <Rental fareList={location.state} />
          </Form>
        </WhiteContainer >
      ),
    },
  ];


  return (
    <>
      <Row justify="end" gutter={20} className="button-bottom-margin">
        <Col>
          <PrimaryButton onClick={(e) => {
            if (tab === "1") {
              localForm.submit();
            } else if (tab === "2") {
              outStationform.submit();
            } else {
              rentalForm.submit();
            }
          }} htmlType="submit" >Save</PrimaryButton>
        </Col>
      </Row>
      <LightBlueContainer>
        <Tabs items={tabItems} onTabClick={(e) => setTab(e)} />
      </LightBlueContainer>
    </>
  );
};

export default AddFare;
