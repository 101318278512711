import { mainApi } from "../../app/services/mainApiSlice";

import { IDriver, IDriverByIdResponse, IDriverCountResponse, IDriverResponse } from "../../types/driver";


const commonUrl: string = "/admin/user";

const driverApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Driver
    getAllDriver: builder.query<IDriverResponse, void>({
      query: () => ({
        url: `${commonUrl}/get-all-driver?page=0&size=1000`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "DRIVER" }],
    }),

    getDriverCount: builder.query<IDriverCountResponse, void>({
      query: () => ({
        url: `/admin/Dashboard/get-driver-count`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "DRIVER" }],
    }),
    addDriver: builder.mutation<any, any>({
      query: (formData) => ({
        url: `/public/register/save-driver`,
        method: "POST",

        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "DRIVER" }],
    }),

    updateDriverById: builder.mutation<any, { id: any; formData: any; }>({
      query: ({ id, formData }) => ({
        url: `/admin/user/update-driver/${id}`,
        method: "PUT",

        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "DRIVER" }],
    }),


    getDriverById: builder.query<IDriverByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "DRIVER" }],
    }),

    getBookingHistory: builder.query<any, any>({
      query: ({ id, startDate, endDate }) => ({
        url: `${commonUrl}/get-cancellationCount/${id}?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",

      })
    }),

    updateDriverStatusById: builder.mutation<
      IDriver,
      any
    >({
      query: (id) => ({
        url: `driver/driver-home/update-status/${id}`,
        method: "PUT",

      }),
      invalidatesTags: [{ type: "Main", id: "DRIVER" }],
    }),


    deleteDriverById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `driver/driver-home/${id}`,
        method: "DELETE",

      }),
      invalidatesTags: [{ type: "Main", id: "DRIVER" }],
    }),

    getVehicles: builder.query<any, void>({
      query: () => ({
        url: `/public/register/vehicle`,
        method: "GET",
      })
    })

  }),
  overrideExisting: false,
});

export const { useGetAllDriverQuery, useGetDriverCountQuery, useAddDriverMutation, useGetDriverByIdQuery, useDeleteDriverByIdMutation, useUpdateDriverStatusByIdMutation, useUpdateDriverByIdMutation, useGetVehiclesQuery, useLazyGetBookingHistoryQuery } = driverApi;
