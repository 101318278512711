import { Col, notification } from "antd";
import { useState, useEffect } from "react";
import dummyFace from "../../assets/dummyFace.png";

type ImageUploaderProps = {
  src?: string;
  name?: string;
  id?: string;
  type?: string;
  imageHandler?: any;
  setImg?: any;
  setCategoryImageUpload?: any;
};
const imageUploaderStyle = {
  width: "8rem",
  height: "6rem",
};
const inputFileStyle = {
  display: "none",
};
const inputFileLabelStyle = {
  width: "47%",
  padding: "2%",
  background: "white",
  borderRadius: "5px",
  border: "1px solid #3e4685",
  color: "#3e4685",
  cursor: "pointer",
};

function ImageUploader(props: ImageUploaderProps) {
  const { src, type, id, name, setImg, setCategoryImageUpload } = props;
  const [imageSrc, setImageSrc] = useState<string | undefined>("");

  const imageHandler = (e: any) => {
    const newData = new FormData();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    let fileType = e.target.files[0].type;

    const acceptedImagesTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (!acceptedImagesTypes.includes(fileType || "")) {
      notification.error({
        message: "Please upload image only",
        placement: "bottomLeft",
      });
      return;
    }

    newData.append("image", e.target.files[0]);
    setCategoryImageUpload(newData);
    setImg({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
  };

  useEffect(() => {
    if (src?.includes("undefined") || src?.includes("null")) {
      setImageSrc(dummyFace);
    } else {
      setImageSrc(src);
    }
  }, [src]);

  return (
    <>
      <Col span={6}>
        <img src={imageSrc} style={imageUploaderStyle} alt="category-img" />
      </Col>
      <Col>
        <input
          type={type}
          name={name}
          id={id}
          style={inputFileStyle}
          onChange={imageHandler}
        />
        <label htmlFor={id} style={inputFileLabelStyle}>
          Upload Image
        </label>
      </Col>
    </>
  );
}

export default ImageUploader;
