const colorPrimary = "#14A011";
const bgColor = "#3E4685";
const progressStrokeColor = "#44ca15";
const menuItemBgColorHoverSelected = colorPrimary;
const menuItemTextColorHoverSelected = "#ffffff";
const menuItemFontSize = 20;
const menuItemFontColor = "#ffffff";
const constant = { baseImgUrl: "https://storage.googleapis.com/anubudh-himcab/" }
export {
  colorPrimary,
  bgColor,
  menuItemBgColorHoverSelected,
  menuItemTextColorHoverSelected,
  menuItemFontSize,
  progressStrokeColor,
  menuItemFontColor,
  constant,
};
