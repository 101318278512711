import { Row, Col } from "antd";
import styled from "styled-components";
import { LightBlueContainer } from "../styled/Container.styled";

const CardContainer = styled(LightBlueContainer)`
  width: 250px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p:first-child {
    font-size: 19px;
  }
`;

type IProps = {
  text: string;
  sum: number | undefined;
};

function Card({ text, sum }: IProps) {
  return (
    <CardContainer>
      <Row>
        <Col span={12}> <p>{text}</p></Col>
        <Col span={12}><span className="text-color">{sum}</span></Col>
      </Row>
     
      
    </CardContainer>
  );
}

export default Card;
