import { Form, Row, Col, notification } from "antd";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../../styled/Container.styled";
import { PrimaryButton } from "../../styled/Button.styled";
import BannerDetailsForm from "./BannerDetailsForm";
import { useAddBannerMutation } from "../../../feature/Banner/BannerApiSlice";
import { useNavigate } from "react-router-dom";
import appendData from "../../../utils/appendData";
import dayjs from "dayjs";
import { useState } from "react";
const AddBanner = () => {
  const [bannerImg, setBannerImg] = useState();
  const [addBanner] = useAddBannerMutation();

  const navigate = useNavigate();
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { BannerDetailsForm } = forms;
            BannerDetailsForm.submit();
            let bannerData = { ...BannerDetailsForm.getFieldsValue() };
            const keyArray: any = [
              "imageFile",
              "liveDate",
              "liveTime",
              "status",
            ];
            const valueArray: any = [
              bannerImg,
              dayjs(bannerData.liveDate).format("DD/MM/YYYY"),
              dayjs(bannerData.liveTime).format("HH:mm"),
              true,
            ];
            const formData = appendData(keyArray, valueArray);


            addBanner({ formData })
              .unwrap()
              .then(() => {
                notification.success({
                  message: "Successfully Added!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              });
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">

          <Col>
            <Form name="submitForm">
              <PrimaryButton htmlType="submit">Save</PrimaryButton>
            </Form>
          </Col>
        </Row>

        <LightBlueContainer>
          <WhiteContainer>
            <BannerDetailsForm setBannerImg={setBannerImg} />
          </WhiteContainer>
        </LightBlueContainer>
      </Form.Provider>
    </>
  );
};

export default AddBanner;
