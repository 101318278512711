import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, DatePicker, Select } from "antd";
import { WhiteContainer } from "../../styled/Container.styled";
import { IOfferByIdResponse } from "../../../types/offers";
import dayjs from "dayjs";

type OfferDetailsProps = {
  data?: IOfferByIdResponse;
  setImgFile?: any;
};

const OffersDetailsForm = (props: OfferDetailsProps) => {
  const { data, setImgFile } = props;

  const [offerImg, setOfferImg] = useState({
    image_file: null,
    image_preview: " ",
  });

  const [stateDisabled, setStateDisabled] = useState<boolean>(false);

  const imgFileHandler = (e: any) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];

    setOfferImg({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
    setImgFile(image_as_files.name);
  };

  useEffect(() => {
    setOfferImg({
      image_file: null,
      image_preview: `${data?.data.image} `,
    });

    setStateDisabled(data ? true : false);
  }, [data?.data.image]);

  return (
    <>
      <Form
        layout="vertical"
        name="offerDetailsForm"
        initialValues={{
          couponName: data?.data.couponName,
          couponCode: data?.data.couponCode,
          couponValue: data?.data.couponValue,
          liveDate: dayjs(data?.data.liveDate),
          expireDate: dayjs(data?.data.expireDate),
          state: data?.data.state,
          image: data?.data.image,
          offerUserType: data?.data.offerUserType || 'EXISTING_USER', // Fallback default value
          offerTripType: data?.data.offerTripType,
          minimumValue: data?.data.minimumValue,
        }}
      >
        <WhiteContainer>
          <Row gutter={[32, 22]}>
            <Col span={12}>
              <label>Coupon Name</label>
              <Form.Item name="couponName" rules={[{ required: true, message: "Coupon Name is required" }]}>
                <Input />
              </Form.Item>

              <label>Coupon Code</label>
              <Form.Item name="couponCode" rules={[{ required: true, message: "Coupon Code is required" }]}>
                <Input />
              </Form.Item>

              <label>Coupon Value</label>
              <Form.Item name="couponValue" rules={[{ required: true, message: "Coupon Value is required" }]}>
                <Input />
              </Form.Item>

              <label>Coupon Live Date</label>
              <Form.Item name="liveDate" rules={[{ required: true, message: "Coupon live date is required" }]}>
                <DatePicker className="w-100" />
              </Form.Item>

              <label>Coupon Expiry Date</label>
              <Form.Item name="expireDate" rules={[{ required: true, message: "Coupon expiry date is required" }]}>
                <DatePicker className="w-100" />
              </Form.Item>

              <label>Coupon applied to</label>
              <Form.Item name="offerUserType">
                <Select
                  options={[
                    { value: 'EXISTING_USER', label: 'Existing User' },
                    { value: 'NEW_USER', label: 'New User' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* 
              <Form.Item>
                <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <Card>
                        <img
                          src={offerImg.image_preview}
                          alt="offer"
                          style={imageUploaderStyle}
                        />
                      </Card>
                    </Col>
                    <Col span={12}>
                      <input
                        type="file"
                        name="image"
                        className="custom-offerbtn"
                        onChange={imgFileHandler}
                      />
                    </Col>
                  </Row>
                </Col>
              </Form.Item>
              */}
              <label>Minimum Trip Value</label>
              <Form.Item name="minimumValue" rules={[{ required: true, message: "Minimum Trip Value is required" }]}>
                <Input />
              </Form.Item>

              <label>Offer Trip Type</label>
              <Form.Item name="offerTripType">
                <Select
                  options={[
                    { value: 'ALL', label: 'ALL' },
                    { value: 'LOCAL', label: 'LOCAL' },
                    { value: 'OUTSTATION', label: 'OUTSTATION' },
                    { value: 'RENTAL', label: 'RENTAL' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </WhiteContainer>
      </Form>
    </>
  );
};

export default OffersDetailsForm;
