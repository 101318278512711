import styled from "styled-components";
import { Button, Form, Input, Card, notification, Row, Typography, Col } from "antd";
import { useSendForgotMailMutation } from "../../../feature/forgotPassword/forgotPasswordApiSlice";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { logo, textLogo } from "../../../constants/logos";
import { PrimaryButton } from "../../styled/Button.styled";

const ResetPasswordCard = styled(Card)`
  height: 60vh;
  max-height: 350px;
  width: 40vw;
  display: flex;
  text-align: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;
const cardBodyStyle = {
    width: "100%",
};
function ForgotPassword() {
    const [form] = Form.useForm();
    const [sendForgotMail] = useSendForgotMailMutation();

    const handleFormSubmit = (val: { email: string }) => {
        sendForgotMail({
            toAddresses: val.email,
        })
            .unwrap()
            .then(() => {
                notification.success({
                    message: "Mail Sent Successfully",
                    description: "Please check your mail to reset password",
                    className:"custom-notification"
                });
            })
            .then(() => {
                form.resetFields();
            })
            .catch(() => {
                notification.error({
                    message: "User not found with email",
                    className:"custom-notification"
                });
                form.resetFields();
            });
    };

    return (
        <>
            <LoginContainer className="bgImage">
                <Row
                    justify={"space-between"}
                    align={"middle"}
                    className="login-container-inner-row"
                >
                    <Col className="logo brand-logo-position" span={6}>
                        <Row gutter={[0, 12]}>
                            <Col span={24}>
                                <Row justify={"center"}>
                                    <img src={logo} alt="brandLogo" className="brand-logo-size" />
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row justify={"center"}>
                                    <img src={textLogo} alt="textLogo" />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={11}>
                        <Card
                            className="reset-password-screen-card"
                            bordered={false}
                            bodyStyle={{ textAlign: "center", padding: "1rem" }}
                        >
                            <h4 className="welcome-text"> Forgot Password</h4>
                            <Col span={24} style={{marginTop:"5px"}}>
                                <p className="body-text">Please provide your email address and we'll send you a link to reset your password.</p>
                            </Col>
                            <Form
                                form={form}
                                name="normal_login"
                                className="login-form p-1"
                                initialValues={{ remember: true }}
                                layout="vertical"
                                onFinish={(values) => handleFormSubmit(values)}
                            >
                                <Form.Item
                                    label="Email ID"
                                    name="email"
                                    style={{ marginBottom: "40px" }}
                                    rules={[
                                        { type: "email", message: "Input is not a valid email" },
                                        { required: true, message: "Please input your email" },
                                    ]}
                                >
                                    <Input placeholder="E-mail" style={{ height: "40px" }} />
                                </Form.Item>
                                <Col className="login-button-container mb-1" span={23} style={{ marginBottom: 0 }}>
                                    <Row justify={"center"}>
                                        <Button type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                            style={{ backgroundColor: "#3e4685", width: "100%",height: "3rem", maxWidth: "200px", fontSize: "20px" }}
                                            size="large">
                                            Reset Password
                                        </Button>
                                    </Row>
                                </Col>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </LoginContainer>
        </>
    );
}

export default ForgotPassword;
