import { useEffect, useState } from "react";
import { Input, Radio, Form, Row, Col, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import { IDriverByIdResponse } from "../../../../types/driver";
import constants from "../../../../constants";
import SelectStateOption from "../../../selectOption/SelectStateOption";
import { UploadImage } from "../../../axios/imageUpload";
import imageCompression from 'browser-image-compression';
import { handleImageSize } from "../../../../utils/handleImageSize";

const defaultImage: string =
  require("../../../../assets/logo/defaultImage.svg").default;

type PersonalDetailsProps = {
  data?: IDriverByIdResponse;
  setImageFile?: any;
  personalDetailsResponse?: any;
};

const imageUploaderStyle = {
  width: "9rem",
  height: "7rem",
  borderRadius: "5%",
  border: "2px Solid #cbe3f7",
};

const PersonalDetails = (props: PersonalDetailsProps) => {
  const [form] = Form.useForm();
  const { data, setImageFile, personalDetailsResponse } = props;
  const [error, setError] = useState("");
  const [expError, setExpError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [dateError, setDateError] = useState("");

  const [img, setImg] = useState({
    image_file: '',
    image_preview: "",
  });

  const imgFileHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
  
      const resultImage= await handleImageSize(file);

      if(resultImage!==undefined){
        const compressedFile:File=resultImage;
        const image_as_base64 = URL.createObjectURL(compressedFile);
  
        let fd = new FormData();
        fd.append('image', compressedFile);

        UploadImage(fd)
          .then((res) => {
            setImg({
              image_preview: image_as_base64,
              image_file: res.data,
            });
            setImageFile(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        }
    }
  };

  useEffect(() => {
    setImg({
      image_file: `${data?.data.image}`,
      image_preview: `${data?.data.image}`,
    });
    setImageFile(data?.data.image);
  }, [data?.data.image]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    phoneNumber: "",
    email: "",
    drivingExperience: "",
    address: "",
    state: "",
    dateOfJoining: "",
  });

  const handleFieldChange = (fieldName: any, value: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
    let res = Object.values(formData).every((value) => value.trim() !== "");
    personalDetailsResponse(res);
  };

  const validateFirstName = (e: any) => {
    handleFieldChange("firstName", e.target.value);
    if (/^[a-zA-Z]*$/.test(e.target.value)) {
      setFnameError("");
    } else {
      setFnameError("Invalid first name");
    }
  };

  const validateLastName = (e: any) => {
    handleFieldChange("lastName", e.target.value);

    if (/^[a-zA-Z]*$/.test(e.target.value)) {
      setLnameError("");
    } else {
      setLnameError("Invalid Last name");
    }
  };

  const handleDateChange = (date: any) => {
    handleFieldChange("dob",date);
    const selectDate = new Date(date).getFullYear();
    const currentDate = new Date().getFullYear();
    const age = currentDate - selectDate;
    if (age > 18) {
      setDateError("");
    } else {
      setDateError("User must be 18 years old or above");
    }
  };

  const handleDateOfJoiningChange = (date: any) => {
    handleFieldChange("dateOfJoining",date);
  };

  const handlePhone = (e: any) => {
    handleFieldChange("phoneNumber", e.target.value);

    if (/^([6-9][\d]{9})$/.test(e.target.value)) {
      setError("");
    } else {
      setError("Invalid mobile number");
    }
  };
  const handleEmail = (e: any) => {
    handleFieldChange("email", e.target.value);

    if (!/[\w_\-.]+[@][a-z]+[.][a-z]{2,3}/.test(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };
  const handleExperiance = (e: any) => {
    handleFieldChange("drivingExperience", e.target.value);

    if (!/^[1-9]{1,2}$/.test(e.target.value)) {
      setExpError("Invalid Driving experience ");
    } else {
      setExpError("");
    }
  };
  const handleStateResponse = (response: any) => {
    handleFieldChange("state", response);
  };

  const isValidDate = (dateString:any) => {
    return dayjs(dateString, 'DD/MM/YYYY', true).isValid();
  };
  
  return (
    <>
      <Form
        name="personalDetailForm"
        form={form}
        initialValues={{
          firstName: data?.data.firstName,
          lastName: data?.data.lastName,
          gender: data?.data.gender,
          phoneNumber: data?.data.phoneNumber,
          email: data?.data.email,
          dob: (data?.data.dob && isValidDate(data?.data.dob)) ? dayjs(data?.data.dob, 'DD/MM/YYYY') : "",
          address: data?.data.address,
          state: data?.data.state,
          image: data?.data?.image,
          dateOfJoining: data?.data.isVerified ? (data?.data.dateOfJoining)? dayjs(dayjs(data?.data.dateOfJoining), 'DD/MM/YYYY'): "":""
        }}
      >
        <Row gutter={[32, 22]}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Row align="middle">
                  <Col span={6}>
                    <img
                      src={img.image_preview}
                      onError={(e) => {
                        (e.target as HTMLSourceElement).src = defaultImage;
                      }}
                      alt="Profile Img"
                      style={imageUploaderStyle}
                    />
                  </Col>
                  <Col>
                    <input
                      type="file"
                      name="image"
                      className="custom-file-input"
                      onChange={imgFileHandler}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <label>First Name</label>
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "First name is required" },
                  ]}
                >
                  <Input onChange={(e) => validateFirstName(e)} />
                </Form.Item>
                {fnameError && <p style={{ color: "red" }}>{fnameError}</p>}
              </Col>

              <Col span={24}>
                <label>Last Name</label>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input onChange={(e) => validateLastName(e)} />
                </Form.Item>
                {lnameError && <p style={{ color: "red" }}>{lnameError}</p>}
              </Col>

              <Col span={24}>
                <label>Date of Birth</label>
                <Form.Item
                  name="dob"
                  rules={[
                    { required: true, message: "Date of Birth is required" },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format={"DD-MM-YYYY"}
                    onChange={handleDateChange}
                  />
                </Form.Item>
                {dateError && <p style={{ color: "red" }}>{dateError}</p>}
              </Col>
              <Col span={24}>
                <label>Gender</label>
                <Form.Item
                  name="gender"
                  rules={[{ required: true, message: "Gender is required" }]}
                >
                  <Radio.Group
                    name="radiogroup"
                    style={{ color: "#3E4685" }}
                    defaultValue={false}
                    onChange={(e) =>
                      handleFieldChange("gender", e.target.value)
                    }
                  >
                    <Radio className="ms-3 " value={"MALE"}>
                      Male
                    </Radio>
                    <Radio value={"FEMALE"}>Female</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <label>Phone Number</label>
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    { required: true, message: "Phone number is required" },
                  ]}
                >
                  <Input onChange={(e) => handlePhone(e)} />
                </Form.Item>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </Col>

              <Col span={24}>
                <label>Email</label>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Email is required" }]}
                >
                  <Input onChange={(e: any) => handleEmail(e)} />
                </Form.Item>
                {emailError && <p style={{ color: "red" }}>{emailError}</p>}
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <label>Address</label>
            <Form.Item
              name="address"
              rules={[{ required: true, message: "Address is required" }]}
            >
              <TextArea
                rows={3}
                onChange={(e) => handleFieldChange("address", e.target.value)}
              />
            </Form.Item>
            <label>State</label>
            <div>
              <SelectStateOption disabled=
              {false} handleStateResponse={handleStateResponse} />
            </div>
            <label>Date of joining</label>
            <Form.Item
                  name="dateOfJoining"
                  rules={[
                    { required: true, message: "Date of Joining is required" },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format={"DD-MM-YYYY"}
                    onChange={handleDateOfJoiningChange}
                  />
                </Form.Item>
          </Col>

        </Row>
      </Form>
    </>
  );
};
export default PersonalDetails;
