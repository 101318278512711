import date from "../../../utils/getDate";
import AuthStatus from "./AuthStatus";
import styled from "styled-components";
import { Col, Row } from "antd";
import Breadcrumbs from "../../Common/Breadcrumbs";

const HeaderContainer = styled.header`
  padding: 1rem 1rem 0 1rem;
`;

const DateContainer = styled.div`
  color: #3e4685;
  font-size: 1rem;
  font-weight:500;
`;

function Header() {
  return (
    <HeaderContainer>
      <Row>
        <Col span={12}>
          <DateContainer>{date}</DateContainer>
          <Breadcrumbs />
        </Col>
        <Col span={12}>
          <AuthStatus />
        </Col>
      </Row>
    </HeaderContainer>
  );
}

export default Header;
