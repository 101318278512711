import { mainApi } from "../../app/services/mainApiSlice";
import { IDriverCountResponse } from "../../types/driver";
import {
  IPlace,
  IPlaceByIdResponse,
  IPlaceResponse,
} from "../../types/popularPlace";

const commonUrl: string = "/admin/popular-place";

const popularPlaceApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All place
    getAllPopularPlace: builder.query<IPlaceResponse, { pageSize: number; }>({
      query: ({ pageSize }) => ({
        url: `${commonUrl}/get-all-list?page=0&size=1000`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "POPULARPLACE" }],
    }),

    getPlaceCount: builder.query<IDriverCountResponse, void>({
      query: () => ({
        url: `${commonUrl}/get-count-popular-places`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "POPULARPLACE" }],
    }),
    getPlaceById: builder.query<IPlaceByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-by-id/${id}`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "POPULARPLACE" }],
    }),

    addPlace: builder.mutation<{}, { formData: any; }>({
      query: ({ formData }) => ({
        url: `${commonUrl}/save`,
        method: "POST",

        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "POPULARPLACE" }],
    }),

    uploadImage: builder.mutation<{}, { formData: FormData; }>({
      query: ({ formData }) => ({
        url: `${commonUrl}/save-image/`,
        method: "POST",

        body: formData,
      }),

    }),

    updatePlaceById: builder.mutation<
      IPlace,
      { id: number | undefined; formData: FormData; }
    >({
      query: ({ id, formData }) => ({
        url: `${commonUrl}/update-by-id/${id}`,
        method: "PUT",

        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "POPULARPLACE" }],
    }),
    updateplaceStatusById: builder.mutation<
      IPlace,
      { id: number | undefined; }
    >({
      query: (id) => ({
        url: `${commonUrl}/update-status/${id}`,
        method: "GET",

      }),
      invalidatesTags: [{ type: "Main", id: "POPULARPLACE" }],
    }),
    deletePlaceById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/delete-by-id/${id}`,
        method: "DELETE",

      }),
      invalidatesTags: [{ type: "Main", id: "POPULARPLACE" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllPopularPlaceQuery,
  useGetPlaceByIdQuery,
  useAddPlaceMutation,
  useUpdatePlaceByIdMutation,
  useDeletePlaceByIdMutation,
  useGetPlaceCountQuery,
  useUpdateplaceStatusByIdMutation,
  useUploadImageMutation
} = popularPlaceApi;
