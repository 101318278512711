import { Alert, Card, Col, Input, notification, Row, Form } from "antd";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../styled/Button.styled";
import { firebase, auth } from "../../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { useSignInMutation } from "../../../feature/login/loginApiSlice";
import secureLocalStorage from "react-secure-storage";
import { logo, textLogo } from "../../../constants/logos";
import { useAppDispatch } from "../../../app/hooks";
import { setToken } from "../../../feature/ui/uiSlice";

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [signIn] = useSignInMutation();
  const [disable, setDisable] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if email and password are filled
    if (email && password) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [email, password]);

  const handleLogin = () => {
    signIn({
      username: email,
      password: password,
    })
      .unwrap()
      .then((data) => {
        const { roles, id, accessToken, username } = data;

        secureLocalStorage.setItem("name", username);
        secureLocalStorage.setItem("role", roles.toString());
        secureLocalStorage.setItem("id", id.toString());
        secureLocalStorage.setItem("token", accessToken);

        dispatch(setToken(accessToken));
        navigate("/dashboard");
        notification.success({
          message: "Successfully Logged In!",
          placement: "bottomLeft",
        });
      })
      .catch(() => {
        notification.error({
          message: "User Does Not Exist!",
          description: "Bad Credentials",
          placement: "bottomLeft",
        });
      });
  };

  return (
    <>
      <LoginContainer className="bgImage">
        <Row
          justify={"space-between"}
          align={"middle"}
          className="login-container-inner-row"
        >
          <Col className="logo brand-logo-position" span={6}>
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Row justify={"center"}>
                  <img src={logo} alt="brandLogo" className="brand-logo-size" />
                </Row>
              </Col>
              <Col span={24}>
                <Row justify={"center"}>
                  <img src={textLogo} alt="textLogo" />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={11}>
            <Card
              className="login-screen-card"
              bordered={false}
              bodyStyle={{ textAlign: "center", padding: "1rem" }}
            >
              <h4 className="welcome-text">Welcome To Himcab</h4>
              <Row justify={"center"} style={{ display: "flex" }}>
                <Col span={24}>
                  <p className="body-text">Login via Registered Email</p>
                </Col>
                {error && <Alert message={error} />}
                <Col className="login-email-container mb-1" span={23} style={{ marginBottom: "15px" }}>
                  <Row justify={"start"}>
                    <label className="fs-8 fontColor" htmlFor="email" style={{ fontSize: "16px" }}>
                      Email Address
                    </label>
                  </Row>
                  <Row>
                    <Input
                      placeholder="Enter Email Address"
                      style={{ height: "40px" }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Row>
                </Col>
                <Col className="login-password-container mb-1" span={23}>
                  <Row justify={"start"}>
                    <label className="fs-8 fontColor" htmlFor="password" style={{ fontSize: "16px" }}>
                      Password
                    </label>
                  </Row>
                  <Row>
                    <Input.Password
                      placeholder="Enter Password"
                      style={{ height: "40px" }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Row>
                </Col>
                <Col className="forgot-password" span={23} style={{ textAlign: "right", marginBottom: "15px" }}>
                  <Form.Item>
                    <Link className="login-form-forgot" to={"/forgot-password"}>
                      Forgot password
                    </Link>
                  </Form.Item>
                </Col>
                <Col className="login-button-container mb-1" span={23}>
                  <Row justify={"center"}>
                    <PrimaryButton disabled={disable} onClick={handleLogin}>
                      Log in
                    </PrimaryButton>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </LoginContainer>
    </>
  );
};

export default Login;
