import { mainApi } from "../../app/services/mainApiSlice";
import { IVehicleMakeResponse } from "../../types/vehicle";

const commonUrl: string = "/public/register";

const vehicleList = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllVehicleMake: builder.query<IVehicleMakeResponse, void>({
      query: () => ({
        url: `${commonUrl}/vehicle-list`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "VEHICLE" }],
    }),
    getVehicleModel: builder.query<IVehicleMakeResponse, number | null>({
      query: (id) => ({
        url: `${commonUrl}/vehicle-model/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "VEHICLE" }],
    }),
  }),
});

export const { useGetAllVehicleMakeQuery, useGetVehicleModelQuery, useLazyGetVehicleModelQuery } =
  vehicleList;
