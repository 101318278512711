import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { IOfferByIdResponse } from "../../../types/offers";
import { WhiteContainer } from "../../styled/Container.styled";

type OfferTermsProps = {
  data?: IOfferByIdResponse;
};
function OffersTerms(props: OfferTermsProps) {
  const { data } = props;

  return (
    <>
      <Form
        name="offerTermsForm"
        initialValues={{ termsAndConditions: data?.data.termsAndConditions }}
      >
        <WhiteContainer>
          <Form.Item name="termsAndConditions"
            rules={[
              {
                required: true,
                message: "Terms and condition is required",
              }
            ]}>
            <TextArea rows={15} />
          </Form.Item>
        </WhiteContainer>
      </Form>
    </>
  );
}
export default OffersTerms;
