import { Form, Row, Col, Tabs, notification } from "antd";

import { LightBlueContainer } from "../../styled/Container.styled";
import { PrimaryButton } from "../../styled/Button.styled";

import OffersForm from "./OffersDetailsForm";
import OffersTerms from "./offersTerms";
import { useAddOfferMutation } from "../../../feature/Offer/offerApiSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useState } from "react";
const AddOffers = () => {
  const [addOffer] = useAddOfferMutation();
  const navigate = useNavigate();
  const [imgFile, setImgFile] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };
  const tabItems = [
    {
      label: "Details",
      key: "1",
      children: <OffersForm setImgFile={setImgFile} />,
    },
    {
      label: "Terms And Condition",
      key: "2",
      children: <OffersTerms />,
    },
  ];
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === "submitForm") {
            const { offerDetailsForm, offerTermsForm } = forms;
            offerDetailsForm.submit();
            offerTermsForm.submit();

            let offerData = { ...offerDetailsForm.getFieldsValue() };
            let terms = { ...offerTermsForm.getFieldsValue() };

            let obj = {
              ...offerData,
              liveDate: dayjs(offerData.liveDate).toISOString(),
              expireDate: dayjs(offerData.expireDate).toISOString(),
              image: imgFile,
              offerStatus: "ACTIVE",
              status: true,
              termsAndConditions: Object.values((terms))[0],
            };

            addOffer(obj)
              .unwrap()
              .then((res: any) => {
                notification.success({
                  message: "Successfully Added!",
                  placement: "bottomLeft",
                });
                navigate(-1);
              }).catch((error) =>
                notification.error({
                  message: "Error!",
                  description: error.data.message,
                  placement: "bottomLeft",
                })
              );
          }
        }}
      >
        <Row justify="end" gutter={20} className="button-bottom-margin">
          <Col style={{ display: "flex" }}>
            {
              activeTab === "1" ? <PrimaryButton onClick={() => setActiveTab("2")} >Next</PrimaryButton> :
                <Form name="submitForm" style={{ marginLeft: "10px" }}>
                  <PrimaryButton htmlType="submit">Save</PrimaryButton>
                </Form>

            }
          </Col>
        </Row>
        <LightBlueContainer>
          <Tabs defaultActiveKey={activeTab} activeKey={activeTab} items={tabItems} onChange={handleTabChange} />
        </LightBlueContainer>
      </Form.Provider>
    </>
  );
          };
export default AddOffers;
