import { Col, Row } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { LightBlueContainer, WhiteContainer } from "../../styled/Container.styled";
import { CloseButton } from "../../styled/Button.styled";
import NotificationItem from "./NotificationItem";

const NotificationContainer = styled.div`
  height: auto;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function Notification() {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <LightBlueContainer>
        <WhiteContainer>
          <NotificationContainer>
            <Row justify="end"  >
              <Col className="button-bottom-margin">
                <CloseButton onClick={handleClose}></CloseButton>
              </Col>
              <Col span={24} >
                <NotificationItem />
              </Col>

            </Row>

          </NotificationContainer>
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}

export default Notification;
