/* eslint-disable react-hooks/rules-of-hooks */
import dayjs from 'dayjs';
import { Area } from "@ant-design/charts";
import styled from "styled-components";
import {
  LightBlueContainer,
  WhiteContainer,
} from "../styled/Container.styled";
import { DatePicker, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUserChartQuery, useLazyGetUserChartQuery } from "../../feature/dashboard/dashboardApiSlice";
const DashboardLightBlueContainer = styled(LightBlueContainer)`
  padding: 5px;
  border: none;
`;

type ChartProps = {
  heading?: string;

};
const areaChart = (props: ChartProps) => {
  const { heading } = props;
  const { Text } = Typography;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [calLink, setCalLink] = useState("");
  const [lineChartData, setLineChartData] = useState<any>([]);
  const { data: chartData } = useGetUserChartQuery(dayjs().year());
  const [getChartDataByYear, { isLoading }] = useLazyGetUserChartQuery();

  const config = {
    data: lineChartData,
    height: 160,
    xField: "year",
    yField: "value",
    tooltip: {

      customContent: (name: any, data: any) =>
        `<div>${data?.map((item: any) => {
          return `<div class="tooltip-chart" >
        <span class="tooltip-item-name">${item?.data.year}</span>
        <span class="tooltip-item-value">${item?.value}</span>
        </div>`;
        })}</div>`,
    },
  };
  const handleCalender = () => {

    setCalLink("/calender");
    navigate(`${calLink}`);
  };

  useEffect(() => {
    for (const months in chartData?.data) {
      const dataOfChart = {
        year: months,
        value: chartData?.data[months]
      };
      setLineChartData((oldData: any) => { return [...oldData, dataOfChart]; });
    }
  }, [chartData]);

  return (
    <>
      <DashboardLightBlueContainer>
        <Row justify="space-between" >
          <Text className="dashboard-card-heading-text-size">{heading}</Text>
          <DatePicker defaultValue={dayjs(dayjs())} picker="year" bordered={false} onChange={(e) => {
            getChartDataByYear(dayjs(e).format('YYYY')).then((res: any) => {
              const updateData = [];
              for (const months in res?.data?.data) {
                const dataOfChart = {
                  year: months,
                  value: res?.data?.data[months]
                };
                updateData.push(dataOfChart);
                setLineChartData(updateData);
              }
            });
          }} />


        </Row>
        <WhiteContainer className="inner-white-container-style">
          <Area loading={isLoading} {...config} />
        </WhiteContainer>
      </DashboardLightBlueContainer>
    </>
  );
};
export default areaChart;
