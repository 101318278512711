import { Col, Divider, Row, Typography, Tag } from "antd";
import constants from "../../../constants";
import { IDashboardResponse } from "../../../types/dashboard";
import {
  LightBlueContainer,

} from "../../styled/Container.styled";
import styled from "styled-components";

type PlaceCardProps = {
  heading?: string;
  data?: IDashboardResponse;
};

const PopularPlaceLightBlueContainer = styled(LightBlueContainer)`
  padding: 5px;
  border: none;
`;
const placeImage = {
  width: "100%",

  // width: "100px", // Set a fixed width
  // height: "auto", // Maintain aspect ratio by adjusting height automatically
  border: "1px solid #2b3e5d",
  borderRadius: "5px",
  aspectRatio: "2 / 1.5",

};
function placeCard(props: PlaceCardProps) {
  const { Text } = Typography;
  const { data, heading } = props;
  return (
    <>
      <PopularPlaceLightBlueContainer>
        <Text className="dashboard-card-heading-text-size">{heading}</Text>
        <Row className="inner-white-place-container-style">
          {data?.data.popularPlaces.map((item) => (
            <>
              <Row gutter={[32, 22]} justify={"center"}>
                <Col span={8}>
                  <Row>
                    <img
                      className="popular-place-image"
                      style={placeImage}
                      alt="place"
                      src={`${item.image}`}

                    />
                  </Row>
                </Col>

                <Col span={16}>
                  <Row gutter={[4, 18]}>
                    <Col span={24}>
                      <Text strong>{item.placeName}</Text>
                    </Col>
                    <Col span={24}>
                      <Text type="success">
                        {item.status === true ? (
                          <Tag color="success">Active</Tag>
                        ) : (
                          <Tag color="error"> Inactive</Tag>
                        )}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider style={{ margin: "5% 0%" }} />
            </>
          ))}
        </Row>
      </PopularPlaceLightBlueContainer>
    </>
  );
}

export default placeCard;