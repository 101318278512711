import React, { useState } from "react";
import { Row, Button, Table, DatePicker, Space, message } from "antd";
import { LightBlueContainer, WhiteContainer } from "../../styled/Container.styled";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import pageSize from "../../../constants/pageSize";
import {
  useGetAllTransactionsForMonthQuery,
  useDownloadInvoicesForMonthQuery,
} from "../../../feature/Transactions/transactionApiSlice";
import { ViewDetailsButton } from "../../styled/Button.styled";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const { RangePicker } = DatePicker;

export default function Transaction() {
  const [selectedMonth, setSelectedMonth] = useState<string>(getCurrentMonthYear());
  const { data, isLoading: isTransactionsLoading, refetch: fetchTransactions } = useGetAllTransactionsForMonthQuery(selectedMonth);
 // const { data: downloadData, refetch: downloadInvoices } = useDownloadInvoicesForMonthQuery(selectedMonth); 
  const navigate = useNavigate();

  const openPlaceDetails = (record: any) => {
    navigate(`view/${record.tripId}`, { state: { transaction: record } });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "driverName",
      key: "driverName",
    },
    {
      title: "Payment ID",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    {
      title: "Amount",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
    },
    {
      title: "Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
    {
      title: "Trip Type",
      dataIndex: "tripType",
      key: "tripType",
    },
    {
      title: "Transaction Date",
      dataIndex: "dateOfTransaction",
      key: "dateOfTransaction",
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openPlaceDetails(record)}>
          View Details
        </ViewDetailsButton>
      ),
    },
  ];

  const handleMonthChange = (date: any) => {
    if (date) {
      const selectedDate = date.format("YYYY-MM");
      setSelectedMonth(selectedDate);
      fetchTransactions();
    }
  };


  const handleDownloadButtonClick = async () => {
    try {
      const apiUrl = `${'https://himcab.prod.service.ripple-anubudh.tech/api/admin/transaction/download-invoices?month'}=${selectedMonth}`;
      const headerToken= secureLocalStorage.getItem("token");
      console.log("API URL:", apiUrl);
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${headerToken}`, 
        },
        responseType: 'blob', 
      });
      const url = window.URL.createObjectURL(response.data);
      window.open(url);
  
      window.URL.revokeObjectURL(url);
  
      message.success("Invoices are downloading.");
    } catch (error) {
      message.error("Failed to download invoices.");
      console.error("Failed to download invoices:", error);
    }
  };
  
  return (
    <>
      <LightBlueContainer>
        <Row justify="end" className="button-bottom-margin">
          <Space>
            <DatePicker
              picker="month"
              format="YYYY-MM"
              onChange={handleMonthChange}
            />
            <Button
              type="primary"
              onClick={handleDownloadButtonClick}
              style={{ background: "#3e4685", borderColor: "#1890ff" }}
            >
              Download Invoices
            </Button>
          </Space>
        </Row>
        <WhiteContainer>
          <Table<any>
            loading={isTransactionsLoading}
            columns={columns}
            dataSource={data?.data.content.map((item: any) => ({ ...item, key: item.transactionId }))}
            pagination={{ pageSize }}
          />
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}

function getCurrentMonthYear() {
  const date = new Date();
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  return `${year}-${month}`;
}
