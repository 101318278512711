import { Col, Row, Spin } from "antd";
import DashboardCard from "../../Common/Card/DashboardCard";
import { useGetAllQuery, useGetUserChartQuery, useGetBookingChartQuery } from "../../../feature/dashboard/dashboardApiSlice";
import { useEffect } from "react";
import DashboardPlaceCard from "../../Common/Card/DashboardPlaceCard";
import dayjs from 'dayjs';
import AreaChart from "../../DashboardComponent/AreaChart";
import LineChart from "../../DashboardComponent/LineChart";
const user: string = require("../../../assets/logo/user.svg").default;
const driver: string = require("../../../assets/logo/driver.svg").default;

const category: string = require("../../../assets/logo/category.svg").default;

const offer: string = require("../../../assets/logo/offer.svg").default;
const style: React.CSSProperties = { padding: "5px 0" };

function Dashboard() {
  const { data, refetch } = useGetAllQuery();
  const { data: userData, refetch: fetchUsers } = useGetUserChartQuery(dayjs().year());
  const { data: bookingData, refetch: fetchBookings } = useGetBookingChartQuery(dayjs().year());

  useEffect(() => {
    refetch();
    fetchUsers();
    fetchBookings();
  }, [refetch, fetchUsers, fetchBookings]);

  return (
    <>
      {!!data ? (
        <div>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <DashboardCard
                  heading={"Users"}
                  subText={"New User"}
                  cardIcon={
                    <img src={user} alt="logo" className="img-icon-size" />
                  }
                  totalCount={data?.data.usersCount}
                  newCount={data?.data.newUserCount}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <DashboardCard
                  heading={"Driver"}
                  subText={"New Driver"}
                  cardIcon={
                    <img src={driver} alt="logo" className="img-icon-size" />
                  }
                  newCount={data?.data.newDriverCount}
                  totalCount={data?.data.driverCount}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <DashboardCard
                  heading={"Categories"}
                  subText={"New Category"}
                  cardIcon={
                    <img src={category} alt="logo" className="img-icon-size" />
                  }
                  newCount={data?.data.newCategoriesCount}
                  totalCount={data?.data.categoriesCount}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <DashboardCard
                  heading={"Offers"}
                  subText={"New Offer"}
                  cardIcon={
                    <img src={offer} alt="logo" className="img-icon-size" />
                  }
                  newCount={data?.data.newOfferCount}
                  totalCount={data?.data.offersCount}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <div style={style}>
                {" "}
                <AreaChart heading="Users" />
              </div>
              <div style={style}>
                {" "}
                <LineChart heading="Bookings" />
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div style={style}>
                <DashboardPlaceCard data={data} heading={"Popular Places"} />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <Spin size="large" />
      
      )}
    </>
  );
}

export default Dashboard;
