import { mainApi} from "../../app/services/mainApiSlice";
import {
  IBanner,
  IBannerByIdResponse,
  IBannerResponse,
  IBannerCountResponse,
} from "../../types/banner";

const commonUrl: string = "/admin/banner";
const bannerApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBanner: builder.query<IBannerResponse, void>({
      query: () => ({
        url: `${commonUrl}/get-all?page=0&size=1000`,
        method: "GET",
        
      }),
      providesTags: [{ type: "Main", id: "BANNER" }],
    }),

    getBannerById: builder.query<IBannerByIdResponse, string | undefined>({
      query: (id) => ({
        url: `${commonUrl}/get-banner/${id}`,
        method: "GET",
      
      }),
      providesTags: [{ type: "Main", id: "BANNER" }],
    }),

    getBannerCount: builder.query<IBannerCountResponse, void>({
      query: () => ({
        url: `${commonUrl}/count`,
        method: "GET",
      
      }),
      providesTags: [{ type: "Main", id: "BANNER" }],
    }),

    updateBannerStatusById: builder.mutation<
      IBanner,
      { id: number | undefined }
    >({
      query: (id) => ({
        url: `${commonUrl}/update-status/${id}`,
        method: "GET",
       
      }),
      invalidatesTags: [{ type: "Main", id: "BANNER" }],
    }),

    addBanner: builder.mutation<{}, { formData: FormData }>({
      query: ({ formData }) => ({
        url: `${commonUrl}/save-banner`,
        method: "POST",
       
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "BANNER" }],
    }),

    updateBannerById: builder.mutation<
      IBanner,
      { id: number | undefined; formData: FormData; date: string; time: string }
    >({
      query: ({ id, formData, date, time }) => ({
        url: `${commonUrl}/update-banner/${id}?time=${time}&date=${date}`,
        method: "PUT",
       
        body: formData,
      }),
      invalidatesTags: [{ type: "Main", id: "BANNER" }],
    }),

    deleteBannerById: builder.mutation<void, number | undefined>({
      query: (id) => ({
        url: `${commonUrl}/${id}`,
        method: "DELETE",
      
      }),
      invalidatesTags: [{ type: "Main", id: "BANNER" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBannerQuery,
  useGetBannerByIdQuery,
  useGetBannerCountQuery,
  useUpdateBannerStatusByIdMutation,
  useDeleteBannerByIdMutation,
  useAddBannerMutation,
  useUpdateBannerByIdMutation,
} = bannerApi;
