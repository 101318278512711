import { Form, Checkbox } from "antd";
// import { useState } from "react";
import { IValueLabel } from "../../types/common";
type serviceprops = {
  handleService?: any;
};

const options: IValueLabel[] = [
  {
    value: "LOCAL_TAXI",
    label: "Local Taxi",
  },
  {
    value: "RENTAL_TAXI",
    label: "Rental Taxi",
  },
  {
    value: "OUTSTATION_TAXI",
    label: "Outstation Taxi",
  },
];

function ServiceType(props: serviceprops) {
  const { handleService } = props;

  const handleServiceChange = (e: any) => {
    handleService(e);
  };
  return (
    <Form.Item
      name="taxiServiceTypeDTOList"
      rules={[{ required: true, message: "Service type is required" }]}
    >
      <Checkbox.Group
        options={options}
        onChange={(e) => handleServiceChange(e)}
      />
    </Form.Item>
  );
}

export default ServiceType;
