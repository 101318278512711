import { mainApi } from "../../app/services/mainApiSlice";
import { IDashboardResponse } from "../../types/dashboard";

const commonUrl: string = "/admin/Dashboard";

const dashboardApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query<IDashboardResponse, void>({
      query: () => ({
        url: `${commonUrl}/get-all`,
        method: "GET",

      }),
      providesTags: [{ type: "Main", id: "DASHBOARD" }],
    }),
    getBookingChart: builder.query({
      query: (year) => ({
        url: `/admin/trip/tripGraph?year=${year}`,
        method: "GET",
      })
    }),
    getUserChart: builder.query({
      query: (year) => ({
        url: `/admin/user/userGraph?year=${year}`,
        method: "GET",
      })
    }),
    getNotification: builder.query<any, void>({
      query: () => ({
        url: `/admin/notification/get-notification-admin`,
        method: "GET",
      }),
      providesTags: [{ type: "Main", id: "NOTIFICATION" }],
    }),
    upDateNotification: builder.mutation<any, any>({
      query: ({ id, status }) => ({
        url: `/admin/notification/update-notification-status/${id}?status=${status}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "Main", id: "NOTIFICATION" }],
    })
  }),
  overrideExisting: false,
});

export const { useGetAllQuery, useGetBookingChartQuery, useLazyGetBookingChartQuery, useGetUserChartQuery, useLazyGetUserChartQuery, useGetNotificationQuery, useUpDateNotificationMutation, useLazyGetNotificationQuery } = dashboardApi;
