import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Col, Layout, Row } from "antd";
import Header from "./header/Header";
import SiderMenuItems from "./SiderMenuItems";
import styled from "styled-components";
import { logo, textLogo } from "../../constants/logos";

const { Content, Sider } = Layout;

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1rem;
`;

const App: React.FC = () => {
  const navigate = useNavigate();


  const handleOnClick = () => {
    navigate("/dashboard");
  };

  return (
    <Layout style={{ minHeight: "100%" }}>
   
        <Sider theme="light" trigger={null} width="250">
          <Row
            justify="center"
            align="top"
            style={{ backgroundColor: "#3e4685", padding: "8%" }}
          >
            <Row>
              <Col span={6}>
                <img
                  src={logo}
                  alt="logo"
                  onClick={handleOnClick}
                  style={{ marginRight: "4%" }}
                />
              </Col>
              <Col span={17}>
                <img
                  src={textLogo}
                  alt="logo"
                  style={{ marginTop: "7%", width: "10rem" }}
                  onClick={handleOnClick}
                />
              </Col>
            </Row>
          </Row>

          <SiderMenuItems />
        </Sider>
    
      <Layout>
        <Header />
     
        <Content>
          <OutletContainer>
            <Outlet />
          </OutletContainer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
